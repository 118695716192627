<template>
    <pv-grid id="reportGrid" sourceType="array" :value="source?.data ?? []"
            v-model:selection="selected"
             :responsive="'scroll'"
             :totalRecords="(source?.data ?? []).length ?? 0"
             :paginator="true"
             responsiveLayout="scroll"
             :paginatorPosition="'bottom'"
             :paginatorTemplate="'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport'"
             :pageLinkSize="5"
             :rows="10"
             :rowsPerPageOptions="[5, 10, 15, 25, 50, 100]"
             :alwaysShowPaginator="true"
             :currentPageReportTemplate="'Showing {first} - {last} of {totalRecords} records'"
             :loading="activity.loading"
             autoLayout
             showGridlines
             stripedRows>
        <pv-grid-column-group type="header">
            <pv-grid-row>
                <pv-grid-column field="id" :selectionMode="'multiple'" headerStyle="width: 3em;min-width: 3em; max-width: 3em;" style="width: 3em;width: 3em;min-width: 3em; max-width: 3em;" :frozen="true" :rowspan="2"></pv-grid-column>
                <pv-grid-column header="COLLECTION DATE" :rowspan="2" />
                <pv-grid-column header="TRANSACTION ID" :rowspan="2" />
                <pv-grid-column header="REFERENCE 1" :rowspan="2" />
                <pv-grid-column header="REFERENCE 2" :rowspan="2" />
                <pv-grid-column header="REFERENCE 3" :rowspan="2" />
                <pv-grid-column header="REFERENCE 4" :rowspan="2" />
                <pv-grid-column header="REFERENCE 5" :rowspan="2" />
                <pv-grid-column header="REFERENCE 6" :rowspan="2" />
                <pv-grid-column header="REFERENCE 7" :rowspan="2" />
                <pv-grid-column header="STANDARD CARRIER" :colspan="4" />
                <pv-grid-column header="RETURN CARRIER" :colspan="4" />
                <pv-grid-column :class="[{'p-field-hidden': !isAdmin}]" header="EXCHANGE RATE" :rowspan="2" />
                <pv-grid-column :class="[{'p-field-hidden': !isAdmin}]" header="CONTRACT COST" :colspan="2" />
                <pv-grid-column :class="[{'p-field-hidden': !isAdmin}]" header="CUSTOMER COST" :colspan="2" />
                <pv-grid-column :class="[{'p-field-hidden': isAdmin}]" header="SHIPPING COST" :rowspan="2" />
                <pv-grid-column header="WAREHOUSE" :rowspan="2" />
                <pv-grid-column header="CUSTOMER" :rowspan="2" />
                <pv-grid-column header="SENDER DETAILS" :colspan="2" />
                <pv-grid-column header="RECIPIENT DETAILS" :colspan="2" />
                <pv-grid-column header="MEASUREMENTS" :colspan="4" />
                <pv-grid-column header="DECLARATIONS" :colspan="7" />
                <pv-grid-column header="MILESTONES" :colspan="4" />
                <pv-grid-column header="ACCOUNT NUMBERS" :colspan="2" />
            </pv-grid-row>
            <pv-grid-row>
                <pv-grid-column header="PROVIDER" />
                <pv-grid-column header="PRODUCT" />
                <pv-grid-column header="OPTION" />
                <pv-grid-column header="REFERENCE" />
                <pv-grid-column header="PROVIDER" />
                <pv-grid-column header="PRODUCT" />
                <pv-grid-column header="OPTION" />
                <pv-grid-column header="REFERENCE" />
                <pv-grid-column :class="[{'p-field-hidden': !isAdmin}]" header="COST" />
                <pv-grid-column :class="[{'p-field-hidden': !isAdmin}]" header="PRESENTMENT" />
                <pv-grid-column :class="[{'p-field-hidden': !isAdmin}]" header="COST" />
                <pv-grid-column :class="[{'p-field-hidden': !isAdmin}]" header="PRESENTMENT" />
                <pv-grid-column header="ADDRESS" />
                <pv-grid-column header="POSTAL CODE" />
                <pv-grid-column header="ADDRESS" />
                <pv-grid-column header="POSTAL CODE" />
                <pv-grid-column header="TOTAL PACKAGES" />
                <pv-grid-column header="WEIGHT" />
                <pv-grid-column header="CBM" />
                <pv-grid-column header="DIMENSIONS" />
                <pv-grid-column header="CONTENT" />
                <pv-grid-column header="CONSIGNMENT" />
                <pv-grid-column header="CUSTOMS" />
                <pv-grid-column header="DISCOUNT" />
                <pv-grid-column header="INSURANCE" />
                <pv-grid-column header="DUTY PAID" />
                <pv-grid-column header="TAX PAID" />
                <pv-grid-column header="MANIFEST" />
                <pv-grid-column header="DISPATCH" />
                <pv-grid-column header="OUT FOR DELIVERY" />
                <pv-grid-column header="POD" />
                <pv-grid-column header="STANDARD" />
                <pv-grid-column header="RETURNS" />
            </pv-grid-row>
        </pv-grid-column-group>
        <pv-grid-column field="id" :selectionMode="'multiple'" headerStyle="width: 3em;min-width: 3em; max-width: 3em;" style="width: 3em;width: 3em;min-width: 3em; max-width: 3em;" :frozen="true"></pv-grid-column>
        <pv-grid-column field="collectionDate" header="COLLECTION DATE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{ $filters.toDateDisplay(slotProps.data?.collectionDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="code" header="TRANSACTION ID" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <router-link :to="{ name: 'base-consignment-track', params: { mask: slotProps.data.code  }}" style="font-weight:bold">{{slotProps.data.code}}</router-link>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference" header="REFERENCE 1" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <router-link :to="{ name: 'consignment-code-edit', params: { code: slotProps.data.code  }}" style="font-weight:bold">{{slotProps.data.customerReference}}</router-link>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference2" header="REFERENCE 2" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="customerReference3" header="REFERENCE 3" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="customerReference4" header="REFERENCE 4" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="customerReference5" header="REFERENCE 5" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="customerReference6" header="REFERENCE 6" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="customerReference7" header="REFERENCE 7" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="providerName" header="CARRIER" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="serviceName" header="SERVICE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="optionName" header="OPTION" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="carrierReference" header="REFERENCE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <a :href="`${$filters.formatString(slotProps.data?.trackingURL, slotProps.data.carrierReference, slotProps.data.shipTo_PostalCode)}`" target="_blank" style="font-weight: bold;"> {{slotProps.data.carrierReference}} </a>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="return_ProviderName" header="RETURN CARRIER" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="return_ServiceName" header="RETURN SERVICE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="return_OptionName" header="RETURN OPTION" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="return_CarrierReference" header="RETURN REFERENCE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    <router-link to="{ name: 'consignment-code-edit', params: { code: slotProps.data.code  }}">{{slotProps.data.return_CarrierReference}}</router-link>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !isAdmin}]" field="forex_Rate" header="EXCHANGE RATE" headerStyle="min-width: 9.5rem;" style="min-width: 9.5rem; text-align: right !important;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatNumber(slotProps.data.forex_Rate ?? 0.00, 8)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !isAdmin}]" field="contractCost" header="SHIPPER COST" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width: 100%; padding: 0 !important;">
                    <div class="p-d-flex p-ac-between">
                        <div v-if="(slotProps.data.contractCost ?? 0.00) > 0.00" class="p-col pr-3" style="padding: 0 !important; text-align: left !important;">
                            <i class="fa fa-external-link" style="color: #2196f3; cursor: pointer;" title="View Quotation Computation" @click="getJson(slotProps.data?.code)"></i>
                        </div>
                        <div class="p-col" style="padding: 0 0 0 1rem !important; text-align: right !important;">
                            {{$filters.formatCurrency(slotProps.data.contractCost ?? 0.00,slotProps.data.contract_CurrencyCode?.toUpperCase(),slotProps.data.contractCountryCode,2)}}
                        </div>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !isAdmin}]" field="presentment_ContractCost" header="PRESENTMENT CONTRACT COST" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatCurrency(slotProps.data.presentment_ContractCost ?? 0.00,slotProps.data.presentment_CurrencyCode?.toUpperCase(),slotProps.data.contractCountryCode,2)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !isAdmin}]" field="customerCost" header="CUSTOMER COST" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatCurrency(slotProps.data.customerCost ?? 0.00,slotProps.data.contract_CurrencyCode?.toUpperCase(),slotProps.data.contractCountryCode,2)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !isAdmin}]" field="presentment_CustomerCost" header="PRESENTMENT CUSTOMER COST" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatCurrency(slotProps.data.presentment_CustomerCost ?? 0.00,slotProps.data.presentment_CurrencyCode?.toUpperCase(),slotProps.data.contractCountryCode,2)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': isAdmin}]" field="presentment_CustomerCost" header="CUSTOMER COST" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatCurrency(slotProps.data.presentment_CustomerCost ?? 0.00,slotProps.data.presentment_CurrencyCode?.toUpperCase(),slotProps.data.contractCountryCode,2)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="warehouseName" header="WAREHOUSE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="customerName" header="CUSTOMER" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="shipFrom_Address" header="SENDER ADDRESS" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="shipFrom_PostalCode" header="SENDER POSTAL CODE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="shipTo_Address" header="RECIPIENT ADDRESS" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="shipTo_PostalCode" header="RECIPIENT POSTAL CODE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="total_PackageCount" header="TOTAL PARCEL" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatNumber(slotProps.data.total_PackageCount ?? 0.00,0,slotProps.data.contractCountryCode)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="total_Weight" header="TOTAL WEIGHT (KG)" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatNumber(slotProps.data.total_Weight ?? 0.00,3,slotProps.data.contractCountryCode)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="total_CBM" header="TOTAL CBM" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatNumber(slotProps.data.total_CBM ?? 0.00,4,slotProps.data.contractCountryCode)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="dimensions" header="DIMENSIONS" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:left !important;">
                    <div class="is-text-bold is-text-info" v-if="slotProps.data.dimensions.indexOf(',') !== -1" v-tooltip.bottom="`${slotProps.data.dimensions.replaceAll('(', '&lt;span class=\'is-text-bold is-text-warning\' &gt;(').replaceAll(')', ')&lt;/span&gt;').replaceAll(', ', '&lt;br /&gt;')}`">Multiple Dimensions</div>
                    <span v-else v-html="`${slotProps.data.dimensions.replaceAll('(', '&lt;span class=\'is-text-bold is-text-info\' &gt;(').replaceAll(')', ')&lt;/span&gt;').replaceAll(', ', '&lt;br /&gt;')}`"></span>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="content" header="CONTENT" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="total_ConsignmentValue" header="DECLARED VALUE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatCurrency(slotProps.data.total_ConsignmentValue ?? 0.00,slotProps.data.currencyCode?.toUpperCase(),slotProps.data.contractCountryCode,2)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="total_CustomsValue" header="CUSTOMS VALUE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatCurrency(slotProps.data.total_CustomsValue ?? 0.00,slotProps.data.currencyCode?.toUpperCase(),slotProps.data.contractCountryCode,2)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="total_DiscountValue" header="DISCOUNT VALUE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatCurrency(slotProps.data.total_DiscountValue ?? 0.00,slotProps.data.currencyCode?.toUpperCase(),slotProps.data.contractCountryCode,2)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="total_InsuranceValue" header="INSURANCE VALUE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatCurrency(slotProps.data.total_InsuranceValue ?? 0.00,slotProps.data.currencyCode?.toUpperCase(),slotProps.data.contractCountryCode,2)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="total_DutyValue" header="DUTY PAID VALUE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatCurrency(slotProps.data.total_DutyValue ?? 0.00,slotProps.data.currencyCode?.toUpperCase(),slotProps.data.contractCountryCode,2)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="total_TaxValue" header="TAX PAID VALUE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div style="width:100%;text-align:right !important;">
                    {{$filters.formatCurrency(slotProps.data.total_TaxValue ?? 0.00,slotProps.data.currencyCode?.toUpperCase(),slotProps.data.contractCountryCode,2)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="manifestDate" header="MANIFEST DATE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDateDisplay(slotProps.data?.manifestDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="dispatchDate" header="DISPATCH DATE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDateDisplay(slotProps.data?.dispatchDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="outForDeliveryDate" header="OUT FOR DELIVERY DATE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDateDisplay(slotProps.data?.outForDeliveryDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="podDate" header="POD DATE" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;">
            <template #body="slotProps">
                <div class="p-d-flex p-col-12" style="padding: 0;">
                    {{  $filters.toDateDisplay(slotProps.data?.podDate)}}
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="accountNumber" header="ACCOUNT NUMBER" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <pv-grid-column field="return_AccountNumber" header="ACCOUNT NUMBER" headerStyle="width: 14rem; padding-left: 15px; padding-top: .8rem !important;" style="text-overflow: ellipsis; overflow: hidden; text-wrap: none; white-space: nowrap; padding-top: 1em;" />
        <!-- Row Messages -->
        <template #empty>
            <span v-html="`${(filterKey === null ? 'Click &lt;b&gt;Apply Filter&lt;/b&gt; button to generate result.' : 'No records found.')}`"></span>
        </template>
        <template #loading>
            Retrieving records from database. Please wait...
        </template>
        <template #paginatorLeft>
            <pv-button type="button" icon="pi pi-download" class="p-button-text" @click="onDownload" />
        </template>
        <template #paginatorRight>
            <pv-button type="button" icon="pi pi-refresh" class="p-button-text" @click="getSource" />
        </template>
    </pv-grid>
    <!-- Operation Dialog -->
    <is-dialog header="Detailed Computation" :visible="quote || quoteShow" @is-cancel="quoteShow = false; quote = null" :showConfirm="false">
        <div class="confirmation-content" style="margin: -2rem 0 !important">
            <quote-result :quote="quote" :resultView="200" />
        </div>
    </is-dialog>
</template>
<script>
    import { mapGetters } from 'vuex';
    import { useVuelidate } from '@vuelidate/core';
    import quote from '@/components/facility/rates/quote/quote-result-form.vue';
    export default {
        name: 'reportGrid',
        setup: () => ({ v$: useVuelidate() }),
        props: {
            formData: { type: Object },
            filterKey: { type: Number, default: null },
            isAdmin: { type: Boolean, default: false }
        },
        components: {
            'quote-result': quote
        },
        data() {
            return {
                type: -1,
                filter: {},
                columns: [],
                deleteDisplay: ["code", "name"],
                searchFields: ["code", "name"],
                activity: { loading: true, download: false},
                source: {
                    total: 0,
                    data: []
                },
                modelState: null,
                initialBind: false,
                quote: null,
                quoteShow: false,
                selected: null
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            scopeCustomerDS() {
                return this.$config.common.$customer(this.user?.environmentID ?? -100, this.$vm.$appSession.warehouse.id ?? this.user.warehouseID, this.user);
            },
            scopeWarehouseDS() {
                return this.$config.common.$warehouse(this.user?.environmentID ?? -100, this.user);
            },
            apiData() {
                return {
                    ServiceHeader: {
                        Username: "TEMP",
                        Password: "TESTESTEDET",
                        ClientID: "TESTTESTTESTTESTTEST",
                        ClientSecret: "TESTEDREDREDTETEDEDETEREDEDEREDE"
                    },
                    Request: {
                        DC: this.formData?.warehouseID > 0 ? this.scopeWarehouseDS.filter(x => x.id === this.formData.warehouseID)[0]?.code : null,
                        Customer: this.formData?.customerID > 0 ? this.scopeCustomerDS.filter(x => x.id === this.formData.customerID)[0]?.code : null,
                        Account: this.formData?.serviceProviderAccountCode,
                        Carrier: this.formData?.serviceProviderID > 0 ? this.$vm.$providers.filter(y => y.id === this.formData.serviceProviderID)[0]?.code : null,
                        ScopeDetail: {
                            IncludeArchive: this.formData?.includeArchived,
                            CollectionDate: {
                                From: this.$filters.formatDateWithFormat(this.formData.collectionDateFrom ?? new Date(), "YYYY-MM-DD"),
                                To: this.$filters.formatDateWithFormat(this.formData.collectionDateTo ?? new Date(), "YYYY-MM-DD")
                            },
                            ManifestDate: {
                                From: this.formData.manifestDateFrom !== undefined && this.formData.manifestDateFrom !== null ? this.$filters.formatDateWithFormat(this.formData.manifestDateFrom ?? new Date(), "YYYY-MM-DD") : null,
                                To: this.formData.manifestDateTo !== undefined && this.formData.manifestDateTo !== null ? this.$filters.formatDateWithFormat(this.formData.manifestDateTo ?? new Date(), "YYYY-MM-DD") : null
                            },
                            DispatchDate: {
                                From: this.formData.dispatchDateFrom !== undefined && this.formData.dispatchDateFrom !== null ? this.$filters.formatDateWithFormat(this.formData.dispatchDateFrom ?? new Date(), "YYYY-MM-DD") : null,
                                To: this.formData.dispatchDateTo !== undefined && this.formData.dispatchDateTo !== null ? this.$filters.formatDateWithFormat(this.formData.dispatchDateTo ?? new Date(), "YYYY-MM-DD") : null
                            }
                        }
                    }
                }

            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                });
            },
            'filterKey': {
                handler() {
                    this.$nextTick(() => {
                    this.getSource();
                    });
                }, deep: true
            },
            activity: {
                handler() {
                    this.$emit('is-sync', this.activity);
                }, deep: true
            },
            selected: {
                handler() {
                    this.$emit('is-selected', this.selected);
                }, deep: true
            }
        },
        methods: {
            async getSource() {
                this.$nextTick(() => { this.activity.loading = true; });
                this.selected = [];
                this.$nextTick(() => {
                    this.$axios.post(this.$config.config.endpoint.api + `/services/report/v2/consignment`.replaceAll("//", "/"), this.apiData)
                        .then((response) => {
                            this.source.data = response?.data?.result ?? [];
                            this.source.total = this.source.data.length;
                            this.$nextTick(() => { this.activity.loading = false; });
                        })
                        .catch(() => {
                            this.source.data = [];
                            this.source.total = 0;
                            this.$nextTick(() => { this.activity.loading = false; });
                        });
                });

            },
            onDownload() {
                this.$nextTick(() => { this.activity.download = true; });
                this.$axios.post(this.$config.config.endpoint.api + `/services/report/v2/consignment/export`.replaceAll("//", "/"), this.apiData)
                    .then((response) => {
                        this.$config.common.$downloadFile(response.data.result.base64File, response.data.result.contentType, response.data.result.fileName);
                        this.$nextTick(() => { this.activity.download = false; });
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Failed To Delete Record', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        this.$nextTick(() => { this.activity.download = false; });
                    });
            },
            getJson(transactionID) {
                this.$axios.get(this.$config.config.endpoint.api + `/services/facility/quote/consignments/${transactionID}/json`.replaceAll("//", "/"))
                    .then((response) => {
                        this.quote = JSON.parse(response.data);
                    })
                    .catch((ex) => {
                        this.quote = null;
                        this.quoteShow = true;
                        console.log(ex);
                    });
            }
        },
        async created() {
            this.activity.loading = false;
        },
        async mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.loading = false;
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    .p-picklist-header {
        padding: 0 10px !important;
    }
</style>