<template>
    <form-record :model="model" :modelState="v$" :validation="v$" @is-reset="resetRecord" @is-refresh="rebindTicker()" :showSend="false" :showClone="false" :title="model.id > 0 ? `[${model.code}]  ${model.name}` : `Add New Service Group`" :subTitle="model.id > 0 ? `` : `Create a new service group.`">
        <div class="p-grid-flex p-d-flex">
            <div class="p-col-4">
                <form-input id="code" :v$="v$" v-focus v-uppercase :required="true" :value="model.code" @is-sync="model.code = $event.value"></form-input>
                <form-input id="name" :v$="v$" :value="model.name" :required="true" @is-sync="model.name = $event.value"></form-input>
            </div>
            <div class="p-col-4">
                <form-input id="description" :rows="5" type="textarea" label="Description" :v$="v$" :value="model.description" @is-sync="model.description = $event.value" />
            </div>
            <div class="p-col-4">
                <form-lookup id="customerID" label="Customer" :required="true" :disabled="true" :source="customers" :value="model.customerID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="model.customerID = $event.value" />
            </div>
        </div>
        <div class="p-grid">
            <div class="p-col-12">
                <pv-tab style="margin: 0 -1rem !important;">
                    <pv-tabpanel header="Rules & Conditions">
                        <rule-form :serviceGroupID="model?.id" :formProducts="productSource" :formProductOptions="productOptionSource" :warehouseScopes="warehouses ?? []" :formData="model" :limitedAccess="limitedAccess" @is-refresh="rebindTicker()"></rule-form>
                    </pv-tabpanel>
                    <pv-tabpanel header="Products">
                        <product-form :serviceGroupID="model?.id" :formData="model" @is-sync="ProductSync($event)" @is-option="OptionSync($event)"></product-form>
                    </pv-tabpanel>
                </pv-tab>
            </div>
        </div>
        <div v-if="model?.id > 0 && limitedAccess" style="margin: -0.5rem -0.99rem !important">
            <pv-accordion :activeIndex="-1" :multiple="false" id="ServiceGroupAccordion">
                <pv-accordion-tab>
                    <template #header>
                        <div class="p-d-flex p-nogutter p-jc-between" style="width:100%">
                            <div style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;">
                                <span class="p-accordion-header-text" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> <span><i class="fa fa-history"></i></span> &nbsp; <span v-html="`LOGS`"></span> </span>
                            </div>
                            <div style="margin-right: 0.5rem;text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 7rem) !important;">
                            </div>
                        </div>
                    </template>
                    <div style="width: calc(100% + 2.5rem); padding: 0; margin: -2rem -1.3rem; margin-bottom: -1rem;">
                        <pv-tab>
                            <pv-tabpanel header="Logs">
                                <auditlog-grid :formData="model" :forceRebindTicker="forceRebindTicker.logs"></auditlog-grid>
                            </pv-tabpanel>
                        </pv-tab>
                    </div>
                </pv-accordion-tab>
            </pv-accordion>
        </div>
    </form-record>
</template>

<script>
    
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    import validator from '@/assets/_js/cosmos-validators.js';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength } from '@vuelidate/validators';
    import productForm from '@/components/ruleengine/product-form.vue';
    import ruleForm from '@/components/ruleengine/rule-form.vue';
    import { mapGetters } from 'vuex';
    import auditLogGrid from '@/components/ruleengine/auditlog-grid.vue';
    const moduleName = 'servicegroup';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            'product-form': productForm,
            'rule-form': ruleForm,
            'auditlog-grid': auditLogGrid
        },
        data() {
            return {
                dialog: {
                    show: false,
                    deallocate: false,
                    reason: null,
                    allocate: false
                },
                packages: null,
                products: [],
                productOptions: [],
                accessScopes: [],
                keys: null,
                session: null,
                forceRebindTicker: { logs: null } 
            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                    if (this.user?.mask)
                        this.accessScopes = this.$config.common.$accessScope(this.user?.mask);

                });
            }
        },
        validations() {
            return {
                model: {
                    code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
                    name: { required, minLength: minLength(1), maxLength: maxLength(255), $autoDirty: true },
                    description: { minLength: minLength(1), maxLength: maxLength(1000), $autoDirty: true },
                    customerID: { required, $autoDirty: true }
                }
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            sessionKeys() { return this.$store.getters["auth/session"]; },
            cdnUrl() {
                return this.$config.config.endpoint.cdn;
            },
            serviceProviderProduct() { return this.$store.getters["providerproduct/dsGET"].data ?? null; },
            customers() { return (this.accessScopes?.customers ?? []) },
            warehouses() {
                return (this.accessScopes?.warehouses ?? [])
                    .filter(wh => (this.model.customerID ?? 0) === 0 || ((this.model.customerID ?? 0) > 0 && (wh.customers ?? []).filter(cust => cust === (this.model.customerID ?? 0)).length > 0)) ?? [];
            },
            productSource() {
                return this.products;
            },
            productOptionSource() {
                return this.productOptions;
            },
            limitedAccess() {
                return ((this.user?.userType ?? 0) === this.userTypes.SYSTEM_ADMINISTRATOR);
            },
            userTypes() {
                return {
                    GLOBAL_ADMINISTRATOR: 0,
                    SYSTEM_ADMINISTRATOR: 2,
                    ENVIRONMENT_ADMINISTRATOR: 3,
                    ENVIRONMENT_USER: 4,
                    CUSTOMER_ADMINISTRATOR: 5,
                    CUSTOMER_USER: 6,
                    RETAILER_USER: 7
                }
            }
        },
        methods: {
            async ProductSync(e) {
                this.products = e.model;
            },
            async OptionSync(e) {
                this.productOptions = e.model;
            },
            isWithFuelCharge(data) {
                return data?.Quote?.ItemizedCharges?.filter((x) => x.Description === "Fuel Surcharge").length > 0;
            },
            getDate(data) {
                if (data != null) {
                    return new Date().setFullYear(Number(data.substring(0, 4)), Number(data.substring(4, 6)) - 1, Number(data.substring(6, 8)))
                }
                return new Date();
            },
            getTime(data) {
                if (data != null) {
                    return new Date().setHours(Number(data.substring(0, 2)), Number(data.substring(2, 4)), Number(data.substring(4, 6)))
                }
                return new Date();
            },
            async getService() {
                this.serviceactivity.serviceloading = true;
                this.$axios.post(this.$config.config.endpoint.polaris + `/consignment/services?additionalinfo=quote`.replaceAll("//", "/"), this.apiService)
                    .then((response) => {
                        this.productservice = response.data.Result.Services;
                        this.serviceactivity.serviceloading = false;
                    })
                    .catch((ex) => {
                        this.serviceactivity.serviceloading = false;
                        console.log(ex);
                    });
            },
            async getServiceProviderProduct(id) {
                return await this.$store.dispatch("providerproduct/getCustom", '/services/Settings/serviceproviderproduct/id/' + id);
            },
            syncPackage(e) {
                this.packages = e;
                if ((this.packages?.length ?? 0) > 0) {
                    this.getService();
                }

            },
            getCol(e) {
                switch (e) {
                    case 0:
                        return "4"
                    default:
                        return "3"
                }
            },
            rebindTicker() {
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.forceRebindTicker.logs = Math.random();
                    }, 500)
                });
            },
        },
        created() {
            if (this.user?.mask)
                this.accessScopes = this.$config.common.$accessScope(this.user?.mask);
        },
        mounted() {
            this.activity.loading = true;
            if (this.user?.mask)
                this.accessScopes = this.$config.common.$accessScope(this.user?.mask);
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style scoped>
    span.custom-marker {
        display: flex;
        border-radius: 50%;
        height: 2rem;
        width: 2rem;
        z-index: 1;
        justify-content: center;
        -webkit-box-align: center;
        box-shadow: 1px 1px #888888;
    }

        span.custom-marker > i {
            float: right;
        }

    .p-timeline-left .p-timeline-event-opposite {
        text-align: left;
    }

    .responsive-iframe {
        width: 100%;
        height: 450px;
    }
    .picklist-item {
        display: flex;
        align-items: center;
        padding: .2rem;
        width: 100%;
    }
    .picklist-list-detail {
        flex: 1 1 0;
    }
    .picklist-list-action {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .custom-skeleton {
        border: 1px solid var(--surface-d);
        border-radius: 4px;
    }
    .custom-skeleton ul {
        list-style: none;
    }

    .custom-skeleton ul > li {
        padding: 0.75rem;
    }
    .p-picklist-header {
        padding: 0 10px !important;
    }
    .p-picklist-buttons.p-picklist-target-controls {
        display: inline-flex !important;
        margin: auto;
    }

    #ServiceGroupAccordion .p-accordion-header-link {
        padding: 0.75rem 0.75rem;
        border-bottom: 1px solid var(--gray-200);
    }

</style>
