<template>
    <is-dialog header="Delete Record?" :visible="dialog.delete && !dialog.edit && !dialog.add" @is-confirm="onDelete" @is-cancel="this.dialog.delete = false; this.dialog.add = false; this.dialog.edit = false;">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
            </div>
            <div>
                You are about to delete this record.

                <!--<table style="margin: 10px 0; padding: 10px; background-color: var(--surface-d); width: 100%;">
                    <tr v-for="data in deleteDisplay" :key="data.id">
                        <th style="text-align:left;">{{ $filters.titleize(data) }}</th>
                        <td>:</td>
                        <td> {{ forDeleteRule[data] }}</td>
                    </tr>
                </table>-->
                <br />

                Please be aware of the following:
                <ul>
                    <li>For records that uses unique code field, you cannot reuse the same code again.</li>
                    <li>For records that has activity, the record will only be disabled and archived.</li>
                    <li>Also deleting a record may be irreversible action.</li>
                </ul>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
    <pv-accordion :activeIndex="activeIdx" id="ConditionAccordion">
        <pv-accordion-tab v-for="(item,index) in conditionSource.filter(x => x.parentID === 0 && x.temporaryParentID === 0)" :key="item.id">
            <template #header>
                <i class="pi pi-bars"></i>
                <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; " :title="`${displayConditionForm(item)}`">{{displayConditionForm(item)}}</div>
                <div class="p-as-end"><pv-button :disabled="((formData.id > 0) && (conditionSource?.filter(x => x.parentID === 0 && x.temporaryParentID === 0) ?? []).length <= 1)" style="margin: 0 8px;" class="p-button p-button-raised p-button-danger" icon="pi pi-trash" title="Delete Condition" @click="{dialog.forDelete = item; dialog.delete = true;}"></pv-button></div>
            </template>
            <condition-detail-form :key="item.id" :lookUpSource="lookUpSource" :formData="item" :formSubData="conditionSource.filter(x => (x.parentID === item.id && x.temporaryParentID === item.temporaryID) || (item.id > 0 ? x.parentID === item.id && x.temporaryParentID === item.id : false))" :indexNo="index" @is-sync="isSync($event)" @is-delete="isDelete($event)"></condition-detail-form>
        </pv-accordion-tab>
    </pv-accordion>
    <div class="p-d-flex" style="margin-top:10px;">
        <pv-button icon="pi pi-fw pi-plus" label="Add Condition" style="margin-right: 5px;" @click="addCondition"></pv-button>
    </div>
</template>
<script>
    import conditiondetailform from '@/components/ruleengine/v2/condition-detail-form.vue';
    export default {
        name: 'RuleConditionForm',
        emits: ["is-sync"],
        props: {
            serviceRuleID: { type: Number, default: -1 },
            serviceGroupID: { type: Number, default: -1 },
            formData: { type: Object },
            lookUpSource: { type: Object }
        },
        components: {
            'condition-detail-form': conditiondetailform
        },
        data() {
            return {
                conditions: [],
                activity: {
                    loading: true
                },
                dialog: {
                    header: "Delete Record?",
                    add: false,
                    edit: false,
                    delete: false,
                    forDelete: null,
                },
            }
        },
        computed: {
            conditionSource() {
                return this?.conditions ?? [];
            },
            activeIdx() {
                return this.conditionSource.filter(x => x.parentID === 0 && x.temporaryParentID === 0).length > 0 ? this.conditionSource.filter(x => x.parentID === 0 && x.temporaryParentID === 0).length - 1 : 0;
            }
        },
        watch: {
            //'model': { handler() { this.$emit("is-sync", { model: this.model }); }, deep: true },
           
            /*'model': { handler() { this.$emit("is-sync", { model: this.model }); }, deep: true },*/
            //'serviceProviderID': { handler() { this.rebind() }, deep: true },
            //'formData': {
            //    handler() {
            //        this.$nextTick(() => {
            //            this.model = this.$props.formData; 
            //            this.model.conditions = this.model?.conditions ?? [];
            //            this.conditions = this.model?.conditions ?? [];
            //        });
            //    }, deep: true
            //},
            //'formData.serviceProviderID': { handler() { this.rebind() }, deep: true }
        },
        methods: {
            async removeCondition(e) {
                let _self = this;
                await this.$store.dispatch("servicegrouprulecondition/removeRecord", e.mask).then(() => {
                })
                .catch((ex) => {
                    _self.errors = ex.data;
                });
            },
            addCondition() {
                this.conditions.push({
                    id: 0,
                    code: ("SC" + Math.random().toString().replace(".", "")).slice(0, 20),
                    name: ("SC" + Math.random().toString().replace(".", "")).slice(0, 20),
                    temporaryID: Number(Math.random().toString().replace(".", "")),
                    parentID: 0,
                    temporaryParentID: 0,
                    serviceGroupRuleID: this.model?.id > 0 ? this.model.id : this.model?.temporaryID,
                    logicType: 100,
                    categoryTypeID: 1,
                    conditionTypeID: 1,
                    dataType: 70,
                    operatorType: 100,
                    referenceID: null,
                    referenceType: 200,
                    textValue: null,
                    decimalValue: 0,
                    currencyID: 3,
                    countryTradeBlocID: null,
                    dimensionUnit: 100,
                    weightUnit: 100,
                    numericValue: 0,
                    warehouseID: null,
                    text: null
                });
            },
            isSync(e) {
                this.$nextTick(() => {
                    var location = this.$filters.init(e.model);
                    var index = -1;
                    var modelIndex = -1;
                    if (location.id > 0)
                        index = this.conditions.findIndex(x => x.id === location.id);
                    else
                        index = this.conditions.findIndex(x => x.temporaryID === location.temporaryID);
                    if (index !== -1) {
                        this.conditions[index] = this.$filters.init(e.model);
                        if (location.id > 0)
                            modelIndex = this.model.conditions.findIndex(x => x.id === location.id);
                        else
                            modelIndex = this.model.conditions.findIndex(x => x.temporaryID === location.temporaryID);
                        if (modelIndex === -1)
                            this.model.conditions.push(this.$filters.init(e.model))
                        else
                            this.model.conditions[index] = this.$filters.init(e.model);
                    }
                    else {
                        if (location.temporaryParentID > 0) {
                            this.conditions.push(this.$filters.init(e.model));
                           
                            if (location.id > 0)
                                modelIndex = this.model.conditions.findIndex(x => x.id === location.id);
                            else
                                modelIndex = this.model.conditions.findIndex(x => x.temporaryID === location.temporaryID);
                            if (modelIndex === -1)
                                this.model.conditions.push(this.$filters.init(e.model))
                            else
                                this.model.conditions[index] = this.$filters.init(e.model);
                        }
                    }
                });
            },
            isDelete(e) {
                this.$nextTick(() => {
                    var location = this.$filters.init(e.model);
                    var index = -1;
                    if (location.id > 0)
                        index = this.conditions.findIndex(x => x.id === location.id);
                    else
                        index = this.conditions.findIndex(x => x.temporaryID === location.temporaryID);
                    if (index !== -1) {
                        this.conditions[index] = this.$filters.init(e.model);
                        var modelIndex = -1;
                        if (location.id > 0)
                            modelIndex = this.model.conditions.findIndex(x => x.id === location.id);
                        else
                            modelIndex = this.model.conditions.findIndex(x => x.temporaryID === location.temporaryID);
                        if (modelIndex !== -1) {
                            if (this.model.conditions[modelIndex].mask != null) {
                                let _forDelete = this.model.conditions[modelIndex];
                                this.model.conditions.splice(modelIndex, 1);
                                this.removeCondition(_forDelete);
                            }
                            else {
                                this.model.conditions.splice(modelIndex, 1);
                            }
                        }
                    }
                    this.dialog.forDelete = null;
                });
            },
            onDelete() {
                this.dialog.delete = false;
                this.isDelete({ model: this.dialog.forDelete });
            },
            displayConditionForm(e) {
                let displayText = e.text;
                this.conditionSource.filter(x => x.parentID === e.id && x.temporaryParentID === e.temporaryID)?.forEach(x => {
                    displayText += ' ' + (this.$vm.$enums['logictype'].filter((t) => t.id == x.logicType)[0]?.text ?? "").toLowerCase() + ' ' + x.text;
                });
                return (displayText ?? "").indexOf("Cosmos.Common.ServiceGroupRuleCondition") !== -1 ? "" : displayText;
            }
        },
        mounted() {
            //if (this.accountID === -1)
            //    this.rebind();
            //else
            //    this.model = this.$props.formData.products;

            this.$nextTick(() => {
                let _formData = this.$filters.init(this.formData);
                _formData;
                this.model = this.$filters.init(null);
                this.model = this.$filters.init(_formData);
                this.model = this.$filters.init(this.model);
                this.model.conditions = this.model?.conditions ?? [];
                this.conditions = this.model?.conditions ?? [];                
            });
            
        }
    }
</script>
<style>
    #ConditionAccordion .p-accordion-header-link {
        padding: 0.10rem 0.25rem;
        border-bottom: 1px solid var(--gray-200);
    }
    #ConditionAccordion .p-accordion-content {
        padding: 0.55rem 0.50rem;
    }
    

    .p-picklist-header {
        padding: 0 10px !important;
    }
    .custom-skeleton { border: 1px solid var(--surface-d); border-radius: 4px; }
    .custom-skeleton ul { list-style: none; }
    .custom-skeleton ul > li { padding: 0.75rem; }
</style>