<template>
    <cosmos-grid id="ConsignmentList" sourceType="url" :source="gridUrl" :multiLevel="true" :searchFilters="searchFields" :searchKeyword="activeFilters['global'].value" :pageSize="15" :filters="activeFilters" :autoGenerate="false" :columns="columns" :deleteColumns="deleteDisplay" :showExport="false" :forceRefresh="forceRefresh" :selectedReset="selectedReset" :showAdd="showToolBar" :showOperation="showToolBar" @is-selected="onSelected($event)" @is-source="onSource($event)" style="position: relative;" :baseRecordID="0">
        <template #toolbar>
            <div class="p-d-flex p-jc-between p-ai-center p-grid">
                <div class="p-col-12 p-md-6" style="padding:0.20rem; margin:0; padding-right:0.2rem" v-if="showToolBar">
                    <span>
                        <pv-button icon="pi pi-fw pi-plus" :disabled="activity.dirty || activity.export || activity.binding" label="New Consignment" style="margin: 0.20rem;" @click="onCreate"></pv-button>
                        <pv-button icon="pi pi-fw pi-print" :disabled="activity.dirty || activity.export || activity.binding" v-if="(statusFilter > 100 && statusFilter < 500)" label="Print All" style="margin: 0.20rem;" @click="onBatchPrint('label')"></pv-button>
                        <pv-button icon="pi pi-fw pi-upload" :disabled="activity.dirty || activity.export || activity.binding" label="Import" style="margin: 0.20rem;" @click="{this.$router.push({ name: `importrequest-index` });}"></pv-button>
                        <pv-button icon="pi pi-fw pi-file-excel" :disabled="activity.dirty || activity.export || activity.binding" label="Export To Excel" :loading="activity.export" style="margin: 0.20rem;" @click="onExportToExcel()"></pv-button>
                        <pv-button v-if="showBookCollection"  v-tooltip.top="{value : 'Book Consignments'}" class="p-button-success" icon="mdi mdi-book-variant-multiple" label="Book Collection" :disabled="!isValidBook || (activity.dirty || activity.export || activity.binding)" style="margin: 0.20rem;" @click="onClickBookCollection"></pv-button>
                        <pv-button v-if="showPOD"  icon="mdi mdi-mailbox-up-outline" label="POD" class="p-button-success" :disabled="(selectedItems?.filter(x => x?.status === 500).length ?? 0) === 0 || (activity.dirty || activity.export || activity.binding)" style="margin: 0.20rem;" @click="onClickPOD"></pv-button>
                    </span>
                </div>
                <div class="p-col-12 p-md-6" style="padding:0.20rem; margin:0; padding-right:0.2rem" v-else-if="!showToolBar">
                    <span>
                        <pv-button icon="pi pi-fw pi-print" v-if="(foreignkeyid > 0) && ((source?.data ?? []).lenth > 1)" label="Print All" style="margin: 0.20rem;" @click="onBatchPrint('label')"></pv-button>
                    </span>
                </div>
                <div class="p-d-flex p-jc-end p-grid p-col" v-if="showToolBar">
                    <div class="p-col-12 p-md-4" style="padding: 0.2rem; margin: 0; padding-right: 0.2rem; padding-top: 0.25rem">
                        <form-lookup id="statusDropDown" type="enum" source="consignmentstatustype" :sorted="false" :value="statusFilter" :hideLabel="true" @is-sync="statusFilter = $event?.value ?? 1;  forceRefresh = true;" style="margin:0; padding:0;" :disabled="activity.dirty || activity.export || activity.binding"/> <!--objectFilter.enumFilter = $event.value; syncFilter();-->
                    </div>
                    <div v-if="showToolBar" :class="`p-col-3 p-md-4`" style="padding: 0.2rem; margin: 0; padding-right: 0.2rem; padding-top: 0.25rem">
                        <span class="p-input-icon-right" style="width: 100%">
                            <i class="pi pi-search" />
                            <pv-input ref="gridSearchText" id="gridSearchKey" :disabled="activity.dirty || activity.export || activity.binding" v-model="activeFilters['global'].value" placeholder="Keyword Search" style="width: 100%;" @keyup="onForceFilter" />
                        </span>
                    </div>

                </div>
                <div v-else class="p-d-flex p-jc-end p-grid p-col" style="padding:0; margin:0;">
                    <span class="p-input-icon-right" style="min-width: 250px; max-width: 300px; width: 100%">
                        <i class="pi pi-search" />
                        <pv-input ref="gridSearchText" id="gridSearchKey" v-model="activeFilters['global'].value" placeholder="Keyword Search" style="width: 100%;" @keyup="onForceFilter" />
                    </span>
                </div>

            </div>
            <div class="p-d-flex p-jc-end p-ai-center p-grid" v-if="showBookCollection">
                <div class="p-col-12 p-md-2" style="padding: 0.20rem; margin: 0; padding-right: 0.2rem">
                    <form-lookup id="customerID" label="Customer" :source="scopeCustomerDS" :value="filterSource.customerID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onCustomer($event)" :hideLabel="true" style="margin:0; padding:0;" filterPlaceholder="Customer" ph="Select Customer" v-tooltip.top="{value: 'Customer Filter'}" />
                </div>
                <div class="p-col-12 p-md-2" style="padding:0.20rem; margin:0; padding-right:0.2rem">
                    <form-lookup id="serviceProviderID" label="Carrier" :source="serviceProviderDS" :value="filterSource.serviceProviderID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onProvider($event)" :hideLabel="true" style="margin:0; padding:0;" filterPlaceholder="Carrier" ph="Select Carrier" v-tooltip.top="{value: 'Carrier Filter'}" />
                </div>
                <div class="p-col-12 p-md-2" style="padding: 0.20rem; margin: 0; padding-right: 0.2rem">
                    <form-lookup id="serviceProviderAccountID" label="Account" :source="serviceProviderAccountDS" :value="filterSource.serviceProviderAccountID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="onAccount($event)" :hideLabel="true" style="margin:0; padding:0;" filterPlaceholder="Account" ph="Select Account" v-tooltip.top="{value: 'Account Filter'}" />

                </div>
                <div class="p-col-12 p-md-2" style="padding: 0.20rem; margin: 0; padding-right: 0.2rem">
                    <form-datetime id="dateFilter" type="date" :value="collectionDateFilter" @is-sync="onCollectionSync($event)" :hideLabel="true" style="margin:0; padding:0;" ph="Select Collection Date" v-tooltip.top="{value: 'Collection Date Filter'}" />
                </div>
                <!--<div class="p-col-12 p-md-2" style="padding: 0.20rem; margin: 0; padding-right: 0.2rem">
                <form-boolean id="includePastCollectionDate" :value="filterSource.includePastCollectionDate" type="toggle" label="Include Past Shipment?" textPosition="left" style="margin:0; padding:0;" @is-sync="filterSource.includePastCollectionDate = $event.value" />
            </div>-->

            </div>
        </template>
        <pv-grid-column field="tradeType" headerStyle="max-width: 3rem" style="max-width: 3rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                        <span><i style="font:0.75rem;" :class="`fa fa-solid fa-square-${getTradeType(slotProps.data.tradeType)} fa-2x`" v-tooltip.top="{value: `${slotProps.data.tradeTypeText}`}"></i></span>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="mask" style="`min-width: 1rem; border-left: 1px solid var(--surface-d); object-position: center;`" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <pv-button icon="pi pi-clone" iconPos="right" @click="{ this.dialog.clone = true; this.clone.mask = slotProps.data?.mask;}" class="p-button-info" title="Clone" v-tooltip.top="{value: 'Clone Consignment'}" />
                <span v-if="(statusFilter !== 100)" style="margin-left:0.25rem">
                    <pv-button v-if="slotProps.data?.serviceProviderID && slotProps.data?.primaryCarrierReference" icon="pi pi-print" iconPos="right" @click="onPrintLabel(slotProps.data,'label')" class="p-button-warning" v-tooltip.top="{value: 'Print Shipping Label'}" />
                    <pv-button v-if="(slotProps.data?.isReturnable ?? false) && (slotProps.data?.hasReturnLabel ?? false)" icon="far fa-print" iconPos="right" @click="onPrintLabel(slotProps.data,'return')" class="p-button-warning" v-tooltip.top="{value: 'Print Return Label'}" />
                </span>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerName" header="Customer" headerStyle="min-width: 10rem" style="min-width: 10rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                        {{slotProps.data.customerName}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="code" header="Transaction ID" headerStyle="min-width: 13rem;" style="min-width: 13rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                        <a :href="`/track/${slotProps.data?.code}`" target="_self" style="font-weight: bold;">{{slotProps.data.code}}</a>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference" header="Reference 1" headerStyle="min-width: 13rem" style="min-width: 13rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                        <router-link style="font-size: 1rem; font-weight:700;" :to="{ name: 'consignment-edit', params: { mask: slotProps.data.mask }}">{{slotProps.data.customerReference}}</router-link>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference2" header="Reference 2" headerStyle="min-width: 13rem" style="min-width: 13rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                        {{slotProps.data.customerReference2}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference3" header="Reference 3" headerStyle="min-width: 13rem" style="min-width: 13rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                        {{slotProps.data.customerReference3}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="shipFrom_CountryName" header="SENDER" headerStyle="min-width: 25rem;max-width:25rem;" style="min-width: 25rem; max-width: 25rem; text-align: left; object-position: center; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; overflow: hidden; text-overflow: ellipsis;">
                        <i :class="`flag flag-${$vm.$countries.filter((x) => x.id == slotProps.data?.shipFrom_CountryID)[0]?.code.toLowerCase()}`" v-tooltip.top="{value : (slotProps.data.shipFrom_CountryName ?? 'GB')}" style="margin-top: -0.15rem"></i>  {{slotProps.data?.shipFrom_FirstName ?? ''}} {{slotProps.data?.shipFrom_LastName ?? ''}},
                        <span v-tooltip.top="{value : `${ slotProps.data.shipFrom_City ? slotProps.data.shipFrom_City + ',' : '' } ${ slotProps.data.shipFrom_State ? slotProps.data.shipFrom_State + ',' : '' } ${ slotProps.data.shipFrom_PostalCode ? slotProps.data.shipFrom_PostalCode + ',' : '' } ${ (slotProps.data.shipFrom_CountryName ?? 'GB').substring(0,2)}` }">{{ slotProps.data.shipFrom_City ? slotProps.data.shipFrom_City + ',' : '' }} {{ slotProps.data.shipFrom_State ? slotProps.data.shipFrom_State + ',' : '' }} {{ slotProps.data.shipFrom_PostalCode ? slotProps.data.shipFrom_PostalCode + ',' : '' }} {{ (slotProps.data.shipFrom_CountryName ?? 'GB').substring(0,2)}}</span>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="shipTo_CountryName" header="RECIPIENT" headerStyle="min-width: 25rem; max-width:25rem;" style="min-width: 25rem; max-width: 25rem; text-align: left; object-position: center; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; ">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; overflow: hidden; text-overflow: ellipsis;">
                        <i :class="`flag flag-${$vm.$countries.filter((x) => x.id == slotProps.data?.shipTo_CountryID)[0]?.code.toLowerCase()}`" v-tooltip.top="{value : (slotProps.data.shipTo_CountryName ?? 'GB')}" style="margin-top: -0.15rem"></i>  {{slotProps.data?.shipTo_FirstName ?? ''}} {{slotProps.data?.shipTo_LastName ?? ''}},
                        <span v-tooltip.top="{value: `${ slotProps.data.shipTo_City ? slotProps.data.shipTo_City + ',' : '' } ${ slotProps.data.shipTo_State ? slotProps.data.shipTo_State + ',' : '' } ${ slotProps.data.shipTo_PostalCode ? slotProps.data.shipTo_PostalCode + ',' : '' } ${ (slotProps.data.shipTo_CountryName ?? 'GB').substring(0,2)}`}">{{ slotProps.data.shipTo_City ? slotProps.data.shipTo_City + ',' : '' }} {{ slotProps.data.shipTo_State ? slotProps.data.shipTo_State + ',' : '' }} {{ slotProps.data.shipTo_PostalCode ? slotProps.data.shipTo_PostalCode + ',' : '' }} {{ (slotProps.data.shipTo_CountryName ?? 'GB').substring(0,2)}}</span>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !(statusFilter === 1 || statusFilter > 100) }]" field="primaryCarrierReference" header="Shipping Info" style="text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div v-if="slotProps.data?.serviceProviderID > 0" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                        <span><img v-tooltip.top="{value : `${$vm.$providers.filter((x) => x.id == slotProps.data?.serviceProviderID)[0]?.name}`}" style="vertical-align: middle; width: 35px; height: 20px; max-height: 20px; max-width: 35px; object-fit: contain;" :src="`/_assets/_integration/${$vm.$providers.filter((x) => x.id == slotProps.data?.serviceProviderID)[0]?.mask}.png`" onerror="this.onerror=null; this.src = '/_assets/img/no-logo.png'" /> <a :href="`${$filters.formatString($vm.$providers.filter((x) => x.id == slotProps.data?.serviceProviderID)[0]?.trackingURL, slotProps.data.primaryCarrierReference,slotProps.data.shipTo_PostalCode)}`" target="_blank" style="font-weight: bold;"> {{slotProps.data.primaryCarrierReference}} </a> , {{providerProductDS?.filter((x) => x.id == slotProps.data?.serviceProviderProductID)[0]?.text}}</span>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !(statusFilter === 1 || statusFilter > 100) }]" field="returnReference" header="Return Info" style="text-align: left; object-position: center; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div v-if="slotProps.data?.serviceProviderReturnProductID > 0 && slotProps.data?.returnReference" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                        <span><img v-tooltip.top="{value : `${$vm.$providers.filter((x) => x.id == slotProps.data?.serviceProviderReturnID)[0]?.name}`}" style="vertical-align: middle; width: 35px; height: 20px; max-height: 20px; max-width: 35px; object-fit: contain;" :src="`/_assets/_integration/${$vm.$providers.filter((x) => x.id == slotProps.data?.serviceProviderReturnID)[0]?.mask}.png`" onerror="this.onerror=null; this.src = '/_assets/img/no-logo.png'" /> {{ slotProps.data?.returnReference }} <b>,</b> {{providerProductDS?.filter((x) => x.id == slotProps.data?.serviceProviderReturnProductID)[0]?.text}}</span>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !(statusFilter === 1 || statusFilter > 300) }]" field="collectionCarrierReference" header="Collection Info" style="text-align: left; object-position: center; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div v-if="slotProps.data?.collectionServiceProviderID > 0" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                        <span><img style="vertical-align: middle; width: 35px; height: 20px; max-height: 20px; max-width: 35px; object-fit: contain;" :src="`/_assets/_integration/${$vm.$providers.filter((x) => x.id == slotProps.data?.collectionServiceProviderID)[0]?.mask}.png`" onerror="this.onerror=null; this.src = '/_assets/img/no-logo.png'" /> {{ slotProps.data?.collectionCarrierReference }}</span>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !((statusFilter === 1 || statusFilter > 100) && user?.userType <= 3) }]" field="invoiceReference" header="Invoice Info" style=" text-align: left; object-position: center; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div v-if="slotProps.data?.invoiceReference" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                        <span>{{ slotProps.data?.invoiceReference }}</span>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !(statusFilter === 1 || statusFilter > 100) }]" field="milestone_AllocatedDate" header="Allocate Date" headerStyle="min-width: 7rem" style="min-width: 7rem; text-align: left; object-position: center; white-space: nowrap; ">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div v-if="slotProps.data?.milestone_AllocatedDate" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                        {{$filters.toDateDisplay(slotProps.data?.milestone_AllocatedDate)}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !(statusFilter === 1 || statusFilter > 100) }]" field="milestone_LabelPrintDate" header="Label Print Date" headerStyle="min-width: 7rem" style="min-width: 7rem; text-align: left; object-position: center; white-space: nowrap; ">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div v-if="slotProps.data?.milestone_LabelPrintDate" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                        {{$filters.toDateDisplay(slotProps.data?.milestone_LabelPrintDate)}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !(statusFilter === 1 || statusFilter > 200) }]" field="request_CollectionDate" header="Collection Date" headerStyle="min-width: 7rem" style="min-width: 7rem; text-align: left; object-position: center; white-space: nowrap; ">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div v-if="slotProps.data?.request_CollectionDate" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                        {{$filters.toDateDisplay(slotProps.data?.request_CollectionDate)}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !(statusFilter === 1 || statusFilter > 400) }]" field="collectionDispatchDate" header="Dispatch Date" headerStyle="min-width: 7rem" style="min-width: 7rem; text-align: left; object-position: center; white-space: nowrap; ">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div v-if="slotProps.data?.collectionDispatchDate" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                        {{$filters.toDateDisplay(slotProps.data?.collectionDispatchDate)}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !(statusFilter === 1 || statusFilter > 400) }]" field="milestone_OutForDeliveryDate" header="Out For Delivery" headerStyle="min-width: 7rem" style="min-width: 7rem; text-align: left; object-position: center; white-space: nowrap; ">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div v-if="slotProps.data?.milestone_OutForDeliveryDate" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                        {{$filters.toDateDisplay(slotProps.data?.milestone_OutForDeliveryDate)}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column :class="[{'p-field-hidden': !(statusFilter === 1 || statusFilter > 500) }]" field="milestone_PODDate" header="POD Date" headerStyle="min-width: 7rem" style="min-width: 7rem; text-align: left; object-position: center; white-space: nowrap; ">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div v-if="slotProps.data?.milestone_AllocatedDate" style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left; vertical-align: middle; display: inline; ">
                        {{$filters.toDateDisplay(slotProps.data?.milestone_PODDate)}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference4" header="Reference 4" headerStyle="min-width: 13rem" style="min-width: 13rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                        {{slotProps.data.customerReference4}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference5" header="Reference 5" headerStyle="min-width: 13rem" style="min-width: 13rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                        {{slotProps.data.customerReference5}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference6" header="Reference 6" headerStyle="min-width: 13rem" style="min-width: 13rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                        {{slotProps.data.customerReference6}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="customerReference8" header="Reference 7" headerStyle="min-width: 13rem" style="min-width: 13rem; text-align: left; object-position: center; text-overflow: ellipsis; white-space: nowrap;">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                        {{slotProps.data.customerReference7}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="status" :class="[{'p-field-hidden': !(statusFilter === 1)}]" header="Status" headerStyle="min-width: 10rem; max-width: 20rem;" style="min-width: 10rem; max-width: 20rem; text-align: center; object-position: center; white-space: nowrap; opacity: 1 !important;" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important; text-align:center;">
                        <div><span :class="`customer-badge status-${getStatus(slotProps.data.status)}`">{{$vm.$enums['consignmentstatustype'].filter((x) => x.id == slotProps.data.status)[0]?.text}}</span></div>
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="lastScan_Activity" header="Activity" headerStyle="min-width: 11.5rem;" style="min-width: 11.5rem; text-align: center; object-position: center; white-space: nowrap;" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <!--<pv-progress-bar :value="`${slotProps.data.status === 700 ? 100 :  slotProps.data.status * 0.10}`" :showValue="false" />-->
                <div class="p-d-flex">
                    <div style="width: 100%; padding: 0 !important; margin: 0 !important; text-align: left;">
                        {{slotProps.data.lastScan_Activity}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <pv-grid-column field="source" header="Source" headerStyle="min-width: 7rem; max-width: 20rem;" style="min-width: 7rem; max-width: 20rem; text-align: center; object-position: center; white-space: nowrap; opacity: 1 !important;" :frozen="true" :alignFrozen="'right'">
            <template #body="slotProps">
                <div class="p-d-flex">
                    <div style="width:100%;padding:0 !important; margin: 0 !important; text-align:center;">
                        {{$vm.$enums['source'].filter((x) => x.id == slotProps.data.source)[0]?.text}}
                    </div>
                </div>
            </template>
        </pv-grid-column>
        <template #expansion="slotProps">
            <div class="p-fluid p-grid">
                <div class="p-col-12">
                    <package-form :parentID="slotProps.data?.id" :currencyID="slotProps.data?.currencyID" :weightUnit="slotProps.data.weightUnit" :dimensionUnit="slotProps.data.dimensionUnit" :isHazardous="slotProps.data.isHazardous" :countryID="slotProps.data?.shipFrom_CountryID" :isDisabled="true"></package-form>
                </div>
            </div>
        </template>
    </cosmos-grid>
    <keep-alive>
        <is-dialog header="BOOK COLLECTION" :visible="dialog.book" icon="mdi mdi-book-variant-multiple" @is-confirm="onBookCollection()" @is-cancel="this.dialog.book = false" :confirmDisabled="action.processing" :cancelDisabled="action.processing" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '75vw'}">
            <div v-if="!action.processing" class="confirmation-content p-fluid p-grid">
                <div class="p-d-flex p-grid p-col-12">
                    <div class="p-col-12 p-md-4">
                        <h4 style="padding-bottom:0.5rem">PICK-UP SCHEDULE</h4>
                        <div>
                            <form-datetime id="collectionDate" :required="true" :value="bookCollectionModel.collectionDate" type="date" @is-sync="bookCollectionModel.collectionDate = $event.value" label="Pick-up Date" :v$="v$"></form-datetime>
                            <form-datetime id="cutOffTime" type="time" :required="true" :value="bookCollectionModel.cutOffTime" @is-sync="bookCollectionModel.cutOffTime = $event.value" :stepMinute="5" :allowInput="true" />
                            <form-datetime id="readyTime" type="time" :required="true" :value="bookCollectionModel.readyTime" @is-sync="bookCollectionModel.readyTime = $event.value" :stepMinute="5" :allowInput="true" />
                            <form-datetime id="collectionTime" type="time" :required="true" :value="bookCollectionModel.collectionTime" @is-sync="bookCollectionModel.collectionTime = $event.value" :stepMinute="5" :allowInput="true" />
                            <form-datetime id="closingTime" type="time" :required="true" :value="bookCollectionModel.closingTime" @is-sync="bookCollectionModel.closingTime = $event.value" :stepMinute="5" :allowInput="true" />
                            <form-datetime id="afterCloseTime" type="time" :required="true" :value="bookCollectionModel.afterCloseTime" @is-sync="bookCollectionModel.afterCloseTime = $event.value" :stepMinute="5" :allowInput="true" />
                        </div>

                    </div>
                    <div class="p-col-12 p-md-4">
                        <h4 style="padding-bottom:0.5rem">AFTER HOUR CONTACT</h4>
                        <div>
                            <form-contact id="contact" :value="contact" :useRegistrationNumber="false" :v$="v$" @is-sync="onBookTranslateContact($event)" :showDirectory="false" />
                        </div>
                    </div>
                    <div class="p-col-12 p-md-4">
                        <h4 style="padding-bottom:0.5rem">AFTER HOUR LOCATION</h4>
                        <div>
                            <form-address id="address" :value="address" :v$="v$" @is-sync="onBookTranslateAddress($event)" :useCompany="true" />
                        </div>
                    </div>
                </div>
                <div class="p-d-flex p-col-12">
                    <div class="p-col-12">
                        <cosmos-grid id="BookCollectionList" sourceType="array" :source="selectedItems?.length ?? 0 > 0 ? selectedItems : source.data ?? []" :sourcePaged="true" :searchFilters="searchFields" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="false" :showDelete="false" :showEdit="false" :showHeader="false" :showOperation="false" groupBy="groupCollection" :scrollableXS="true">
                            <pv-grid-column field="groupCollection" header="groupCollection" style="min-width: 10rem; text-align: center; object-position: center;">
                            </pv-grid-column>
                            <pv-grid-column field="code" header="Transaction ID" style="text-align: center; object-position: center;">
                                <template #body="slotProps">
                                    <div>
                                        <div class="p-d-flex">
                                            {{slotProps.data.code}}
                                        </div>
                                    </div>
                                </template>
                            </pv-grid-column>
                            <pv-grid-column field="shipTo_CountryName" header="Destination" style="min-width: 10rem; text-align: center; object-position: center;">
                                <template #body="slotProps">
                                    <div class="p-d-flex">
                                        <i :class="`flag flag-${$vm.$countries.filter((x) => x.id == slotProps.data?.shipTo_CountryID)[0]?.code.toLowerCase()}`" style="font-size: 0.8rem; margin-right:5px;" />
                                        <span v-tooltip.top="{value: `${ slotProps.data.shipTo_City ? slotProps.data.shipTo_City + ',' : '' } ${ slotProps.data.shipTo_State ? slotProps.data.shipTo_State + ',' : '' } ${ slotProps.data.shipTo_PostalCode ? slotProps.data.shipTo_PostalCode + ',' : '' } ${ (slotProps.data.shipTo_CountryName ?? 'GB').substring(0,2)}`}">{{ slotProps.data.shipTo_City ? slotProps.data.shipTo_City + ',' : '' }} {{ slotProps.data.shipTo_State ? slotProps.data.shipTo_State + ',' : '' }} {{ slotProps.data.shipTo_PostalCode ? slotProps.data.shipTo_PostalCode + ',' : '' }} {{ (slotProps.data.shipTo_CountryName ?? 'GB').substring(0,2)}}</span>
                                    </div>
                                </template>
                            </pv-grid-column>

                            <pv-grid-column field="primaryCarrierReference" header="Carrier Reference" style="min-width: 10rem;  text-align: center; object-position: center;">
                                <template #body="slotProps">
                                    <div>
                                        <div class="p-d-flex">
                                            {{slotProps.data.primaryCarrierReference}}
                                        </div>
                                    </div>
                                </template>
                            </pv-grid-column>
                            <pv-grid-column field="integrationProductID" header="Service" style="min-width: 10rem; text-align: center; object-position: center;">
                                <template #body="slotProps">
                                    <div class="p-d-flex" style="z-index: 100;">
                                        <div style="margin-right:5px;">
                                            <img style="width:35px; height:20px; max-height: 20px; max-width: 35px; object-fit: contain; object-position: top;" :title="`${$vm.$providers.filter((x) => x.id == slotProps.data?.serviceProviderID)[0]?.text}`" :src="`/_assets/_integration/${$vm.$providers.filter((x) => x.id == slotProps.data?.serviceProviderID)[0]?.mask}.png`" onerror="this.onerror=null; this.src = '/_assets/img/no-logo.png'" />
                                        </div>
                                        {{providerProductDS?.filter((x) => x.id == slotProps.data?.serviceProviderProductID)[0]?.text}}
                                    </div>
                                </template>
                            </pv-grid-column>
                            <template #groupheader="slotProps">
                                <div class="p-d-flex">
                                    <i :class="`flag flag-${$vm.$countries.filter((x) => x.id == slotProps.data?.shipFrom_CountryID)[0]?.code.toLowerCase()}`" style="font-size: 0.8rem; margin-left:5px;" />
                                    <span v-tooltip.top="{value : `${ slotProps.data.shipFrom_City ? slotProps.data.shipFrom_City + ',' : '' } ${ slotProps.data.shipFrom_State ? slotProps.data.shipFrom_State + ',' : '' } ${ slotProps.data.shipFrom_PostalCode ? slotProps.data.shipFrom_PostalCode + ',' : '' } ${ (slotProps.data.shipFrom_CountryName ?? 'GB').substring(0,2)}` }">{{ slotProps.data.shipFrom_City ? slotProps.data.shipFrom_City + ',' : '' }} {{ slotProps.data.shipFrom_State ? slotProps.data.shipFrom_State + ',' : '' }} {{ slotProps.data.shipFrom_PostalCode ? slotProps.data.shipFrom_PostalCode + ',' : '' }} {{ (slotProps.data.shipFrom_CountryName ?? 'GB').substring(0,2)}}</span>
                                </div>

                            </template>

                            <template #groupfooter="slotProps">
                                <td style="width: 60%" colspan="3"></td>
                                <td style="width: 40%">
                                    <table style="width:100%">
                                        <tr>
                                            <td>Total Consignments</td>
                                            <td>{{computeTotalConsignment(slotProps.data.groupCollection)}}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>

                                            <td>Total Packages</td>
                                            <td>0</td>
                                        </tr>
                                        <tr>
                                            <td></td>

                                            <td></td>

                                            <td>Total Weight</td>
                                            <td>0</td>
                                        </tr>
                                    </table>
                                    <div style="text-align: right; width: 100%">Total Consignments</div>
                                </td>
                            </template>
                        </cosmos-grid>
                    </div>
                </div>
            </div>
            <div v-else class="confirmation-content p-fluid p-grid">
                <div class="p-d-flex p-grid p-col">
                    <div class="p-col-12" style="text-align:center;">
                        <pv-progress-spinner style="padding:3rem;">PLEASE WAIT</pv-progress-spinner>
                    </div>
                    <div class="p-col-12" style="text-align:center;">
                        <span class="p-code-text" style="text-align:center"><b>...Processing collection... Please Wait....</b></span>
                    </div>

                </div>

            </div>
        </is-dialog>
    </keep-alive>
    <is-dialog header="POD" :visible="dialog.pod" @is-confirm="onConfirmPOD()" @is-cancel="this.dialog.pod = false">
        <div class="confirmation-content p-d-flex">
            <div>
                <i class="pi pi-info-circle p-mr-3" style="font-size: 2rem" />
            </div>
            <div>
                You are about to POD selected consignments
                <form-datetime id="podDate" :required="true" :value="podDate" type="date" @is-sync="podDate = $event.value" label="POD Date" style="margin-top:20px;" :v$="v$"></form-datetime>
                Are you sure you still want to proceed?
            </div>
        </div>
    </is-dialog>
    <is-dialog header="Clone Record?" :visible="dialog.clone" @is-confirm="confirmClone" @is-cancel="{this.dialog.clone = false; this.clone = { code: null, name: null};}" :confirmDisabled="(clone?.name?.length ?? 0) === 0">
        <div class="confirmation-content p-d-flex">
            <div class="p-col-12">
                <form-input id="name" @keyup="onClone_KeyupEvent" label="Reference" :required="true" :v$="v$" :value="clone.name" @is-sync="clone.name = $event.value" placeholder="Reference" v-focus :max-lenght="20" />
            </div>
        </div>
    </is-dialog>
    <print-form :formData="dialog?.print?.model" :isBatchPrint="dialog?.print?.isBatch" :foreignkeyid="foreignkeyid" :selectedItems="selectedItems ?? source.data ?? []" :showDialog="dialog.print.show" :documentType="dialog.print.type" @is-confirm="{dialog.print.show = false; dialog.print.model = null; dialog.print.type = ''; dialog.print.isBatch = false;   this.$nextTick(() => {
                        if (this.statusFilter === 200)
                        {
                            this.selectedItems = [];
                            this.selectedReset = true;
                            this.onRefresh();
                        }
                        });
                    }" @is-cancel="{dialog.print.show = false; dialog.print.model = null; dialog.print.type = '';}"></print-form>
</template>
<script>
    import { mapGetters } from 'vuex';
    import mixins from '@/assets/lib/cosmos/_js/mixin.js';
    import { FilterMatchMode } from 'primevue/api';
    import printform from '@/components/transaction/tms/consignment/record/print-form.vue';
    import packageform from '@/components/transaction/tms/consignment/record/package-formnew.vue';

    export default {
        name: 'ConsignmenttTemplate',
        mixins: [mixins.GRID],
        props: {
            foreignkeyid: { type: Number, default: null },
            collectionStatus: { type: Number, default: null },
            readOnly: { type: Boolean, default: false }
        },
        components: {
            'print-form': printform,
            'package-form': packageform
        },
        data() {
            return {
                type: -1,
                filters: {},
                columns: [],
                deleteDisplay: ["code", "name"],
                searchFields: ["code",
                    "customerReference",
                    "customerReference2",
                    "customerReference3",
                    "customerReference4",
                    "customerReference5",
                    "customerReference6",
                    "customerReference7",
                    "shipTo_FirstName",
                    "shipTo_LastName",
                    "shipTo_City",
                    "shipTo_PostalCode",
                    "shipTo_State",
                    "shipTo_CountryName",
                    "shipFrom_FirstName",
                    "shipFrom_LastName",
                    "shipFrom_City",
                    "shipFrom_PostalCode",
                    "shipFrom_State",
                    "shipFrom_CountryName",
                    "primaryCarrierReference",
                    "returnReference",
                    "customerName",
                    "customerCode",
                    ],
                forceRefresh: false,
                selectedReset: false,
                statusFilter: null,
                collectionDateFilter: null,
                selectedItems: [],
                source: { Object },
                dialog: {
                    book: false,
                    pod: false,
                    print: {
                        show: false,
                        type: '',
                        model: null,
                        isBatch: false
                    },
                    clone: false
                },
                podDate: new Date(),
                bookCollectionModel: {
                    collectionDate: new Date(),
                    collectionTime: new Date('2021-01-01 04:00 PM'),
                    readyTime: new Date('2021-01-01 03:00 PM'),
                    cutOffTime: new Date('2021-01-01 03:00 PM'),
                    closingTime: new Date('2021-01-01 06:00 PM'),
                    afterCloseTime: new Date('2021-01-01 08:00 PM'),
                    firstName: null,
                    lastName: null,
                    telephone: null,
                    mobile: null,
                    email: null,
                    company: null,
                    countryID: 238,
                    address1: null,
                    address2: null,
                    address3: null,
                    city: null,
                    state: null,
                    postalCode: null
                },
                filterSource: {
                    customerID: 0,
                    serviceProviderID: 0,
                    serviceProviderAccountID: 0,
                    serviceProviderAccountCode: null,
                    includePastCollectionDate: false,
                    collection: {
                        from: null,
                        to: null
                    },
                    accountDS: [],
                    customerAccountDS: []
                },
                clone: {
                    mask: null,
                    code: null,
                    name: null
                },
                keys: null,
                session: {
                    tenant: null,
                    environment: null,
                    warehouse: null,
                    customer: null,
                    currentUser: null,
                    executiveUser: null
                },
                searchKey: null,
                action: {
                    processing: false
                },
                activeFilters: {
                    global: { value: "", matchMode: FilterMatchMode.CONTAINS }
                },
                activity: {
                    parentLoading: true,
                    loading: false,
                    export: false,
                    binding: false,
                    dirty: false
                },
            }
        },
        watch: {
            $route(to, from) {
                if ((to?.params?.q ?? from?.params?.q)) {
                    this.activeFilters.global.value = to?.params?.q ?? from.params?.q ?? "";
                    this.statusFilter = null;
                    this.$nextTick(() => { this.onRefresh(); });
                }
            },
            'statusFilter': {
                handler() {
                    this.$nextTick(() => {
                        this.selectedItems = [];
                        this.selectedReset = true;
                        this.onRefresh();
                    });
                    
                }, deep: true
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            showToolBar() {
                return !this.$props.readOnly;
            },
            sessionKeys() { return this.$store.getters["auth/session"]; },
            scopeCustomerDS() {
                return this.$config.common.$customerFiltered(this.user?.environmentID ?? -100, this.$vm.$appSession.warehouse.id ?? this.user.warehouseID, this.user);
            },
            scopeWarehouseDS() {
                return this.$config.common.$warehouse(this.user?.environmentID ?? -100, this.user);
            },
            serviceProviderDS() {
                let ds = [];
                if (this.filterSource.customerID > 0) {
                    this.filterSource.customerAccountDS?.forEach((x) => {
                        let provider = this.$vm.$providers.filter(y => y.id === x.serviceProvider.id)[0];
                        if (ds.length === 0) {
                            ds.push({ id: provider.id, name: '[' + provider.code + '] ' + provider.name });
                        }
                        else if (ds?.findIndex(y => y.id === x.serviceProvider.id) === -1) {
                            ds.push({ id: provider.id, name: '[' + provider.code + '] ' + provider.name });
                        }
                    });
                }
                else {
                    this.serviceAccounts?.data?.forEach((x) => {
                        let provider = this.$vm.$providers.filter(y => y.id === x.serviceProviderID)[0];
                        if (ds.length === 0) {
                            ds.push({ id: provider.id, name: '[' + provider.code + '] ' + provider.name });
                        }
                        else if (ds?.findIndex(y => y.id === x.serviceProviderID) === -1) {
                            ds.push({ id: provider.id, name: '[' + provider.code + '] ' + provider.name });
                        }

                    });
                }
                return ds;
            },
            serviceProviderAccountDS() {
                let ds = [];
                if (this.filterSource.serviceProviderID > 0) {
                    if (this.filterSource.customerID > 0) {
                        if (this.filterSource.accountDS.length > 0) {
                            this.filterSource.customerAccountDS?.forEach((x) => {
                                if (x.serviceProvider.id === this.filterSource.serviceProviderID) {
                                    let account = this.serviceAccounts?.data?.filter(y => y.id === x.accountDetail.id)[0];
                                    var provider = this.$vm.$providers.filter(y => y.id === account.serviceProviderID)[0];
                                    if (ds.length === 0) {
                                        ds.push({ id: account.id, name: '[' + x.code + '] ' + x.name + ' (<i><span style="color: silver">' + account.accountNumber + '</span></i>)', code: x.code, providerCode: provider.code, accountCode: account.code });
                                    }
                                    else if (ds?.findIndex(y => y.id === x.accountDetail.id) === -1) {
                                        ds.push({ id: account.id, name: '[' + x.code + '] ' + x.name + ' (<i><span style="color: silver">' + account.accountNumber + '</span></i>)', code: x.code, providerCode: provider.code, accountCode: account.code });
                                    }
                                }

                            });
                        }

                    }
                    else {
                        this.filterSource.accountDS?.forEach((x) => {
                            var provider = this.$vm.$providers.filter(y => y.id === x.serviceProviderID)[0];
                            if (ds.length === 0) {
                                ds.push({ id: x.id, name: '[' + x.accountNumber + '] ' + x.name, code: x.code, providerCode: provider.code, accountCode: x.code });
                            }
                            else if (ds?.findIndex(y => y.id === x.id) === -1) {
                                ds.push({ id: x.id, name: '[' + x.accountNumber + '] ' + x.name, code: x.code, providerCode: provider.code, accountCode: x.code });
                            }
                        });
                    }

                }
                return ds;
            },
            serviceAccounts() { return this.$store.getters["provideraccount/ds"]; },
            providerProductDS() {
                return this.$store.getters["providerproduct/ds"].data ?? [];
            },
            onDataToProcess() {
                return this.selectedItems?.length > 0  ? this.selectedItems : this.source.data;
            },
            showBookCollection() {
                return this.statusFilter == 300;
            },
            isValidBook() {
                return this.filterSource.serviceProviderAccountID > 0 && this.collectionDateFilter && (this.source?.data?.length ?? 0 > 0);
            },
            showPOD() {
                return this.statusFilter == 500;
            },
            gridUrl() {
                let url = `${this.$config.config.endpoint.api}/services/tms/consignment`;
                if ((this.$props?.foreignkeyid <= 0)) {
                    url += '' + (this.statusFilter > 1 ? '?status=' + this.statusFilter : '');
                }
                if (this.showBookCollection) {
                    url += '' + ((this.collectionDateFilter) ? '&collectiondate=' + this.$filters.formatDateWithFormat(this.collectionDateFilter, 'yyyy-MM-DD') : '') + '';
                    url += '' + ((this.filterSource.customerID > 0) ? '&foreighkey2id=' + this.filterSource.customerID : '') + '';
                    url += '' + ((this.filterSource.serviceProviderID > 0) ? '&foreighkey3id=' + this.filterSource.serviceProviderID : '') + '';
                    url += '' + ((this.filterSource.serviceProviderAccountID > 0) ? '&foreighkey4id=' + this.filterSource.serviceProviderAccountID : '') + '';
                }

                url += '' + ((this.$props?.foreignkeyid > 0) ? (url.indexOf('?') !== -1 ? '&' : '?') + 'foreignkeyid=' + this.$props?.foreignkeyid : '') + '';
                if (this.$props?.collectionStatus > 0)
                    url += '' + ((this.$props?.collectionStatus > 0) ? (url.indexOf('?') !== -1 ? '&' : '?') + (url.indexOf('status') === -1 ? 'status=' + this.$props?.collectionStatus : '') : '') + '';

                return url; 
            },
            gridExportUrl() {
                let url = `${this.$config.config.endpoint.api}/services/tms/consignment/export`;
                if ((this.$props?.foreignkeyid <= 0)) {
                    url += '' + (this.statusFilter > 1 ? '?status=' + this.statusFilter : '');
                }
                if (this.showBookCollection) {
                    url += '' + ((this.collectionDateFilter) ? '&collectiondate=' + this.$filters.formatDateWithFormat(this.collectionDateFilter, 'yyyy-MM-DD') : '') + '';
                    url += '' + ((this.filterSource.customerID > 0) ? '&foreighkey2id=' + this.filterSource.customerID : '') + '';
                    url += '' + ((this.filterSource.serviceProviderID > 0) ? '&foreighkey3id=' + this.filterSource.serviceProviderID : '') + '';
                    url += '' + ((this.filterSource.serviceProviderAccountID > 0) ? '&foreighkey4id=' + this.filterSource.serviceProviderAccountID : '') + '';
                }
                url += '' + ((this.$props?.foreignkeyid > 0) ? (url.indexOf('?') !== -1 ? '&' : '?') + 'foreignkeyid=' + this.$props?.foreignkeyid : '') + '';
                return url;
            },
            //activeFilters() {
            //    var filter = {};
            //    if (this.filters)
            //        filter = this.filters;
            //    filter["global"] = { value: "", matchMode: FilterMatchMode.CONTAINS };
            //    return filter;
            //},
            contact() {
                return {
                    firstName: this.bookCollectionModel.firstName,
                    lastName: this.bookCollectionModel.lastName,
                    email: this.bookCollectionModel.email,
                    mobile: this.bookCollectionModel.mobile,
                    telephone: this.bookCollectionModel.telephone
                };
            },
            address() {
                return {
                    address1: this.bookCollectionModel.address1,
                    address2: this.bookCollectionModel.address2,
                    address3: this.bookCollectionModel.address3,
                    city: this.bookCollectionModel.city,
                    state: this.bookCollectionModel.state,
                    postalCode: this.bookCollectionModel.postalCode,
                    country: null,
                    countryID: this.bookCollectionModel.countryID,
                    companyName: this.bookCollectionModel.company,
                    establishmentType: 100
                };
            },
            apiCollectRequests() {
                let request = [];
                let _self = this;
                let groupedResult = this.groupRecordBy(_self.onDataToProcess, (c) => c.groupCollection);
                for (let item in groupedResult) {
                    let transactionIDs = [];
                    if (_self.selectedItems.length > 0 || _self.onDataToProcess.filter(x => !x.request_CollectionDate.toString().startsWith(_self.$filters.formatDateWithFormat(this.bookCollectionModel.collectionDate, 'yyyy-MM-DD')))) {
                        _self.onDataToProcess.filter(x => x.groupCollection === item).forEach((x) => {
                            transactionIDs.push(x.code);
                        });
                    }
                    request.push({
                        Request: {
                            CollectionSummary: {
                                CollectionSchedule: {
                                    CollectionDate: _self.$filters.formatDateWithFormat(this.bookCollectionModel.collectionDate, 'yyyy-MM-DD'),
                                    ReadyTime: _self.$filters.formatDateWithFormat(this.bookCollectionModel.readyTime, 'HH:mm'),
                                    CollectionTime: _self.$filters.formatDateWithFormat(this.bookCollectionModel.collectionTime, 'HH:mm'),
                                    ClosingTime: _self.$filters.formatDateWithFormat(this.bookCollectionModel.closingTime, 'HH:mm'),
                                    CutOffTime: _self.$filters.formatDateWithFormat(this.bookCollectionModel.cutOffTime, 'HH:mm'),
                                    AfterCloseTime: _self.$filters.formatDateWithFormat(this.bookCollectionModel.afterCloseTime, 'HH:mm')
                                },
                                AfterHoursDetails: {
                                    Contact: {
                                        FirstName: _self.bookCollectionModel.firstName,
                                        LastName: _self.bookCollectionModel.lastName,
                                        Email: _self.bookCollectionModel.email,
                                        Telephone: _self.bookCollectionModel.telephone,
                                        Mobile: _self.bookCollectionModel.mobile,
                                    },
                                    Address: {
                                        Address1: _self.bookCollectionModel.address1,
                                        Address2: _self.bookCollectionModel.address2,
                                        Address3: _self.bookCollectionModel.address3,
                                        City: _self.bookCollectionModel.city,
                                        State: _self.bookCollectionModel.state,
                                        PostalCode: _self.bookCollectionModel.postalCode,
                                        Country: _self.$vm.$countries.filter(x => x.id === _self.bookCollectionModel.countryID)[0]?.code,
                                        CompanyName: _self.bookCollectionModel.company,
                                    }
                                },
                                ConsignmentSummary: {
                                    ProductCode: "string",
                                    PackageCount: 0,
                                    Total: {
                                        GrossWeight: 0,
                                        NetWeight: 0,
                                        ChargeableWeight: 0
                                    },
                                    WeightUnit: "string"
                                },
                                TransactionIDs: transactionIDs
                            }
                        }
                    });
                }
                return request;
            },
            apiCollect() {
                let request = [];
                let _self = this;
                let groupedResult = this.groupRecordBy(_self.onDataToProcess, (c) => c.groupCollection);
                for (let item in groupedResult) {
                    let transactionIDs = [];
                    let pickInfo = null;
                    if (_self.selectedItems.length > 0 || _self.onDataToProcess.filter(x => !x.request_CollectionDate.toString().startsWith(_self.$filters.formatDateWithFormat(this.bookCollectionModel.collectionDate, 'yyyy-MM-DD')))) {
                        _self.onDataToProcess.filter(x => x.groupCollection === item).forEach((x) => {
                            if (pickInfo == null) {                                
                                pickInfo = {
                                    Contact: {
                                        FirstName: x.shipFrom_FirstName,
                                        LastName: x.shipFrom_LastName,
                                        Email: x.shipFrom_Email,
                                        Telephone: x.shipFrom_Telephone,
                                        Mobile: x.shipFrom_Mobile
                                    },
                                    Address: {
                                        Company: x.shipFrom_Company,
                                        Address1: x.shipFrom_Address1,
                                        Address2: x.shipFrom_Address2,
                                        Address3: x.shipFrom_Address3,
                                        City: x.shipFrom_City,
                                        State: x.shipFrom_State,
                                        PostalCode: x.shipFrom_PostalCode,
                                        Country: _self.$vm.$countries.filter(c => c.id === x.shipFrom_CountryID)[0]?.code,
                                    },
                                    PickupPoint: null
                                };
                            }
                            transactionIDs.push(x.code);
                        });
                    }
                    request.push({
                        Source: "Web",
                        Request: {
                            Warehouse: this.scopeWarehouseDS.filter(x => x.id === this.$vm.$appSession?.warehouse.id)[0]?.code,
                            Customer: this.filterSource.customerID > 0 ? this.scopeCustomerDS.filter(x => x.id === this.filterSource.customerID)[0]?.code : null,
                            Provider: this.serviceProviderAccountDS.filter(x => x.id === this.filterSource.serviceProviderAccountID)[0]?.providerCode,
                            Account: this.serviceProviderAccountDS.filter(x => x.id === this.filterSource.serviceProviderAccountID)[0]?.accountCode,
                            BookingInfo: {
                                ScheduleInfo: {
                                    CollectionDate: _self.$filters.formatDateWithFormat(this.bookCollectionModel.collectionDate, 'yyyy-MM-DD'),
                                    CutOffTime: _self.$filters.formatDateWithFormat(this.bookCollectionModel.cutOffTime, 'HH:mm'),
                                    ReadyTime: _self.$filters.formatDateWithFormat(this.bookCollectionModel.readyTime, 'HH:mm'),
                                    CollectionTime: _self.$filters.formatDateWithFormat(this.bookCollectionModel.collectionTime, 'HH:mm'),
                                    ClosingTime: _self.$filters.formatDateWithFormat(this.bookCollectionModel.closingTime, 'HH:mm'),
                                    AfterHourClosingTime: _self.$filters.formatDateWithFormat(this.bookCollectionModel.afterCloseTime, 'HH:mm'),
                                },
                                PickupInfo: pickInfo,
                                AfterHourInfo: (groupedResult?.length ?? 0) <= 1 ? {
                                    Contact: {
                                        FirstName: _self.bookCollectionModel.firstName,
                                        LastName: _self.bookCollectionModel.lastName,
                                        Email: _self.bookCollectionModel.email,
                                        Telephone: _self.bookCollectionModel.telephone,
                                        Mobile: _self.bookCollectionModel.mobile,
                                    },
                                    Address: {
                                        Company: _self.bookCollectionModel?.company,
                                        Address1: _self.bookCollectionModel?.address1,
                                        Address2: _self.bookCollectionModel?.address2,
                                        Address3: _self.bookCollectionModel?.address3,
                                        City: _self.bookCollectionModel?.city,
                                        State: _self.bookCollectionModel?.state,
                                        PostalCode: _self.bookCollectionModel?.postalCode,
                                        Country: _self.$vm.$countries.filter(x => x.id === _self.bookCollectionModel.countryID)[0]?.code,
                                    }
                                } : pickInfo
                            },
                            TransactionIDs: transactionIDs
                        }
                    });
                }
                return request;
            }

            
        },
        methods: {
            onExportToExcel() {
                this.$nextTick(() => { this.activity.export = true; });
                this.$axios.get(this.gridExportUrl + ``.replaceAll("//", "/"), { headers: { filter: this.activeFilters?.global?.value, fields: JSON.stringify(this.searchFields ?? []) } })
                    .then((response) => {
                        this.$config.common.$downloadFile(response.data.result.base64File, response.data.result.contentType, response.data.result.fileName);
                        this.$nextTick(() => { this.activity.export = false; });
                    })
                    .catch(ex => {
                        this.$toast.add({ severity: 'error', summary: 'Export Failed', detail: 'UNHANDLED ERROR: ' + ex, life: 3000 });
                        this.$nextTick(() => { this.activity.export = false; });
                    });
            },
            onCollectionSync(event) {
                this.collectionDateFilter = event.value;
                this.forceRefresh = true;
            },
            async cloneRecord(mask, model) { return await this.$store.dispatch("consignment/cloneRecord", { mask: mask, record: model }); },
            onClone_KeyupEvent(e) {
                if (e.keyCode === 13 && (this.clone?.name?.length ?? 0) > 0) {
                    this.confirmClone();
                }
                if (e.keyCode === 27) {
                    { this.dialog.clone = false; this.clone = { code: null, name: null }; }
                }
            },
            confirmClone() {
                this.cloneRecord(this.clone.mask, this.clone).then((response) => {
                    if (response.errors) {
                        this.$toast.add({ severity: 'error', summary: 'Record Clone Failed!', detail: response.errors[0].message, life: 3000 });
                        this.dialog.clone = false;
                        this.clone = {
                            mask: null,
                            code: null,
                            name: null
                        };
                    }
                    else {
                        if (response?.data?.result ?? response.result) {
                            this.$router.push({ name:  'consignment-edit', params: { mask: (response?.data?.result ?? response.result ?? this.record)?.mask } });
                            this.$toast.add({ severity: 'success', summary: 'Record Clone Success!', detail: 'The record was cloned successfully. <br/> For International consignments please make sure to change details for the following: <br/> <ul><li>Invoice Details</li><li>Customs Details</li></ul>', life: 20000 });
                        }
                        else {
                            this.$toast.add({ severity: 'error', summary: 'Record Clone Failed!', detail: 'Not Allowed', life: 3000 });
                        }
                        this.dialog.clone = false;
                        this.clone = {
                            mask: null,
                            code: null,
                            name: null
                        };
                    }
                }).catch(() => {
                    this.$toast.add({ severity: 'error', summary: 'Record Clone Failed!', detail: 'Record Clone Failed', life: 3000 });
                });
                this.dialog.clone = false
            },
            async getProviderProducts() {
                return await this.$store.dispatch("providerproduct/getAll", { sort: null, where: null });
            },
            onCreate() { this.$router.push({ name: `${this.router.name}-add` }); },
            onForceFilter(e) {
                if (e.keyCode === 13 || this.activeFilters["global"].value === '') {
                    this.onRefresh();
                }
            },
            onRefresh() {
                this.forceRefresh = false; 
                this.$nextTick(() => { this.forceRefresh = true; });
            },
            getStatus(e) {
                let status = "new";
                switch (e) {
                    case 100:
                        status = "new";
                        break;
                    case 200:
                        status = "proposal";
                        break;
                    case 300:
                        status = "renewal";
                        break;
                    case 400:
                        status = "qualified";
                        break;
                    case 500:
                        status = "new";
                        break;
                    case 600:
                        status = "qualified";
                        break;
                    case 700:
                        status = "qualified";
                        break;
                    case 800:
                        status = "unqualified";
                        break;
                    default:
                        status = "new";
                        break;
                }
                return status.toLowerCase();
            },
            getTradeType(e) {
                switch (e) {
                    case 2:
                        return "i";
                    case 4:
                        return "c";
                    case 8:
                        return "p";
                    case 16:
                        return "r";
                    default:
                        return "e";
                }
            },
            onSource(e) {
                this.source = e;
            },
            onSelected(e) {
                this.selectedReset = false;
                this.selectedItems = e;
            },
            onClickBookCollection() {
                this.dialog.book = true;
                let data = this.onDataToProcess[0];
                if (data === null || data === undefined)
                    data = this.source.data[0];
                if (data != null) {
                    this.bookCollectionModel.firstName = data.shipFrom_FirstName;
                    this.bookCollectionModel.lastName = data.shipFrom_LastName;
                    this.bookCollectionModel.telephone = data.shipFrom_Telephone;
                    this.bookCollectionModel.mobile = data.shipFrom_Mobile;
                    this.bookCollectionModel.email = data.shipFrom_Email;
                    this.bookCollectionModel.company = data.shipFrom_Company;
                    this.bookCollectionModel.countryID = data.shipFrom_CountryID;
                    this.bookCollectionModel.address1 = data.shipFrom_Address1;
                    this.bookCollectionModel.address2 = data.shipFrom_Address2;
                    this.bookCollectionModel.address3 = data.shipFrom_Address3;
                    this.bookCollectionModel.city = data.shipFrom_City;
                    this.bookCollectionModel.state = data.shipFrom_State;
                    this.bookCollectionModel.postalCode = data.shipFrom_PostalCode;
                }
            },
            onBookTranslateContact(e) {
                this.bookCollectionModel.firstName = e.firstName;
                this.bookCollectionModel.lastName = e.lastName;
                this.bookCollectionModel.email = e.email;
                this.bookCollectionModel.mobile = e.mobile;
                this.bookCollectionModel.telephone = e.telephone;
                this.contact = {
                    firstName: this.bookCollectionModel.firstName,
                    lastName: this.bookCollectionModel.lastName,
                    email: this.bookCollectionModel.email,
                    mobile: this.bookCollectionModel.mobile,
                    telephone: this.bookCollectionModel.telephone
                };
            },
            onBookTranslateAddress(e) {
                this.bookCollectionModel.address1 = e.address1;
                this.bookCollectionModel.address2 = e.address2;
                this.bookCollectionModel.address3 = e.address3;
                this.bookCollectionModel.city = e.city;
                this.bookCollectionModel.state = e.state;
                this.bookCollectionModel.postalCode = e.postalCode;
                this.bookCollectionModel.countryID = e.countryID;
                this.bookCollectionModel.company = e.companyName;
                this.bookCollectionModel.establishmentType = e.establishmentType;
                this.address = {
                    address1: this.bookCollectionModel.address1,
                    address2: this.bookCollectionModel.address2,
                    address3: this.bookCollectionModel.address3,
                    city: this.bookCollectionModel.city,
                    state: this.bookCollectionModel.state,
                    postalCode: this.bookCollectionModel.postalCode,
                    countryID: this.bookCollectionModel.countryID,
                    companyName: this.bookCollectionModel.company,
                    establishmentType: this.bookCollectionModel.establishmentType,
                };
            },
            async onBookCollection() {
                let failed = false;
                this.action.processing = true;
                for (const item of this.apiCollect) {
                    await this.commitCollection(item).then((response) => {
                        if (response.data.Status === "SUCCESS") {
                            this.onRefresh();
                        }
                        else {
                            failed = true;
                            if (response.data.Errors !== null && response.data.Errors !== undefined)
                                this.$toast.add({ severity: 'error', summary: 'Collection Booked Failed', detail: response.data.Errors[0].Message, life: 6000 });
                            if (response.data.Faults !== null && response.data.Faults !== undefined)
                                this.$toast.add({ severity: 'error', summary: 'Collection Booked Failed', detail: response.data.Faults[0].Message, life: 6000 });
                        }

                    }).catch((ex) => {
                        if (ex?.data?.Status === "FAILED") {
                            failed = true;
                            this.$toast.add({ severity: 'error', summary: 'Collection Booked Failed', detail: ex.data.Errors[0].Description, life: 6000 });
                        }
                        else {
                            failed = true;
                            this.$toast.add({ severity: 'error', summary: 'Collection Booked Failed', detail: "The book collection failed", life: 6000 });
                        }

                    });
                }
                this.action.processing = false;
                if (!failed) {
                
                    this.dialog.book = false;
                    this.$toast.add({ severity: 'success', summary: 'Collection Booked Successfully', detail: "The collection successfully booked", life: 6000 });
                    this.$nextTick(() => {
                        this.selectedItems = [];
                        this.selectedReset = true;
                        this.onRefresh();
                    });
                }
                
            },
            async commitCollection(request) {
                return await this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/collection`.replaceAll("//", "/"), request);
            },
            async bookProcessCollection(request) {
                return await this.$axios.post(this.$config.config.endpoint.polaris + `/collection`.replaceAll("//", "/"), request);
            },
            async onCommitPOD(mask, podDate) {
                return await this.$store.dispatch("consignment/getURL", { url: '/services/tms/consignment/' + mask + '/pod/' + podDate });
            },
            onClickPOD() {
                if (this.selectedItems.length === 0 || (this.selectedItems?.filter(x => x.status === 500).length ?? 0) === 0) {
                    this.$toast.add({ severity: 'error', summary: 'Please Select Record', detail: "Record selection is required", life: 6000 });
                }
                else {
                    this.dialog.pod = true;
                }
            },
            async onConfirmPOD() {
                let _self = this;
                for (const item of this.selectedItems?.filter(x => x.status === 500)) {
                    await this.onCommitPOD(item.mask, _self.$filters.formatDateWithFormat(_self.podDate, 'yyyyMMDD')).then(() => {
                        _self.onRefresh();
                        this.selectedItems = [];
                        this.selectedReset = true;
                    })
                }
                this.dialog.pod = false;
                this.$toast.add({ severity: 'success', summary: 'Consignment POD Successfully', detail: "The Consignment successfully POD", life: 6000 });
                this.onRefresh();
            },
            computeTotalConsignment(groupCollection) {
                let total = 0;
                if (this.selectedItems.length > 0) {
                    total = this.selectedItems.filter(x => x.groupCollection === groupCollection).length;
                    //for (let item of )
                    //    total++;
                }
                else {

                    total = this.source.data.filter(x =>
                        x.groupCollection === groupCollection
                        && x.status === this.statusFilter
                        //   // && x.warehouseId === this.activeFilters["warehouseId"].constraints[0].value
                        //&& x.request_CollectionDate.toString().startsWith(this.activeFilters["request_CollectionDate"].constraints[0].value)
                        && x.request_CollectionDate.toString().startsWith(this.$filters.formatDateWithFormat(this.collectionDateFilter, 'yyyy-MM-DD'))

                    ).length;
                }


                return total;
            },
            groupRecordBy(xs, f) {
                return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
            }
            //modules(slotProps) {
            //    return this.source.filter(x => this.$filters.getFlags(slotProps.data.modules).indexOf(x.id) !== -1).map(x => '<div class="p-chip" style="padding: 2px 10px; border-radius: 4px;">' + x.text + '</div>').join(', ');
            //}
            ,onPrintLabel(e, type) {
                this.dialog.print.model = e;
                this.dialog.print.show = true;
                this.dialog.print.type = type ?? 'label';
            },
            onBatchPrint(type) {
                this.dialog.print.model = {
                    code: null,
                    status: this.statusFilter,
                    tenantID: null
                };
                this.dialog.print.show = true;
                this.dialog.print.isBatch = true;
                this.dialog.print.type = type ?? 'label';
            },
            onProvider(e) {
                this.filterSource.serviceProviderID = e?.value;
                this.$nextTick(() => {
                    this.getAccounts();
                });
            },
            onAccount(e) {
                this.filterSource.serviceProviderAccountID = e?.value;
                this.$nextTick(() => {
                    if ((this.selectedItems?.length ?? 0) > 0) {
                        this.selectedItems = [];
                        this.selectedReset = true;
                        this.onRefresh();
                    }
                });

            },
            onCustomer(e) {
                this.filterSource.customerID = e?.value;
                this.$nextTick(() => {
                    this.getCustomerAccounts();
                });
                
            },
            async getProviderAccounts() {
                await this.$store.dispatch("provideraccount/getAll", { sort: null, where: null }).then(() => {
                });
            },
            async getAccounts() {
                if (this.filterSource.serviceProviderID > 0) {
                    await this.$store.dispatch("provideraccount/getURL", { url: `/services/admin/serviceprovideraccount/byprovider/id/${this.filterSource.serviceProviderID}` }).then((response) => {
                        this.filterSource.accountDS = response.result;
                    });
                }
                else {
                    this.accountDS = [];
                }
            },
            async getCustomerAccounts() {
                if (this.filterSource.customerID > 0) {
                    await this.$store.dispatch("provideraccountcustomer/getURL", { url: `/services/admin/serviceprovideraccountcustomer/handlerbyaccount/id/${this.filterSource.customerID}` }).then((response) => {
                        this.filterSource.customerAccountDS = response.result;
                    });
                }
                else {
                    this.filterSource.customerAccountDS = [];
                }
            }
        },
        async created() {
            this.statusFilter = Number(this.$router?.currentRoute?._value?.params?.status ?? this.statusFilter ?? 1);
            if ((this.providerProductDS.length ?? 0) === 0)
                await this.getProviderProducts()
            await this.getProviderAccounts();
            if (this.$router?.currentRoute?._value?.params?.q) {
                this.activeFilters.global.value = this.$router?.currentRoute?._value?.params?.q;
                this.statusFilter = null;
                this.$nextTick(() => { this.onRefresh(); });
            }
        },
        async mounted() {
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            this.statusFilter = Number(this.$router?.currentRoute?._value?.params?.status ?? this.statusFilter ?? 1);
            this.activeFilters.global.value = this.$router?.currentRoute?._value?.params?.q ?? "";

            //await this.getProviderProducts();
            //await this.getProviderAccounts();
        }
        ,unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>