var model = {
    model: null,
    modelState: null
};

var browser = {
    language() { return navigator.language; },
    touchDevice() { return navigator.maxTouchPoints > 0; },
    isMobile() { return /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(navigator.userAgent.substr(0, 4)) },
};

var data = {
    activity: {
        loading: {
            form: true,
            save: false,
            delete: false,
            print: false,
            send: false,
            clone: false,
            faq: false,
            support: false
        }
    },
    display: { width: 1000, height: 1000 },
    router: {
        name: null,
        module: null,
        model: null,
        schema: null,
        title: null,
        subtitle: null,
        type: null
    },
    helper: {
        guided: true
    },
    isModelDirty: false
};

var computed = {
    ...browser,
    sessionKeys() { return this.$store.getters["auth/session"]; },
    init() { return this.$filters.init(this.$store.getters[this.router.module + "/init"]) },
    record() { return this.$filters.init(this.$store.getters[this.router.module + "/record"]) },
    dataSource() { return this.$store.getters[this.router.module + "/ds"]; },
    errors() { return this.$store.getters[this.router.module + "/errors"]; },
    isDirty() { return this.$props.modelState ? this.$props.modelState && (this.$props.modelState.$isDirty || this.$props.modelState.$anyDirty) : this.modelState && (this.modelState.$isDirty || this.modelState.$anyDirty); },
};

var crud = {
    async get(mask) { return await this.$store.dispatch(this.router.module + "/getRecord", mask); },
    async insert(model) { return await this.$store.dispatch(this.router.module + "/addRecord", model); },
    async update(model) { return await this.$store.dispatch(this.router.module + "/modifyRecord", model); },
    async delete(mask) { return await this.$store.dispatch(this.router.module + "/removeRecord", mask); },
    async send(mask) { return await this.$store.dispatch(this.router.module + "/sendRecord", mask); }

};

var method = {
    onWindowResize() {
        this.display.width = document.documentElement.clientWidth;
        this.display.height = document.documentElement.clientHeight;
    },
    DEVELOPMENT() { this.$toast.add({ severity: 'warn', summary: 'Feature Unavailable', detail: 'The feature is currently under development.', life: 3000 }); },
    getRouter() {
        const router = this.$route;
        const meta = this.$router.currentRoute.value.meta;
        this.router.name = router.name.replace("-index", "").replace("-add", "").replace("-edit", "");
        this.router.module = meta.module ?? this.router.name;
        this.router.type = meta.type ?? "";
        this.router.model = meta.model ?? "";
        this.router.schema = meta.schema ?? "";
        this.router.title = meta.title ?? "";
        this.router.subtitle = meta.subtitle ?? "";
    },
    resetModelState() { this.$nextTick(() => { this.modelState.$reset(); this.isModelDirty = false; }); },
    resetRecord() {
        if (!this.$route.params.mask) {
            this.model = this.$filters.init(this.$store.getters[this.router.module + "/init"]);
        }
        else
            this.get(this.record.mask).then(() => {
                this.model = this.record;
            });
        this.resetModelState();
    },
    sendRecord() {
        let _self = this;
        _self.send(_self.record.mask).then(() => {
            this.$toast.add({ severity: 'success', summary: 'Record Sent', detail: 'Record Sent Successful', life: 3000 });
            //_self.get(_self.record.mask).then(() => {
            //    //_self.model = _self.record
            //    this.$toast.add({ severity: 'success', summary: 'Record Sent', detail: 'Record Sent Successful', life: 3000 });
            //});
        });
        this.resetModelState();
    }
};

export default {
    TOOLBAR: {
        props: {
            identity: { type: String, default: null },
            back: { type: String, default: 'dashboard' },
            disabled: { type: Boolean, default: false },
            isDirty: { type: Boolean, default: false },
            deleteClone: { type: Boolean, default: true }
        },
        emits: ["is-reset"],
        data() {
            return {
                ... data,
                dialog: {
                    dirty: false,
                    refresh: false
                }
            }
        },
        computed: {
            ...computed,
            backLabel() { return (this.display.width > 450) ? "Back" : null; },
            saveLabel() { return (this.display.width > 450) ? (this.model && this.model.mask ? "Update" : "Save") : null; },
            resetLabel() { return (this.display.width > 450) ? "Reset" : null; },
            printLabel() { return (this.display.width > 450) ? "Print" : null; },
            cloneLabel() { return (this.display.width > 450) ? "Clone" : null; },
            deleteLabel() { return (this.display.width > 450) ? "Delete" : null; },
            sendLabel() { return (this.display.width > 450) ? "Send" : null; },
            faqLabel() { return (this.display.width > 450) ? "FAQ" : null; },
            faqMenu() { return [{ label: 'Support', icon: 'pi pi-fw pi-ticket', command: () => { this.SUPPORT_onClick(); } }] }
        },
        methods: {
            ...method,
            ...crud,
            BACK_onClick() {
                this.activity.loading.back = true;
                if (this.isDirty)
                    this.dialog.dirty = true;
                else
                    this.BACK_onConfirm();
                setTimeout(() => this.activity.loading.back = false, 200, this);
            },
            BACK_onConfirm() {
                return this.$router.push({ name: this.router.name + '-index' });
            },
            SAVE_onClick() {
                this.activity.loading.save = this.activity.submitted = this.$vm.submitted = true;
                setTimeout(() => this.activity.loading.save = false, 200, this);
            },
            RESET_onClick() {
                this.activity.loading.refresh = true;
                if (this.isDirty)
                    this.dialog.refresh = true;
                else
                    this.$emit("is-reset");
                setTimeout(() => this.activity.loading.refresh = false, 200, this);
            },
            CLONE_onClick() {
                this.activity.loading.clone = true;
                this.DEVELOPMENT();
                setTimeout(() => this.activity.loading.clone = false, 200, this);
            },
            DELETE_onClick() {
                this.activity.loading.delete = this.dialog.delete = true;
                setTimeout(() => this.activity.loading.delete = false, 200, this);
            },
            DELETE_onConfirm() {
                this.activity.loading.delete = true;
                this.delete(this.model.mask).then(() => {
                    if (!this.errors) {
                        this.$toast.add({ severity: 'success', summary: 'Record Deleted Sucessfully', detail: 'The record was successfully deleted', life: 3000 });
                        this.$router.push({ name: this.router.name + '-index' });
                    }
                    else
                        this.$toast.add({ severity: 'danger', summary: 'Record Delete Failed', detail: this.errors[0].message, life: 3000 });
                }).catch((error) => { this.$toast.add({ severity: 'danger', summary: 'Record Delete Failed', detail: error, life: 3000 }); });
                this.dialog.delete = false;
            },
            SEND_onClick() {
                console.log('dumaan');
            },
            FAQ_onClick() {
                this.activity.loading.faq = true;
                this.DEVELOPMENT();
                setTimeout(() => this.activity.loading.faq = false, 200, this);
            },
            SUPPORT_onClick() {
                this.activity.loading.support = true;
                this.DEVELOPMENT();
                setTimeout(() => this.activity.loading.support = false, 200, this);
            },
            DIALOG_onCancel() { this.dialog.refresh = this.dialog.dirty = false; },
            DIALOG_onConfirm(isRefresh) {
                if (isRefresh) {
                    this.$emit("is-reset");
                    this.DIALOG_onCancel();
                }
                else
                    this.BACK_onConfirm();
            }
        },
    },
    FORM: {
        emits: ["is-reset","is-send","is-guided","is-postsave"],
        props: {
            ...model,
            validators: { type: Object, default: {} },
            title: { type: String, default: null },
            subTitle: { type: String, default: null },
            viewOnly: { type: Boolean, default: false },
            showGuideHelper: { type: Boolean, default: false },
            saveOnly: { type: Boolean, default: false },
            viewBack: { type: Boolean, default: true },
            onBeforeSubmit: { type: Function },
            showCloneButton: { type: Boolean, default: false },
            showSendButton: { type: Boolean, default: false }
        },
        data() { return { ...data }; },
        computed: { ...computed },
        methods: {
            ...method,
            ...crud,
            onSubmit(form) {
                if (this.onBeforeSubmit) {
                    this.$vm.$submitted = this.activity.submitted = true;
                    this.onBeforeSubmit()
                        .then((response) => {
                            if (response)
                                this.executeSubmit(form);

                        })
                        .catch((ex) => {
                            console.log(ex)
                        });
                }
                else
                    this.executeSubmit(form);
            },
            executeSubmit(form) {
                form.preventDefault();
                this.modelState.$touch();
                this.$vm.$submitted = this.activity.submitted = true;
                let genericErrorMessage = "";
                let _this = this;
                if (!this.modelState.$invalid) {
                    if (!this.$route.params.mask) {
                        this.insert(this.model).then((response) => {
                            if (this.errors) {
                                if (!(Array.isArray(this.errors))) {
                                    let formKeys = Object.keys(this.errors);
                                    let _errors = this.errors;
                                    formKeys.forEach(function (value) {
                                        if (document.querySelector("#area" + value + " > small") !== undefined && document.querySelector("#area" + value + " > small") !== null)
                                            document.querySelector("#area" + value + " > small").innerHTML = _errors[value].join();
                                        else
                                            genericErrorMessage += "<b>" + _this.$filters.titleize(value) + ":</b> " + _errors[value].join() + "<br />";
                                    }
                                    );
                                }
                                this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: this.errors[0]?.message ?? genericErrorMessage });
                            }
                            else {
                                this.$router.push({ name: this.router.name + '-edit', params: { mask: (response?.result ?? this.record)?.mask } });
                                this.$toast.add({ severity: 'success', summary: 'Record Insert Success!', detail: 'The record was added successfully.', life: 3000 });
                                this.resetModelState();
                                setTimeout(() => this.$emit("is-reset"), 500);
                                if (this.router.name === 'contract' || this.router.name === 'environment' || this.router.name === 'warehouse')
                                    sessionStorage.setItem('companyupdate', Math.random());
                                //this.$nextTick(() => { this.$emit("is-reset"); });
                            }
                        }).catch((err) => {
                            this.$toast.add({ severity: 'error', summary: 'Record Insert Failed!', detail: err.xhr, life: 3000 });
                        });
                    }
                    else {
                        this.update(this.model).then(() => {
                            if (this.errors) {
                                if (!(Array.isArray(this.errors)))
                                {
                                    if (this.errors.value.errors) {
                                        if (!(Array.isArray(this.errors.value.errors))) {
                                            let formKeys = Object.keys(this.errors.value.errors);
                                            let _errors = this.errors.value.errors;
                                            formKeys.forEach(function (value) {
                                                if (document.querySelector("#area" + value + " > small") !== undefined && document.querySelector("#area" + value + " > small") !== null)
                                                    document.querySelector("#area" + value + " > small").innerHTML = _errors[value].join();
                                                else {
                                                    if (_errors[value].join() !== undefined && _errors[value].join() !== null && _errors[value].join() !== "")
                                                        genericErrorMessage += "<b>" + _this.$filters.titleize(value) + ":</b> " + _errors[value].join() + "<br />";
                                                }
                                            }
                                            );
                                        }
                                        else {
                                            this.$toast.add({ severity: 'error', summary: 'Record Update Failed!', detail: this.errors.value.errors[0]?.message ?? genericErrorMessage, life: 30000 });
                                        }
                                    }
                                    else {
                                        let formKeys = Object.keys(this.errors);
                                        let _errors = this.errors;
                                        formKeys.forEach(function (value) {
                                            if (document.querySelector("#area" + value + " > small") !== undefined && document.querySelector("#area" + value + " > small") !== null)
                                                document.querySelector("#area" + value + " > small").innerHTML = _errors[value].join();
                                            else {
                                                if (_errors[value].join() !== undefined && _errors[value].join() !== null && _errors[value].join() !== "")
                                                    genericErrorMessage += "<b>" + _this.$filters.titleize(value) + ":</b> " + _errors[value].join() + "<br />";
                                            }
                                        }
                                        );
                                    }
                                }
                                this.$toast.add({ severity: 'error', summary: 'Record Update Failed!', detail: this.errors[0]?.message ?? genericErrorMessage, life: 30000 });
                            }
                            else {
                                this.$toast.add({ severity: 'success', summary: 'Record Update Success!', detail: 'The record was updated successfully.', life: 3000 });
                                this.resetModelState();
                                this.$emit("is-refresh");
                                if (this.router.name === 'contract' || this.router.name === 'environment' || this.router.name === 'warehouse')
                                    sessionStorage.setItem('companyupdate', Math.random());

                            }
                        }).catch((err) => {
                            console.log(err);
                            this.$toast.add({ severity: 'error', summary: 'Record Update Failed!', detail: err.xhr, life: 3000 });
                        });
                    }
                }
            },
            onResetForm() {
                this.$emit("is-reset");
            },
            onSendForm() { this.$emit("is-send"); },
            onGuidedForm(e) {
                this.helper.guided = e;
            },
            onClone(e) {
                this.$router.push({ name: this.router.name + '-edit', params: { mask: (e ?? this.record)?.mask, cloned: true } });
                if (this.router.name === 'consignment') {
                    this.$toast.add({ severity: 'success', summary: 'Record Clone Success!', detail: 'The record was cloned successfully. <br/> For International consignments please make sure to change details for the following: <br/> <ul><li>Invoice Details</li><li>Customs Details</li></ul>', life: 20000 });
                }
                else {
                    this.$toast.add({ severity: 'success', summary: 'Record Clone Success!', detail: 'The record was cloned successfully.', life: 3000 });
                }
                this.resetModelState();
                setTimeout(() => this.$emit("is-reset"), 500);
                setTimeout(() => {
                    if (this.modelState)
                        this.modelState.$reset();
                    this.resetModelState();
                }, 3000);
            }
        },
        mounted() {
            setTimeout(() => {
                try {
                    if (this.modelState)
                        this.modelState.$reset();
                    this.resetModelState();
                }
                catch { /* NO CATCH */ }
            }, 1000);
            //setTimeout(() => {
            //    try {
            //        if (this.modelState)
            //            this.modelState.$reset();
            //        this.resetModelState();
            //    }
            //    catch { /* NO CATCH */ }
            //}, 5000);
        }
    },
    RECORD: {
        data() { return { ...model, ...data } },
        computed: { ...computed },
        methods: { ...method, ...crud },
        watch: { v$: { handler() { this.modelState = this.v$ }, deep: true }, },
        async created() {
            window.addEventListener("resize", this.onWindowResize);
            this.getRouter();
            this.model = this.init;
            if (this.$route.params.mask)
                this.get(this.$route.params.mask).then((response) => {
                    var data = response?.result ?? this.record;
                    if ((this.$route.params.mask ?? "").toUpperCase() === (data?.mask ?? "").toUpperCase())
                        this.model = response?.result ?? this.record;
                    else {
                        if (this.$route.params.mask) {
                            this.$toast.add({ severity: 'error', summary: 'RECORD NOT FOUND!', detail: "Insufficient access or disabled record.", life: 10000 });
                            try {
                                this.$router.push({ name: (this.router.name ?? this.router.module) + '-index' });
                            }
                            catch {
                                try {
                                    this.$router.push({ name: this.router.module + '-index' });
                                }
                                catch {
                                    try {
                                        this.$router.push({ name: 'dashboard-index' });
                                    } catch { /**/ }
                                }

                            }
                        }
                    }
                    this.$nextTick(() => { this.v$.$reset(); this.isModelDirty = false; });
                });
            this.activity.submitted = this.$vm.$submitted = false;
        },
        async mounted() {
            try {
                this.activity.loading.form = false;
            } catch {/**/ }
            
        },
        async updated() {
            this.getRouter();
            this.activity.submitted = this.$vm.$submitted;
        },
        async beforeUnmount() {
            try {
                this.activity.loading.form = true;
            } catch {/**/ }
            window.removeEventListener("resize", this.onWindowResize);
        },
        async unmount() { this.activity.loading = true; }
    },
    GRID: {

    }
}