<template>
    <is-dialog header="Carrier Account" :breakpoints="{'2500px': '85vw', '960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}" :visible="switchUser.dialog" @is-confirm="onSwitchConfirm" @is-cancel="onSwitchCancel" :confirmDisabled="!(switchUser?.type != null && switchUser?.user?.mask != null)" confirm="Confirm Switch" confirm-icon="far fa-people-arrows">
        <form-lookup id="userType" :value="switchUser.type" @is-sync="switchUser.type = $event.value; switchUser.user = { text: null, mask: null }; onSwitchUserSource()" Label="User Types" :source="sources.userTypes" :sorted="false" :required="true" />
        <form-lookup type="autocomplete" id="userType" :value="switchUser.user?.mask" label="User" :source="sources.filteredUsers" @is-sync="eventConsole($event)" valueField="mask" optionGroup="text" :required="true">
            <template #item="slotProps">
                <div class="country-item">
                    <div style="margin-left: 0.5rem;" v-tooltip.bottom="{ value: `${slotProps.item.userType}` }"><i :class="`mdi mdi-${(slotProps.item.type === 3 ? 'account-tie' : (slotProps.item.type === 4 ? 'account' : (slotProps.item.type === 5 ? 'account-supervisor-circle' : (slotProps.item.type === 6 ? 'account-circle' : (slotProps.item.type === 6 ? 'account-box' : '')))))}`" style="margin-right: 0.5rem; font-size: 1.15rem;"></i>{{slotProps.item.text}}</div>
                </div>
            </template>
        </form-lookup>
    </is-dialog>
    <div class="layout-topbar">
        <pv-menubar :model="menu" class="is-menu">
            <template #start>
                <img src="/_assets/img/Integro360_icon_transparent.png" style="height: 30px; width: 30px; object-fit: cover; border-radius: 4px; padding: 2px; border: 1px solid var(--surface-d); background-color: var(--surface-b); margin-top: 3px;" aria-controls="overlay_menu" @mouseover="onShortcuts" @click="onShortcuts" />
                <pv-tieredmenu ref="shortcut" id="overlay_menu" :model="shortcuts" :popup="true" style="width: 200px;" />
            </template>
            <template #end>
                <div class="p-d-flex p-d-md-inline-flex p-jc-between" style="width: 100% !important;">
                    <div v-if="this.userType !== 'Global Administrator'" class="p-mr-3" style="padding: 11px 11px 0 11px;">
                        <i class="fa fa-solid fa-bookmark" style="font-size: 22px !important; color: gold;" @mouseover="onShortcuts" @click="onShortcuts" />
                    </div>
                    <div style="padding-top: 5px;">
                        <ul class="topbar-menu">
                            <li class="topbar-submenu topbar-resources-submenu">
                                <div class="p-mr-3 p-d-none p-d-md-inline-flex">
                                    <!--
                        <div style="padding: 0 10px; border-radius: 4px 0 0 4px; border: 1px solid var(--surface-d); border-right: 0; background-color: var(--surface-c); height: 30px;">
                            <i class="pi pi-globe"></i>
                        </div>
                        <pv-dropdown ref="currentTenant"
                                     id="currentTenant"
                                     name="currentTenant"
                                     :options="tenants"
                                     optionLabel="name"
                                     optionValue="id"
                                     style="color: var(--text-color) !important; padding: 0 !important; border-radius: 0; border: 1px solid var(--surface-d); height: 30px; line-height: 0.75rem;"
                                     v-model="session.tenantID" />
                    -->
                                    <div v-if="this.userType === 'Global Administrator' && display.width > 768" style="padding: 0 7px !important; border-radius: 4px 0 0 4px; border: 1px solid var(--surface-d); border-right: 0; background-color: var(--surface-c); height: 30px; ">
                                        <i class="mdi mdi-star" title="Current Environment"></i>
                                    </div>
                                    <pv-dropdown v-if="this.userType === 'Global Administrator' && display.width > 768" ref="currentEnvironment"
                                                 id="currentEnvironment"
                                                 name="currentEnvironment"
                                                 :options="sources.environments"
                                                 optionLabel="name"
                                                 optionValue="id"
                                                 style="color: var(--text-color) !important; padding: 0 !important; border-radius: 0; border: 1px solid var(--surface-d); height: 30px; line-height: 14px; width: 200px !important; max-width: 200px; min-width: 200px; margin: 0 !important; font-size: max(1em, 14px) !important; "
                                                 v-model="session.environmentID"
                                                 @change="onEnvironmentSelect">
                                        <template #value="slotProps">
                                            <div v-if="slotProps.value" class="flex align-items-center" style="margin: -1rem !important; text-align: left; padding: 0 !important; line-height: 14px; max-width: 200px !important; text-overflow: ellipsis !important; white-space: nowrap !important; overflow: hidden; font-size: max(1em, 14px) !important; padding: 11px !important" v-tooltip.bottom="sources.environments.filter(x => x.id === slotProps.value)[0]?.name">{{ sources.environments.filter(x => x.id === slotProps.value)[0]?.name }}</div>
                                            <span v-else style="font-size: max(1em, 14px) !important;">
                                                {{ slotProps.placeholder }}
                                            </span>
                                        </template>
                                        <template #option="slotProps">
                                            <div class="flex align-items-center" style="font-size: max(1em, 14px) !important;">{{ slotProps.option.name }}</div>
                                        </template>
                                    </pv-dropdown>
                                    <div v-if="display.width > 768" style="padding: 0 7px; border: 1px solid var(--surface-d); border-right: 0; background-color: var(--surface-c); height: 30px; ">
                                        <i class="mdi mdi-warehouse" title="Current Warehouse"></i>
                                    </div>
                                    <pv-dropdown v-if="display.width > 768" ref="currentWarehouse"
                                                 id="currentWarehouse"
                                                 name="currentWarehouse"
                                                 :options="sources.warehouses"
                                                 optionLabel="name"
                                                 optionValue="id"
                                                 placeholder="No Warehouse Available"
                                                 style="color: var(--text-color) !important; padding: 0 !important; border-radius: 0 4px 4px 0; border: 1px solid var(--surface-d); line-height: 14px; height: 30px; width: 200px !important; max-width: 200px; min-width: 200px; margin: 0 !important; "
                                                 v-model="session.warehouseID"
                                                 @change="onWarehouseSelect">
                                        <template #value="slotProps">
                                            <div v-if="slotProps.value" class="flex align-items-center" style="margin: -1rem !important; text-align: left; line-height: 14px; height: 30px; max-width: 200px !important; text-overflow: ellipsis !important; white-space: nowrap !important; overflow: hidden; font-size: max(1em, 14px) !important; padding: 11px !important;" v-tooltip.bottom="sources.warehouses.filter(x => x.id === slotProps.value)[0]?.name">{{ sources.warehouses.filter(x => x.id === slotProps.value)[0]?.name }}</div>
                                            <span v-else style="font-size: max(1em, 14px) !important;">
                                                {{ slotProps.placeholder }}
                                            </span>
                                        </template>
                                        <template #option="slotProps">
                                            <div class="flex align-items-center" style="font-size: max(1em, 14px) !important;">{{ slotProps.option.name }}</div>
                                        </template>
                                    </pv-dropdown>
                                    <!--
                    <div style="padding: 0 10px; border: 1px solid var(--surface-d); border-right: 0; border-left: 0; background-color: var(--surface-c); height: 30px;">
                        <i class="pi pi-home"></i>
                    </div>
                    <pv-dropdown ref="currentCustomer"
                                    id="ccurrentCustomer"
                                    name="currentCustomer"
                                    :options="customers"
                                    optionLabel="name"
                                    optionValue="id"
                                    placeholder="No Customer Available"
                                    style="color: var(--text-color) !important; padding: 0 !important; border-radius: 0 4px 4px 0; border: 1px solid var(--surface-d); height: 30px; line-height: 0.75rem;"
                                    v-model="session.customerID"
                                    @change="onCustomerSelect" />
                    -->
                                </div>
                            </li>
                            <li class="topbar-submenu topbar-resources-submenu">
                                <div class="p-mr-3">
                                    <i class="pi pi-search" @mouseover="onSearch" @click="onSearch" />
                                    <pv-overlay-panel ref="searchPanel" style="padding: 5px !important;">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-search" />
                                            <pv-input ref="searchText" id="globalSearchText" type="text" v-model="searchText" placeholder="Search References" style="padding: 0.5rem 0.75rem 0.5rem 2.5rem; width: 300px; font-size: max(1rem, 14px) !important" @keyup="onFindSearch"  onfocus="this.select()" />
                                        </span>
                                    </pv-overlay-panel>
                                </div>
                            </li>
                            <li class="topbar-submenu topbar-resources-submenu">
                                <div tabindex="0" @click="showMenu" style="white-space: nowrap;"><span style="font-size: max(1rem, 14px); margin-right: 15px; white-space: nowrap;">{{ user?.fullName }}</span><i class="pi pi-bars" /></div>
                                <ul id="menuCustomer" style="display: none;">
                                    <li v-if="this.userType === 'Global Administrator' && display.width <= 768" class="p-mr-3 p-d-inline-flex p-d-md-none" style="padding-bottom: 0.25rem">
                                        <div v-if="this.userType === 'Global Administrator' && display.width <= 768" style="padding: 7px; border-radius: 4px 0 0 4px; border: 1px solid var(--surface-d); border-right: 0; background-color: var(--surface-c); height: 30px; ">
                                            <i class="mdi mdi-star" title="Current Environment"></i>
                                        </div>
                                        <pv-dropdown v-if="this.userType === 'Global Administrator' && display.width <= 768" ref="currentEnvironment"
                                                     id="currentEnvironment"
                                                     name="currentEnvironment"
                                                     :options="sources.environments"
                                                     optionLabel="name"
                                                     optionValue="id"
                                                     style="color: var(--text-color) !important; padding: 0 !important; border-radius: 0; border: 1px solid var(--surface-d); height: 30px; line-height: 14px; width: 220px !important; max-width: 220px; min-width: 220px; margin: 0 !important; font-size: max(1em, 14px) !important; "
                                                     v-model="session.environmentID"
                                                     @change="onEnvironmentSelect">
                                            <template #value="slotProps">
                                                <div v-if="slotProps.value" class="flex align-items-center" style="margin: -1rem !important; text-align: left; padding: 0 !important; line-height: 14px; max-width: 220px !important; text-overflow: ellipsis !important; white-space: nowrap !important; overflow: hidden; font-size: max(1em, 14px) !important; padding: 11px !important" v-tooltip.bottom="sources.environments.filter(x => x.id === slotProps.value)[0]?.name">{{ sources.environments.filter(x => x.id === slotProps.value)[0]?.name }}</div>
                                                <span v-else style="font-size: max(1em, 14px) !important;">
                                                    {{ slotProps.placeholder }}
                                                </span>
                                            </template>
                                            <template #option="slotProps">
                                                <div class="flex align-items-center" style="font-size: max(1em, 14px) !important;">{{ slotProps.option.name }}</div>
                                            </template>
                                        </pv-dropdown>
                                    </li>
                                    <li v-if="display.width <= 768" class="p-mr-3 p-d-inline-flex p-d-md-none" style="border-bottom: 1px solid var(--surface-d); padding-bottom: 0.5rem; width: 100%;">
                                        <div style="padding: 7px; border: 1px solid var(--surface-d); border-right: 0; background-color: var(--surface-c); height: 30px;">
                                            <i class="mdi mdi-warehouse" title="Current Warehouse"></i>
                                        </div>
                                        <pv-dropdown v-if="display.width <= 768" ref="currentWarehouse"
                                                     id="currentWarehouse"
                                                     name="currentWarehouse"
                                                     :options="sources.warehouses"
                                                     optionLabel="name"
                                                     optionValue="id"
                                                     placeholder="No Warehouse Available"
                                                     style="color: var(--text-color) !important; padding: 0 !important; border-radius: 0 4px 4px 0; border: 1px solid var(--surface-d); line-height: 14px; height: 30px; width: 220px !important; max-width: 220px; min-width: 220px; margin: 0 !important; "
                                                     v-model="session.warehouseID"
                                                     @change="onWarehouseSelect">
                                            <template #value="slotProps">
                                                <div v-if="slotProps.value" class="flex align-items-center" style="margin: -1rem !important; text-align: left; line-height: 14px; height: 30px; max-width: 220px !important; text-overflow: ellipsis !important; white-space: nowrap !important; overflow: hidden; font-size: max(1em, 14px) !important; padding: 11px !important;" v-tooltip.bottom="sources.warehouses.filter(x => x.id === slotProps.value)[0]?.name">{{ sources.warehouses.filter(x => x.id === slotProps.value)[0]?.name }}</div>
                                                <span v-else style="font-size: max(1em, 14px) !important;">
                                                    {{ slotProps.placeholder }}
                                                </span>
                                            </template>
                                            <template #option="slotProps">
                                                <div class="flex align-items-center" style="font-size: max(1em, 14px) !important;">{{ slotProps.option.name }}</div>
                                            </template>
                                        </pv-dropdown>
                                    </li>
                                    <li><router-link :to="{ name: 'profile-edit', params: { mask: user.mask }}" style="padding: 5px 8px; line-height: 25px;"><i class="fa fa-id-card fa-fw" style="font-size: inherit; margin-right: 10px;"></i>Profile</router-link></li>
                                    <li>
                                        <div class="p-d-flex p-jc-between" style="padding: 5px 8px;">
                                            <div style="line-height: 2rem;"><i class="mdi mdi-theme-light-dark" style="font-size: 0.7rem; margin-right: 10px;"></i>Theme</div>
                                            <div>
                                                <select ref="ddlTheme" :value="theme" @change="UpdateTheme" class="p-dropdown p-component p-inputwrapper p-fluid" style="height: 26px !important; color: var(--text-color) !important; padding: 3px 10px; min-width: 100px; font-size: 14px; ">
                                                    <option value="dark">Dark</option>
                                                    <option value="light">Light</option>
                                                </select>
                                            </div>
                                        </div>
                                    </li>
                                    <li style="border-bottom: 1px solid var(--surface-d);">
                                        <div class="p-d-flex p-jc-between" style="padding: 5px 8px;">
                                            <div style="line-height: 2rem;"><i class="mdi mdi-resize" style="font-size: 0.7rem; margin-right: 10px; "></i>Display Scale</div>
                                            <div>
                                                <select :value="displayScale" @change="UpdateScale" class="p-dropdown p-component p-inputwrapper p-fluid" style="height: 26px !important; color: var(--text-color) !important; padding: 3px 10px; min-width: 100px; font-size: 14px;">
                                                    <option value="10">Tiny</option>
                                                    <option value="12">Small</option>
                                                    <option value="14">Normal</option>
                                                    <option value="16">Large</option>
                                                    <option value="18">Huge</option>
                                                </select>
                                            </div>
                                        </div>
                                    </li>
                                    <!--<li v-if="(this.$filters.getFlags(user.userPermission ?? 0).findIndex(x => x === 8) !== -1 || user.userType &lt;= 2 || user.userType &lt;= 5)" style="padding-top: 5px; padding-bottom: 5px; border-bottom: 1px solid var(--surface-d);"><a @click="switchUser.dialog = true" style="padding: 5px 8px; line-height: 25px;"><i class="far fa-people-arrows" style="font-size: inherit; margin-right: 10px;"></i>SwitchUser</a></li>-->
                                    <li style="padding-top: 5px;"><a @click="this.$emit('is-logout')" style="padding: 5px 8px; line-height: 25px;"><i class="pi pi-fw pi-sign-out" style="font-size: inherit; margin-right: 10px;"></i>Logout</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </template>
        </pv-menubar>
    </div>
</template>
<script>
    import config from '@/assets/lib/cosmos/_js/config.js';
    import { mapGetters } from 'vuex';
    export default {
        name: 'CosmosHeaderComponent',
        emits: ["is-sync", "is-logout"],
        data() {
            return {
                switchUser: {
                    dialog: false,
                    type: -1,
                    user: {
                        text: null,
                        mask: null
                    }
                },
                selectedUser: null,
                selectedUserRecord: {
                    text: null,
                    mask: null
                },
                selectedUserType: -1,
                session: {
                    tenantID: 1,
                    environmentID: null,
                    warehouseID: null,
                    customerID: -1,
                },
                sources: {
                    environments: [],
                    warehouses: [],
                    users: [],
                    filteredUsers: [],
                    userTypes: []
                },
                keys: null,
                companyKeys: null,
                shortcuts: [],
                searchText: null,
                display: { width: 1000, height: 1000 }
            }
        },
        watch: {
            keys() {                
                this.onRefreshKeys(); this.onRefresh();
            },
            companyKeys() {
                this.onRefreshKeys(); this.onRefresh();
            },
            environments() {
                if (this.sources.environments.filter(x => x.id === this.session.environmentID)[0] === undefined)
                    this.setCurrent("environment", this.sources.environments[0].id, this.sources.environments[0]?.name);
            },
            warehouses() {
                if (this.sources.warehouses.length > 0 && this.sources.warehouses.filter(x => x.id === this.session.warehouseID)[0] === undefined) 
                    this.setCurrent("warehouse", this.sources.warehouses[0].id, this.sources.warehouses[0]?.name);
            },
            customers() {
                if (this.customers.filter(x => x.id === this.session.customerID)[0] === undefined)
                    this.setCurrent("customer", this.customers[0]?.id, this.customers[0]?.name);
            },
            displayWidth() {
                return this.displayWidth;
            },
            $route(to, from) {
                this.onShortcut(to, from);
            }
        },
        computed: {
            ...mapGetters({
                user: "auth/currentUser",
                menu: "auth/currentMenu",
                userType: "auth/currentUserType",
                loggedIn: "auth/loggedIn"
            }),
            theme() { return this.loggedIn ? (this.user.defaults.theme === 100 ? "dark" : "light") : "dark"; },
            displayScale() { return this.loggedIn ? this.user.defaults.display : 14; },
            tenants() { return this.$config.common.$tenant(); },
            environments() { return this.$config.common.$environment(); },
            warehouses() { return this.$config.common.$warehouse(this.session.environmentID, this.user); },
            customers() { return this.$config.common.$customer(this.session.environmentID, this.session.warehouseID, this.user); }
        },
        methods: {
            onShortcut(to) {
                var pathname = to?.path ?? window.location.pathname;
                var items = [
                    {
                        label: 'Create Consignment',
                        icon: 'fa fa-solid fa-file-pen fa-2x',
                        items: [
                            {
                                label: 'Export',
                                icon: 'fa fa-solid fa-square-e fa-2x',
                                to: '/transaction/consignment/create?type=1'
                            },
                            {
                                label: 'Import',
                                icon: 'fa fa-solid fa-square-i fa-2x',
                                to: '/transaction/consignment/create?type=2'
                            },
                            {
                                label: 'Third-Country/Party',
                                icon: 'fa fa-solid fa-square-p fa-2x',
                                to: '/transaction/consignment/create?type=4'
                            },
                        ]
                    },
                    {
                        label: 'Quote Consignment',
                        icon: 'fa fa-solid fa-filter-circle-dollar',
                        to: '/facility/rates/quote'
                    },
                    {
                        label: 'Import Consignments',
                        icon: 'fa fa-solid fa-upload fa-2x',
                        to: '/facility/import/requests'
                    },
                ]
                if (pathname.indexOf("transaction/consignment/create") >= 0)
                    items = items.filter(x => x.label !== "Create Consignment");
                if (pathname.indexOf("facility/rates/quote") >= 0)
                    items = items.filter(x => x.label !== "Quote Consignment");
                if (pathname.indexOf("facility/import/requests") >= 0)
                    items = items.filter(x => x.label !== "Import Consignments");
                this.shortcuts = items;
            },
            onWindowResize() {
                this.display.width = document.documentElement.clientWidth;
                this.display.height = document.documentElement.clientHeight;
            },
            setCurrent(type, value, name) {
                this.session[type + "Id"] = value;
                this.$vm.$appSession[type] = { id: value, name: name };
                window.$appSession[type] = { id: value, name: name };
                let activeKeys = (isNaN(this.session.tenantID) ? 1 : Number(this.session.tenantID)) + "|" + this.$vm.$appSession["environment"].id + "|" + this.$vm.$appSession["warehouse"].id + "|" + (this.$vm.$appSession["customer"]?.id ?? -1000) + "|" + this.user?.mask + "|" + this.user?.mask;
                this.$store.dispatch("auth/getSitemap");
                sessionStorage.setItem("activekeys", btoa(activeKeys));
                localStorage.setItem("activekeys", btoa(activeKeys));
                if (value !== undefined && value !== null)
                    this.$store.dispatch("user/getURL", { url: `/services/settings/user/default/${type}/${value}` });
                this.$store.commit("auth/SET_SESSION_KEY", btoa(activeKeys));
                this.resetSetItem("activekeys", btoa(activeKeys));
            },
            onEnvironmentSelect(e) {
                let selected = this.sources.environments.filter(x => x.id === e.value)[0]
                this.setCurrent("environment", selected.id, selected.name);
                this.sources.warehouses = this.$config.common.$warehouse(selected.id, this.user) ?? [];
                var _self = this;
                const meta = this.$router.currentRoute.value.meta;
                let _module = meta.module;
                let _type = meta.type;
                this.$store.dispatch("companyprofile/getURL", { url: `/services/settings/companygeneralsetting/bycompany/${selected?.mask}`.replaceAll("//", "/") })
                    .then((response) => {
                        this.$store.commit("auth/SET_SESSION_SETTING", response.result);
                    });
                setTimeout(() => {
                    this.$nextTick(() => {
                        try { _self.$router.push({ name: (_type ?? _module) === "quote" ? "quote-terminal" : (_type ?? _module) + "-index" }); }
                        catch { _self.$router.push({ name: "dashboard-index" }); }
                    });
                }, 1000);
            },
            onWarehouseSelect(e) {
                let selected = this.sources.warehouses.filter(x => x.id === e.value)[0]
                this.setCurrent("warehouse", selected.id, selected.name);
                var _self = this;
                const meta = this.$router.currentRoute.value.meta;
                let _module = meta.module;
                let _type = meta.type;
                setTimeout(() => {
                    this.$nextTick(() => {
                        _self;
                        try {
                            _self.$router.push({
                                name: (_type ?? _module) === "quote" ? "quote-terminal" :
                                    (_type ?? _module) === "rule" ? "rule-simulate" :
                                     (_type ?? _module) + "-index"
                            });
                        }
                        catch { _self.$router.push({ name: "dashboard-index" }); }
                    });
                }, 1000);
            },
            onCustomerSelect(e) {
                let selected = this.customers.filter(x => x.id === e.value)[0]
                this.setCurrent("customer", selected.id, selected.name);
            },
            showMenu(e) {
                var obj = e.target.parentElement.parentElement;
                var state = obj.getElementsByTagName("ul")[0].style.display;
                obj.getElementsByTagName("ul")[0].style.display = state === "block" ? "none" : "block";
            },
            UpdateScale(event) {
                var ddlScale = event.target;
                var value = ddlScale.options[ddlScale.selectedIndex].value;
                this.$axios.put(`${this.$config.config.endpoint.api}/services/settings/user/display/${value}`)
                    .then((response) => {
                        var session = this.$ls.get("x-cosmos-session");
                        session.defaults.display = response.data.result.display;
                        this.$ls.set("x-cosmos-session", session);
                        var html = document.getElementsByTagName("HTML")[0];
                        var att = document.createAttribute("style");
                        att.value = "font-size: " + response.data.result.display + "px !important";
                        html.setAttributeNode(att);
                    });
            },
            UpdateTheme(event) {
                var ddlTheme = event.target;
                var value = ddlTheme.options[ddlTheme.selectedIndex].value === "dark" ? 100 : 200;
                this.$axios.put(`${this.$config.config.endpoint.api}/services/settings/user/theme/${value}`)
                    .then((response) => {
                        var session = this.$ls.get("x-cosmos-session");
                        session.defaults.theme = response.data.result.theme;
                        this.$ls.set("x-cosmos-session", session);
                        sessionStorage.setItem("session-theme", session.defaults.theme);
                        var body = document.getElementsByTagName("body")[0];
                        let theme = body.dataset['theme'] === "light" ? "dark" : "light";
                        let themeElement = document.getElementById('theme-link');
                        themeElement.setAttribute('href', "/_assets/themes/mdc-" + theme + "-indigo/theme.css");
                        body.dataset['theme'] = theme;
                        sessionStorage.setItem("session-theme", theme);
                        this.$emit("is-sync", theme);
                    })
            },
            onRefresh() {
                this.sources.environments = this.$config.common.$environment();
                if (this.sources.environments.filter(x => x.id === this.session.environmentID)[0] === undefined)
                    this.setCurrent("environment", this.sources.environments[0].id, this.sources.environments[0].name);
                this.sources.warehouses = this.$config.common.$warehouse(this.session.environmentID, this.user);
                if (this.sources.warehouses.length > 0  && this.sources.warehouses.filter(x => x.id === this.session.warehouseID)[0] === undefined) {
                    let warehouse = this.sources.warehouses.sort(this.$config.tools.dynamicNumericSort("id"))[0];
                    this.setCurrent("warehouse", warehouse.id, warehouse.name);
                }
                if (this.customers.length > 0 && this.customers.filter(x => x.id === this.session.customerID)[0] === undefined)
                    this.setCurrent("customer", this.customers[0].id, this.customers[0].name);
            },
            keyUpdate() {
                this.keys = sessionStorage.getItem('activekeys') ?? localStorage.getItem("activekeys");
                this.companyKeys = sessionStorage.getItem('companyupdate'); //For Header DropDown Environment, Warehosue / Customer Updates
                setTimeout(() => { this.keyUpdate(); }, 1000);
            },
            onRefreshKeys() {
                let activeKeys = atob(this.keys).split('|');
                this.session = {
                    tenantID: isNaN(activeKeys[0]) ? 1 : Number(activeKeys[0]),
                    environmentID: Number(activeKeys[1]),
                    warehouseID: Number(activeKeys[2]),
                    customerID: Number(activeKeys[3])
                }
                //sessionStorage.setItem('activekeys', this.keys);
            },
            eventConsole(event) {
                if (!event) {
                    this.switchUser.user = { text: null, mask: null };
                }
                if (event?.value != null) {
                    this.switchUser.user = event.record;
                }
            },
            onSwitchConfirm() {
                this.$toast.add({ severity: 'info', summary: 'UNDER DEVELOPMENT', detail: 'Feature is under development / testing.', life: 3000 });
                this.onSwitchCancel();
            },
            onSwitchCancel() {
                this.switchUser.dialog = false;
                this.switchUser.type = -1;
                this.switchUser.user = {
                    text: null,
                    mask: null
                };
            },
            onSwitchUserSource() {
                let source = [];
                for (let data of this.sources.users) {
                    var items = data.items ?? [];
                    if (this.switchUser.type !== -1)
                        items = items.filter(x => x.type === this.switchUser.type);
                    if (items && items.length)
                        source.push({ ...data, ...{ items: items } });
                }
                this.sources.filteredUsers = source;
            },
            onShortcuts(event) {
                this.$refs.shortcut.toggle(event);
            },
            onSearch(event) {
                this.$refs.searchPanel.toggle(event);
                setTimeout(() => {
                    this.$nextTick(() => {
                        const searchText = document.getElementById("globalSearchText");
                        searchText?.focus();
                    });
                }, 100);
            },
            onFindSearch(event) {
                if (event.keyCode === 13 && (this.searchText ?? "").trim().length > 0) {
                    let _self = this;
                    setTimeout(() => {
                        this.$nextTick(() => {
                            try { _self.$router.push({ name: "search-index", params: { q: this.searchText } }); }
                            catch { _self.$router.push({ name: "dashboard-index" }); }
                        });
                    }, 1000);
                }
            }
        },
        created() {
            this.onShortcut();
        },
        mounted() {
            window.addEventListener("resize", this.onWindowResize);
            var defaultEnvironment = this.environments.sort(config.tools.dynamicNumericSort("id"))[0]?.id;
            var defaultWarehouse = this.warehouses.sort(config.tools.dynamicNumericSort("id"))[0]?.id;

            // PATCH FOR NEW TAB CURRENT WAREHOUSE SESSION UNABLE TO ACQUIRE USE LOCALSTORAGE FOR CATCH
            if (sessionStorage.getItem('activekeys')) {
                this.keys = sessionStorage.getItem('activekeys');
                this.onRefreshKeys();
            }
            else if (localStorage.getItem("activekeys")) {
                sessionStorage.setItem("activekeys", localStorage.getItem("activekeys"));
                this.keys = sessionStorage.getItem('activekeys');
                this.onRefreshKeys();
            }

            this.session.tenantID = this.user?.tenantID ?? this.session?.tenantID ?? 1;
            this.session.environmentID = this.session?.environmentID ?? this.user?.defaults?.environmentID ?? defaultEnvironment;
            this.session.warehouseID = this.session?.warehouseID ?? this.user?.defaults?.warehouseID ?? defaultWarehouse;

            this.$vm.$appSession["environment"] = { id: this.session.environmentID, name: "" };
            this.$vm.$appSession["warehouse"] = { id: this.session.warehouseID, name: "" };
            let activeKeys = (this.session.tenantID ?? 1) + "|" + this.$vm.$appSession["environment"].id + "|" + this.$vm.$appSession["warehouse"].id + "|" + (this.$vm.$appSession["customer"]?.id ?? -1000) + "|" + this.user?.mask + "|" + this.user?.mask;
            sessionStorage.setItem("activekeys", btoa(activeKeys));
            localStorage.setItem("activekeys", btoa(activeKeys));
            this.resetSetItem("activekeys", btoa(activeKeys));
            this.keys = btoa(activeKeys);
            this.onRefreshKeys();
            this.onRefresh();
            if (this.user != null && this.$filters.getFlags(this.user.userPermission ?? 0).findIndex(x => x === 8) !== -1 || this.user.userType <= 2 || this.user.userType <= 5) {
                this.sources.userTypes = this.$vm.$enums["usertype"].filter(x => x.id > 1 && x.id > this.user.userType);
                this.sources.userTypes.unshift({ id: -1, text: "All", code: "ALL" });
                this.$store.dispatch("user/getURL", { url: '/services/settings/user/getallowedswitchto', sort: null, where: null }).then((response) => {
                    if (this.user != null)
                        this.sources.users = (response.result ?? []);
                    this.onSwitchUserSource();
                });
            }
            let selected = this.environments.filter(x => x.id === this.session.environmentID)[0];
            this.$store.dispatch("companyprofile/getURL", { url: `/services/settings/companygeneralsetting/bycompany/${selected?.mask}`.replaceAll("//", "/") })
                .then((response) => {
                    this.$store.commit("auth/SET_SESSION_SETTING", response.result);
                });
            this.onShortcut();

            this.keyUpdate(); this.onWindowResize();
        },
        beforeUnmount() {
            window.removeEventListener("resize", this.onWindowResize);
        }
    }
</script>
<style>
    .p-overlaypanel .p-overlaypanel-content {
        padding: 0.25rem !important;
    }
    .p-menubar .p-submenu-list {
        width: 200px;
        white-space: nowrap;
    }
</style>