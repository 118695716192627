<template>
    <!--<is-loader v-if="activity.parentLoading"></is-loader>-->
    <div class="content-section header">
        <div class="feature-intro">
            <h1><span>RULE ENGINE SIMULATION</span></h1>
            <p>Simulate service group rules and conditions</p>
        </div>
    </div>
    <div class="content-section body" style="min-height: calc(100vh - 200px);">
        <div class="card" style="padding: 0;">
            <pv-accordion :multiple="true" :activeIndex="allActiveIndex" id="RuleEngineAccordion" @tab-open="onOpen" @tab-close="onClose">
                <pv-accordion-tab>
                    <template #header>
                        <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                            <div class="p-col-fixed p-as-start"><i class="pi pi-bars"></i></div>
                            <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                                <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; CONSIGNMENT DETAILS</div>
                            </div>
                        </div>
                    </template>
                    <div class="p-fluid p-grid">
                        <div class="p-col-12 p-md-3">
                            <form-lookup id="customerID" :required="true" label="Customer" :source="scopeCustomerDS" :value="model.order.customerID" :v$="v$" :textField="'name'" :valueField="'id'" @is-sync="{(model.order.customerID = $event?.value ?? scopeCustomerDS[0]?.id ?? null); getSetting();}" />
                        </div>
                        <div class="p-col-12 p-md-3">
                            <form-lookup id="serviceGroupID" label="Service Group" :required="true" :source="`services/facility/servicegroup/customer/id/${model.order?.customerID ?? -1000}`" sourceType="url" :value="model.order.serviceGroupID" :v$="v$" :textField="'text'" :valueField="'id'" @is-sync="{model.order.serviceGroupID = $event.value; onSelectServiceGroup($event)}" :sorted="false" />
                        </div>
                    </div>
                    <div class="p-grid">
                        <div class="p-col-12 p-md-3">
                            <div class="card" style="padding: 0.7rem; min-height: 30.74rem; ">
                                <h6><span>Collection Details</span></h6>
                                <!--<ruleAddress-form id="collectionAddress" :value="collectionAddress" @is-sync="populateCollectionAddress($event)" :showAddress="true" :useCompany="true" />-->
                                <form-address id="collectionAddress" :value="collectionAddress" :validateState="companySettingRecord?.setting_CheckState ?? true" :validatePostalCode="companySettingRecord?.setting_CheckPostalCode ?? true" :v$="v$" @is-sync="populateCollectionAddress($event)" :useCompany="true" :forceEnableCompany="true" :disabled="disablecollectionAddress" :showLookup="true" :showCopy="false" />
                                <form-contact id="collectionContact" :value="collectionContact" :useRegistrationNumber="false" :v$="v$" @is-sync="populateCollectionContact($event)" :showDirectory="true" :useContactDetail="false" :directoryFilterID="model.order.customerID" :applyValidatorRequired="false" />
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3">
                            <div class="card" style="padding: 0.7rem; min-height: 30.74rem; ">
                                <h6><span>Delivery Details</span></h6>
                                <!--<ruleAddress-form id="deliveryAddress" :value="deliveryAddress" @is-sync="populateDeliveryAddress($event)" :showAddress="true" :useCompany="true" />-->
                                <form-address id="deliveryAddress" :value="deliveryAddress" :validateState="companySettingRecord?.setting_CheckState ?? true" :validatePostalCode="companySettingRecord?.setting_CheckPostalCode ?? true" :v$="v$" @is-sync="populateDeliveryAddress($event)" :useCompany="true" :forceEnableCompany="true" :showLookup="true" :showCopy="false" />
                                <form-contact id="deliveryContact" :value="deliveryContact" :useRegistrationNumber="false" :v$="v$" @is-sync="populateDeliveryContact($event)" :showDirectory="true" :useContactDetail="false" :directoryFilterID="model.order.customerID" :applyValidatorRequired="false" />
                                <!--<form-input id="deliveryFirstname" label="First Name" :v$="v$" :value="model.deliveryContact.firstName" @is-sync="model.deliveryContact.firstName = $event.value" />
                <form-input id="deliveryLastname" label="Last Name" :v$="v$" :value="model.deliveryContact.lastName" @is-sync="model.deliveryContact.lastName = $event.value" />-->
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3">
                            <div class="card" style=" padding: 0.7rem; min-height: 30.74rem; ">
                                <h6><span>Order Details</span></h6>
                                <form-input id="reference" label="Reference 1" :v$="v$" :value="model.order.reference_1" @is-sync="model.order.reference_1 = $event.value" />
                                <form-input id="reference2" label="Reference 2" :v$="v$" :value="model.order.reference_2" @is-sync="model.order.reference_2 = $event.value" />
                                <form-input id="reference3" label="Reference 3" :v$="v$" :value="model.order.reference_3" @is-sync="model.order.reference_3 = $event.value" />
                                <form-input id="reference4" label="Reference 4" :v$="v$" :value="model.order.reference_4" @is-sync="model.order.reference_4 = $event.value" />
                                <form-input id="reference5" label="Reference 5" :v$="v$" :value="model.order.reference_5" @is-sync="model.order.reference_5 = $event.value" />
                                <form-input id="reference6" label="Reference 6" :v$="v$" :value="model.order.reference_6" @is-sync="model.order.reference_6 = $event.value" />
                                <form-input id="reference7" label="Reference 7" :v$="v$" :value="model.order.reference_7" @is-sync="model.order.reference_7 = $event.value" />
                                <form-input id="instruction" label="Delivery Instruction" :v$="v$" :value="model.order.instruction" @is-sync="model.order.instruction = $event.value" />
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3">
                            <div class="card" style="padding: 0.7rem; min-height: 30.84rem;">
                                <h6><span>Others</span></h6>
                                <form-lookup :value="model.currencyID" :required="true" id="currencyID" label="Currency" type="dropdown" source="currency" @is-sync="{model.currencyID = $event.value; model.currencyCode = $event.record?.code ;}"></form-lookup>
                                <form-lookup id="weightUnit" :required="true" type="enum" source="weightunit" :value="model.order.weightUnit" label="Unit Weight" @is-sync="{model.weightUnit = $event.value; model.order.weightUnit = $event.value;model.weightUnitText = $event.text;}" :v$="v$"></form-lookup>
                                <form-lookup id="dimensionUnit" :required="true" type="enum" source="lengthunit" :value="model.order.dimensionUnit" label="Unit Length" @is-sync="{model.dimensionUnit = $event.value; model.order.dimensionUnit = $event.value;  model.dimensionUnitText = $event.text;}" :v$="v$"></form-lookup>
                                <form-lookup id="incoterm" :required="true" type="enum" source="incotermtype" :value="model.order.incoTerms" label="Incoterm" @is-sync="model.incoTerms = $event.value" :v$="v$"></form-lookup>
                                <form-lookup id="customsCategoryType" :required="true" type="enum" source="customscategorytype" :value="model.order.customsCategoryType" label="Customs Category" @is-sync="model.order.customsCategoryType = $event.value" :v$="v$"></form-lookup>
                                <form-lookup id="packageType" :required="true" type="enum" source="serviceproviderproductpackagingtype" :value="model.order.packageType" label="Package Type" @is-sync="model.order.packageType = $event.value" :v$="v$"></form-lookup>
                                <form-datetime id="collectionDate" :value="collectionDateVal" type="date" @is-sync="model.order.collectionDate = $event.record.data;" label="Collection Date" :v$="v$"></form-datetime>
                            </div>
                        </div>
                    </div>
                    <div v-if="consignmentDetails && !packageDetails" class="p-d-flex p-jc-end">
                        <pv-button class="p-mr-1" v-if="((model.parcels?.length ?? 0) > 0 && (model.parcels?.length ?? 0) <= 99) && (model?.order?.serviceGroupID > 0)" :disabled="activity.loading || v$.$invalid" :loading="activity.loading" :label="(activity.loading ? 'Processing...' : 'Get Service')" icon="pi pi-search" @click="getService()"></pv-button>
                        <pv-button class="p-mr-1" label="Reset" icon="pi pi-refresh" :disabled="activity.loading" :loading="activity.loading" @click="reset()"></pv-button>
                    </div>
                </pv-accordion-tab>
                <pv-accordion-tab>
                    <template #header>
                        <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                            <div class="p-col-fixed p-as-start"><i class="fa fa-cubes"></i></div>
                            <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                                <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; PACKAGE DETAILS</div>
                            </div>
                        </div>
                    </template>
                    <div style="overflow-x: auto;padding: 1.25rem 0 0; margin-bottom: 0.25rem;">
                        <quote-parcel :source="model.parcels" :header="model" @is-sync="model.parcels[$event.parcelID - 1] = $event.model" :units="units"
                                      @is-add="onAdd"
                                      @is-delete="onDelete"
                                      @is-copy-next="onCopyNext"
                                      @is-copy="onCopyValue" />
                    </div>
                    <div v-if="(!consignmentDetails && packageDetails) || (consignmentDetails && packageDetails)" class="p-d-flex p-jc-end">
                        <pv-button class="p-mr-1" v-if="((model.parcels?.length ?? 0) > 0 && (model.parcels?.length ?? 0) <= 99) && (model?.order?.serviceGroupID > 0)" :disabled="activity.loading  || v$.$invalid" :loading="activity.loading" :label="(activity.loading ? 'Processing...' : 'Get Service')" icon="pi pi-search" @click="getService()"></pv-button>
                        <pv-button class="p-mr-1" label="Reset" icon="pi pi-refresh" :disabled="activity.loading" :loading="activity.loading" @click="reset()"></pv-button>
                    </div>
                </pv-accordion-tab>
                <pv-accordion-tab v-if="(productservice ?? []).length > 0">
                    <template #header>
                        <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                            <div class="p-col-fixed p-as-start"><i class="fa fa-list"></i></div>
                            <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                                <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; RESULT</div>
                            </div>
                        </div>
                    </template>
                    <div style="width: calc(100% + 2.5rem); padding: 0; margin: -2rem -1.3rem; margin-bottom: -1rem;">
                        <cosmos-grid id="serviceResult" :bypassArrayFilter="true" sourceType="array" :source="productservice ?? []" :sourcePaged="true" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields" :showExport="false" :showDelete="false" :showEdit="false" :showSelection="false" :showCheckbox="false" :showAdd="false" :showHeader="false" :showOperation="false">
                            <pv-grid-column field="priority" header="PRIORITY" headerStyle="max-width: 1.5rem !important;" style="white-space:unset !important; word-break: break-word !important; flex-wrap:wrap; text-align:center !important;">
                                <template #body="slotProps">
                                    <span style="word-break: break-word !important;">{{slotProps.data.Priority}}</span>
                                </template>
                            </pv-grid-column>
                            <pv-grid-column field="CarrierCode" header="CARRIER" style="white-space:unset !important; word-break: break-word !important; flex-wrap:wrap;">
                                <template #body="slotProps">
                                    <span v-if="slotProps.data.CarrierCode"><span class="p-code-text"><img style="float:left !important; margin-right:0.25rem; width: 35px; height: 20px; max-height: 20px; max-width: 35px; object-fit: contain; object-position: center;" :src="`${slotProps.data.Logo}`" onerror="this.onerror=null; this.src = '/_assets/img/no-image-box.png'" /> [{{ slotProps.data.CarrierCode}}] - {{ slotProps.data.CarrierName}} </span></span>
                                </template>
                            </pv-grid-column>
                            <pv-grid-column field="ServiceCode" header="SERVICE" style="white-space:unset !important; word-break: break-word !important; flex-wrap:wrap;">
                                <template #body="slotProps">
                                    <span v-if="slotProps.data.ServiceCode" class="p-code-text">{{ slotProps.data.ServiceCode }} - {{ slotProps.data.ServiceName }} {{ slotProps.data.PptionName}}</span>
                                </template>
                            </pv-grid-column>
                        </cosmos-grid>
                    </div>
                </pv-accordion-tab>
                <pv-accordion-tab v-if="(rules ?? []).length > 0">
                    <template #header>
                        <div class="p-d-flex p-nogutter p-jc-end" style="margin: -0.5rem -1rem -1rem 0; width: 100%;">
                            <div class="p-col-fixed p-as-start"><i class="fa fa-list"></i></div>
                            <div class="p-col" style="font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;">
                                <div class="p-accordion-header-text" style="margin: 0; padding: 0; font-weight: 700; text-overflow: ellipsis; white-space: nowrap; overflow: hidden; max-width: calc(100% - 5rem) !important;"> &nbsp; RULES AND CONDITIONS</div>
                            </div>
                        </div>
                    </template>
                    <div style="width: calc(100% + 2.5rem); padding: 0; margin: -2rem -1.3rem; margin-bottom: -1rem;">
                        <cosmos-grid id="rules" :bypassArrayFilter="true" sourceType="array" :source="rules ?? []" :sourcePaged="true" :autoGenerate="false" :columns="columns" :deleteColumns="searchFields"  :showExport="false" :showDelete="false" :showEdit="false" :showSelection="false" :showCheckbox="false" :showAdd="false" :showHeader="false" :showOperation="false" @is-refresh="getServiceGroup">
                            <pv-grid-column field="code" header="RULE" style="width: calc(100% - 80%);white-space: unset !important; flex-wrap: wrap;">
                                <template #body="slotProps">
                                    <div class="p-grid">
                                        <div class="p-col-12 p-md-12">
                                            <div class="p-grid">
                                                <div class="p-col-fixed" style="width: 130px; font-weight: bold;">Clause</div>
                                                <div class="p-col-fixed" style="width: 10px; font-weight: bold;">:</div>
                                                <div class="p-col"> <span class="p-code-text"> {{ slotProps.data?.clauseName }}</span></div>
                                            </div>
                                            <div class="p-grid">
                                                <div class="p-col-fixed" style="width: 130px; font-weight: bold; ">Carrier</div>
                                                <div class="p-col-fixed" style="width: 10px; font-weight: bold;">:</div>
                                                <div v-if="slotProps.data?.carrier?.name" class="p-col"> <span class="p-code-text"> [{{ slotProps.data?.carrier?.code }}] {{ slotProps.data?.carrier?.name }}</span></div>
                                                <div v-else class="p-col"> <span class="p-code-text"> All </span></div>
                                            </div>
                                            <div class="p-grid">
                                                <div class="p-col-fixed" style="width: 130px; font-weight: bold; ">Service</div>
                                                <div class="p-col-fixed" style="width: 10px; font-weight: bold;">:</div>
                                                <div v-if="slotProps.data?.product?.name" class="p-col"> <span class="p-code-text"> [{{ slotProps.data?.product?.code }}] {{ slotProps.data?.product?.name }}</span></div>
                                                <div v-else class="p-col"> <span class="p-code-text"> All </span></div>
                                            </div>
                                            <div class="p-grid" v-if="slotProps.data?.option?.name">
                                                <div class="p-col-fixed" style="width: 130px; font-weight: bold; ">Option</div>
                                                <div class="p-col-fixed" style="width: 10px; font-weight: bold;">:</div>
                                                <div class="p-col"> <span class="p-code-text"> [{{ slotProps.data?.option?.code }}] {{ slotProps.data?.option?.name }}</span></div>
                                                
                                            </div>
                                            <div class="p-grid">
                                                <div class="p-col-fixed" style="width: 130px; font-weight: bold; ">Warehouse</div>
                                                <div class="p-col-fixed" style="width: 10px; font-weight: bold;">:</div>
                                                <div v-if="slotProps.data?.warehouse?.name" class="p-col"> <span class="p-code-text"> {{ slotProps.data?.warehouse?.name }}</span></div>
                                                <div v-else class="p-col"> <span class="p-code-text"> All </span></div>
                                            </div>
                                            <div class="p-grid">
                                                <div class="p-col-fixed" style="width: 130px; font-weight: bold; ">Incoterms</div>
                                                <div class="p-col-fixed" style="width: 10px; font-weight: bold;">:</div>
                                                <div v-if="slotProps.data?.incotermsName" class="p-col"> <span class="p-code-text"> {{ slotProps.data?.incotermsName }}</span></div>
                                                <div v-else class="p-col"> <span class="p-code-text"> All </span></div>
                                            </div>
                                            <div class="p-grid">
                                                <div class="p-col-fixed" style="width: 130px; font-weight: bold;">Customs Category Type</div>
                                                <div class="p-col-fixed" style="width: 10px; font-weight: bold;">:</div>
                                                <div v-if="slotProps.data?.customsCategoryTypeName" class="p-col"> <span class="p-code-text"> {{ slotProps.data?.customsCategoryTypeName }}</span></div>
                                                <div v-else class="p-col"> <span class="p-code-text"> All </span></div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </pv-grid-column>
                            <pv-grid-column field="text" header="CONDITIONS" style="white-space:unset !important; word-break: break-word !important; flex-wrap:wrap; text-align:justify-all !important;">
                                <template #body="slotProps">
                                    <span style="word-break: break-word !important;" v-html="slotProps.data?.text"></span>
                                </template>
                            </pv-grid-column>
                        </cosmos-grid>
                    </div>
                </pv-accordion-tab>
            </pv-accordion>
        </div>
    </div>
</template>
<script>
    //const config = require('@/assets/lib/cosmos/_js/config.js').default.config;
    import { mapGetters } from 'vuex';
    //const config = require("@/assets/_js/cosmos-config.js").default;
    import mixins from '@/assets/lib/cosmos/_js/final-mixins.js';
    //import validator from '@/assets/_js/cosmos-validators.js';
    import { useVuelidate } from '@vuelidate/core';
    //import { required, minLength, maxLength } from '@vuelidate/validators';
    //import addressForm from '@/components/ruleengine/form-simulate-address.vue';
    import parcel from '@/components/ruleengine/simulate/rule-parcel-form.vue';
    const moduleName = 'servicegroup';
    export default {
        name: moduleName + 'Record',
        mixins: [mixins.RECORD],
        setup: () => ({ v$: useVuelidate() }),
        components: {
            //'ruleAddress-form': addressForm,
            'quote-parcel': parcel
        },
        data() {
            return {
                model: {
                    collectionContact: {
                        firstName: null,
                        lastName: null
                    },
                    collection: {
                        countryID: 238,
                        city: null,
                        state: null,
                        postalCode: null,
                        companyName: null,
                        address1: null,
                        address2: null,
                        address3: null
                    },
                    deliveryContact: {
                        firstName: null,
                        lastName: null
                    },
                    delivery: {
                        countryID: 0,
                        city: null,
                        state: null,
                        postalCode: null,
                        companyName: null,
                        address1: null,
                        address2: null,
                        address3: null
                    },
                    currencyID: 3,
                    weightUnit: 100,
                    dimensionUnit: 100,
                    order: {
                        reference_1: null,
                        reference_2: null,
                        reference_3: null,
                        reference_4: null,
                        reference_5: null,
                        reference_6: null,
                        reference_7: null,
                        currencyID: 3,
                        weightUnit: 100,
                        dimensionUnit: 100,
                        incoTerms: 64,
                        instruction: null,
                        customerID: null,
                        customsCategoryType:2,
                        packageType: 4,
                        shipmentType: 8,
                        serviceGroupID: null,
                        serviceGroupCode: null,
                        serviceGroupText: null,
                        collectionDate: null
                    },
                    parcels: [{
                        id: 1,
                        //parcelID: 1,
                        actualWeight: 1.00,
                        length: 0,
                        width: 0,
                        height: 0,
                        declaredValue: 1.00,
                        insuranceValue: 0.00,
                        dutyValue: 0.00,
                        taxValue: 0.00,
                        dangerousGoods: 100,
                        volumetricWeight: 0.00,
                        chargeableWeight: 0.00,
                        packageCount: 1
                    }]
                },
                activity: {
                    parentLoading: true,
                    loading: false,
                    rule:false,
                },
                //productservice: [{ "Priority": "1", "CarrierCode": "DHLEXPRTEST", "CarrierName": "DHL Express", "ServiceCode": "WPX", "ServiceName": "Express Worldwide", "Logo": "/_assets/_integration/3C36ADF8BBF95E1FAA0B442800000002.png", "Key": "00000002-5e1f-4428-aa0b-3c36adf8bbf9" }, { "Priority": "2", "CarrierCode": "MYDHLEXPR", "CarrierName": "DHL Express", "ServiceCode": "DOM", "ServiceName": "Domestic Express", "Logo": "/_assets/_integration/3C36ADF8BBF95E1FAA0B442800000002.png", "Key": "00000002-5e1f-4428-aa0b-3c36adf8bbf9" }, { "Priority": "3", "CarrierCode": "DHLEXPRTEST", "CarrierName": "DHL Express", "ServiceCode": "DOK", "ServiceName": "Domestic Express [09:00]", "Logo": "/_assets/_integration/3C36ADF8BBF95E1FAA0B442800000002.png", "Key": "00000002-5e1f-4428-aa0b-3c36adf8bbf9" }, { "Priority": "4", "CarrierCode": "DHLEXPRTEST", "CarrierName": "DHL Express", "ServiceCode": "DOM", "ServiceName": "Domestic Express", "Logo": "/_assets/_integration/3C36ADF8BBF95E1FAA0B442800000002.png", "Key": "00000002-5e1f-4428-aa0b-3c36adf8bbf9" }, { "Priority": "5", "CarrierCode": "DHLEXPRTEST", "CarrierName": "DHL Express", "ServiceCode": "DOT", "ServiceName": "Domestic Express [12:00]", "Logo": "/_assets/_integration/3C36ADF8BBF95E1FAA0B442800000002.png", "Key": "00000002-5e1f-4428-aa0b-3c36adf8bbf9" }, { "Priority": "6", "CarrierCode": "MYDHLEXPR", "CarrierName": "DHL Express", "ServiceCode": "WPX", "ServiceName": "Express Worldwide", "Logo": "/_assets/_integration/3C36ADF8BBF95E1FAA0B442800000002.png", "Key": "00000002-5e1f-4428-aa0b-3c36adf8bbf9" }],
                productservice: [],
                rules:[],
                keys: null,
                session: {
                    tenant: null,
                    environment: null,
                    warehouse: null,
                    customer: null,
                    currentUser: null,
                    executiveUser: null
                },
                units: {
                    shipFromCountry: "",
                    shipToCountry: "",
                    currency: "GBP",
                    resultCurrency: "GBP"
                },
                activeIndex: [0, 1, 2]
            }
        },
        //validations() {
        //    return {
        //        model: {
        //            code: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
        //            name: { required, minLength: minLength(1), maxLength: maxLength(20), code: validator.code(20), $autoDirty: true },
        //        }
        //    }
        //},
        computed: {
            ...mapGetters({
                user: "auth/currentUser"
            }),
            scopeCustomerDS() {
                return this.$config.common.$customerFiltered(this.user?.environmentID ?? -100, this.currentWarehouse ?? this.$vm.$appSession.warehouse.id ?? this.user.warehouseID, this.user);
            },
            scopeWarehouseDS() {
                return this.$config.common.$warehouse(this.user?.environmentID ?? -100, this.user);
            },
            sessionKeys() { return this.$store.getters["auth/session"]; },
            header() { return this.model; },
            parcels() { return this.model?.parcels ?? [] },
            collectionContact() {
                return {
                    firstName: this.model.collectionContact?.firstName,
                    lastName: this.model.collectionContact?.lastName,
                };
            },
            collectionAddress() {
                return {
                    city: this.model?.collection?.city,
                    state: this.model?.collection?.state,
                    postalCode: this.model?.collection?.postalCode,
                    countryID: this.model?.collection?.countryID,
                    companyName: this.model?.collection?.companyName,
                    address1: this.model?.collection?.address1,
                    address2: this.model?.collection?.address2,
                    address3: this.model?.collection?.address3
                };
            },
            deliveryContact() {
                return {
                    firstName: this.model.deliveryContact?.firstName,
                    lastName: this.model.deliveryContact?.lastName,
                };
            },
            deliveryAddress() {
                return {
                    city: this.model?.delivery?.city,
                    state: this.model?.delivery?.state,
                    postalCode: this.model?.delivery?.postalCode,
                    countryID: this.model?.delivery?.countryID,
                    companyName: this.model?.delivery?.companyName,
                    address1: this.model?.delivery?.address1,
                    address2: this.model?.delivery?.address2,
                    address3: this.model?.delivery?.address3
                };
            },
            currentWarehouse() {
                return this.session?.warehouseID ?? this.$vm.$appSession?.warehouse?.id ?? null;
            },
            warehouse() {
                return this.scopeWarehouseDS.filter(x => x.id == this.currentWarehouse)[0];
            },
            customer() {
                return this.scopeCustomerDS.filter(x => x.id == this.model.customerID)[0];
            },
            collectionDateVal() {
                if (this?.model?.order?.collectionDate)
                    return new Date(this.$filters.formatDate(this?.model?.order.collectionDate));
                return new Date();
            },
            productServiceInit() {
                return [{
                    ProviderCode: null,
                    ProviderName: null,
                    ProductCode: null,
                    ProductName: null,
                    Disclaimer: null,
                    Quote: {
                        Description: null,
                        CurrencyCode: null,
                        Value: 0.00,
                        ItemizedCharges: [
                            {
                                Description: null,
                                CurrencyCode: null,
                                Value: 0.00
                            },
                        ]
                    },
                    Schedule: {
                        CollectionSchedule: {
                            CollectionDate: null,
                            CollectionTime: null,
                            CutOffTime: null
                        },
                        EstimatedDelivery: {
                            CollectionDate: null,
                            CollectionTime: null
                        },
                        TimeInTransit: 1
                    }
                }, {
                    ProviderCode: null,
                    ProviderName: null,
                    ProductCode: null,
                    ProductName: null,
                    Disclaimer: null,
                    Quote: {
                        Description: null,
                        CurrencyCode: null,
                        Value: 0.00,
                        ItemizedCharges: [
                            {
                                Description: null,
                                CurrencyCode: null,
                                Value: 0.00
                            },
                        ]
                    },
                    Schedule: {
                        CollectionSchedule: {
                            CollectionDate: null,
                            CollectionTime: null,
                            CutOffTime: null
                        },
                        EstimatedDelivery: {
                            CollectionDate: null,
                            CollectionTime: null
                        },
                        TimeInTransit: 1
                    }
                }, {
                    ProviderCode: null,
                    ProviderName: null,
                    ProductCode: null,
                    ProductName: null,
                    Disclaimer: null,
                    Quote: {
                        Description: null,
                        CurrencyCode: null,
                        Value: 0.00,
                        ItemizedCharges: [
                            {
                                Description: null,
                                CurrencyCode: null,
                                Value: 0.00
                            },
                        ]
                    },
                    Schedule: {
                        CollectionSchedule: {
                            CollectionDate: null,
                            CollectionTime: null,
                            CutOffTime: null
                        },
                        EstimatedDelivery: {
                            CollectionDate: null,
                            CollectionTime: null
                        },
                        TimeInTransit: 1
                    }
                }
                ]
            },
            modelInit() {
                return {
                    collectionContact: {
                        firstName: null,
                        lastName: null
                    },
                    collection: {
                        countryID: 238,
                        city: null,
                        state: null,
                        postalCode: null,
                        companyName: null,
                        address1: null,
                        address2: null,
                        address3: null
                    },
                    deliveryContact: {
                        firstName: null,
                        lastName: null
                    },
                    delivery: {
                        countryID: 0,
                        city: null,
                        state: null,
                        postalCode: null,
                        companyName: null,
                        address1: null,
                        address2: null,
                        address3: null
                    },
                    currencyID: 3,
                    weightUnit: 100,
                    dimensionUnit: 100,
                    order: {
                        reference_1: null,
                        currencyId: 3,
                        weightUnit: 100,
                        dimensionUnit: 100,
                        incoTerms: 64,
                        instruction: null,
                        customerId: null,
                        customsCategoryType: 2,
                        packageType: 4,
                        shipmentType: 8,
                        serviceGroupID: null,
                        serviceGroupCode: null,
                        serviceGroupText: null,
                        collectionDate: null
                    },
                    parcels: [{
                        id: 1,
                        //parcelID: 1,
                        actualWeight: 1.00,
                        length: 0,
                        width: 0,
                        height: 0,
                        declaredValue: 1.00,
                        insuranceValue: 0.00,
                        dutyValue: 0.00,
                        taxValue: 0.00,
                        dangerousGoods: 100,
                        volumetricWeight: 0.00,
                        chargeableWeight: 0.00,
                        packageCount: 1
                    }]
                };
            },
            apiDataRequest() {
                let packages = [];
                this?.parcels?.forEach((x) => {
                    packages.push({
                        Weight: x.actualWeight,
                        Dimension: {
                            Length: x.length,
                            Height: x.height,
                            Width: x.width
                        },
                        Declaration: {
                            DeclaredValue: x.declaredValue
                        }
                    });

                });
                return {
                    Source: "Web",
                    Request: {
                        Customer: this.scopeCustomerDS.filter(x => x.id === this?.model?.order?.customerID)[0]?.code,
                        Warehouse: this.scopeWarehouseDS.filter(x => x.id === this.$vm.$appSession?.warehouse.id)[0]?.code,
                        ServiceGroup: this.model?.order?.serviceGroupCode,
                        ShippingType: "SHIPPING",
                        Consignment: {
                            Order: {
                                Sender: {
                                    Contact: {
                                        firstName: this.model?.collectionContact?.firstName ?? "XXXJoe",
                                        lastName: this.model?.collectionContact?.lastName ,
                                        email: "no-reply@integro360.com"
                                    },
                                    Address: {
                                        Company: this.model?.collection?.companyName,
                                        Address1: this.model?.collection?.address1 ?? "XXX0",
                                        Address2: this.model?.collection?.address2 ?? "",
                                        Address3: this.model?.collection?.address3 ?? "",
                                        City: this.model?.collection?.city,
                                        State: this.model?.collection?.state,
                                        PostalCode: this.model?.collection?.postalCode,
                                        Country: this.$vm.$countries.filter(x => x.id == this.model?.collection?.countryID)[0]?.code
                                    }
                                },
                                Recipient: {
                                    Contact: {
                                        firstName: this.model?.deliveryContact?.firstName ?? "XXXJoe",
                                        lastName: this.model?.deliveryContact?.lastName ,
                                        email: "no-reply@integro360.com"
                                    },
                                    Address: {
                                        Address1: this.model?.delivery?.address1 ?? "XXX0",
                                        Address2: this.model?.delivery?.address2 ?? "",
                                        Address3: this.model?.delivery?.address3 ?? "",
                                        City: this.model?.delivery?.city,
                                        State: this.model?.delivery?.state,
                                        PostalCode: this.model?.delivery?.postalCode,
                                        Country: this.$vm.$countries.filter(x => x.id == this.model?.delivery?.countryID)[0]?.code
                                    }
                                },
                                Reference1: this.model?.order?.reference_1,
                                Reference2: this.model?.order?.reference_2,
                                Reference3: this.model?.order?.reference_3,
                                Reference4: this.model?.order?.reference_4,
                                Reference5: this.model?.order?.reference_5,
                                Reference6: this.model?.order?.reference_6,
                                Reference7: this.model?.order?.reference_7,
                                Instruction: this.model?.order?.instruction,
                                PackageType: "PACKAGE",
                                CollectionDate: this.$filters.formatDateWithFormat(this.model?.order.collectionDate ?? new Date(), "YYYY-MM-DD"),
                                WeightUnit: this.$vm.$enums['weightunit'].filter((xz) => xz.id == this.model?.weightUnit)[0]?.text?.toUpperCase() ?? "KG",
                                DimensionUnit: this.$vm.$enums['lengthunit'].filter((xz) => xz.id == this.model?.weightUnit)[0]?.text?.toUpperCase() ?? "CM",
                                Currency: this.$vm.$currencies.filter((xz) => xz.id === this.model?.currencyID)[0]?.code?.toUpperCase(),
                                CustomsCategory: this.$vm.$enums['customscategorytype'].filter((xz) => xz.id == this.model?.customsCategoryType)[0]?.code?.toUpperCase() ?? "SAL",
                                Incoterm: this.$vm.$enums['incotermtype'].filter((xz) => xz.id == this.model?.incoTerms)[0]?.code?.toUpperCase() ?? "DAP"
                                
                            },
                            Packages: packages
                        }
                    }
                }
;
            }
            , allActiveIndex() { return this.activeIndex }
            ,consignmentDetails() { return this.activeIndex.findIndex(x => x == 0) !== -1; },
            packageDetails() { return this.activeIndex.findIndex(x => x == 1) !== -1; },
            resultDetails() { return this.activeIndex.findIndex(x => x == 2) !== -1; },
            companySettingRecord() {
                return this.$store.getters["companygeneralsetting/record"];
            }
        },
        watch: {
            keys() {
                this.$nextTick(() => {
                    this.session = this.sessionKeys;
                    if (this.warehouse !== undefined) {
                        this.populateCollectionAddress(this.warehouse);
                    }
                    else {
                        this.getCompanyList().then(() => {
                            this.populateCollectionAddress(this.warehouse);
                        });
                    }
                });
            },
            'model.order.serviceGroupMask': { handler() { this.getServiceGroup() }, deep: true }
        },
        methods: {
            formatCurrency(value, currencyCode) {
                return value.toLocaleString('en-US', { style: 'currency', currency: currencyCode ?? this.currencyDS.data.filter((x) => x.id === this.model?.currencyID)[0]?.code ?? "USD" });
            },
            getDate(data) {
                if (data != null) {
                    return new Date().setFullYear(Number(data.substring(0, 4)), Number(data.substring(4, 6)) - 1, Number(data.substring(6, 8)))
                }
                return new Date();
            },
            getTime(data) {
                if (data != null) {
                    return new Date().setHours(Number(data.substring(0, 2)), Number(data.substring(2, 4)), Number(data.substring(4, 6)))
                }
                return new Date();
            },
            isWithFuelCharge(data) {
                return data?.Quote?.ItemizedCharges?.filter((x) => x.Description === "Fuel Surcharge").length > 0;
            },
            reset() {
                this.activity.loading = true;
                this.model = {
                    collectionContact: {
                        firstName: null,
                        lastName: null
                    },
                    collection: {
                        countryID: 238,
                        city: null,
                        state: null,
                        postalCode: null,
                        companyName: null,
                        address1: null,
                        address2: null,
                        address3: null
                    },
                    deliveryContact: {
                        firstName: null,
                        lastName: null
                    },
                    delivery: {
                        countryID: 0,
                        city: null,
                        state: null,
                        postalCode: null,
                        companyName: null,
                        address1: null,
                        address2: null,
                        address3: null
                    },
                    currencyID: 3,
                    weightUnit: 100,
                    dimensionUnit: 100,
                    order: {
                        reference_1: null,
                        reference_2: null,
                        reference_3: null,
                        reference_4: null,
                        reference_5: null,
                        reference_6: null,
                        reference_7: null,
                        currencyID: 3,
                        weightUnit: 100,
                        dimensionUnit: 100,
                        incoTerms: 64,
                        instruction: null,
                        customerID: null,
                        customsCategoryType: 2,
                        packageType: 4,
                        shipmentType: 8,
                        serviceGroupID: null,
                        serviceGroupCode: null,
                        serviceGroupText: null,
                        collectionDate: null
                    },
                    parcels: [{
                        id: 1,
                        //parcelID: 1,
                        actualWeight: 1.00,
                        length: 0,
                        width: 0,
                        height: 0,
                        declaredValue: 1.00,
                        insuranceValue: 0.00,
                        dutyValue: 0.00,
                        taxValue: 0.00,
                        dangerousGoods: 100,
                        volumetricWeight: 0.00,
                        chargeableWeight: 0.00,
                        packageCount: 1
                    }]
                };
                this.$filters.init(this.modelInit);
                this.productservice = [];
                this.activeIndex = [0, 1, 2];
                this.model.order.customerID = this.model?.order?.customerID ?? this.scopeCustomerDS[0]?.id;
                this.populateCollectionAddress(this.warehouse);
                setTimeout(() => {
                    this.activity.loading = false;
                }, 1000);
                
            },
            getService() {
                this.activity.loading = true;
                this.productservice = [];// this.productServiceInit;
                let _self = this;
                this.$axios.post(this.$config.config.endpoint.api + `/api/polaris/tools/servicegroup`, this.apiDataRequest)
                    .then((response) => {
                        _self.productservice = response?.data?.Response?.Data ?? [];
                        _self.activity.loading = false;
                        if (_self.productservice.length === 0) {
                            _self.$toast.add({ severity: 'warn', summary: 'No Valid Service Result!', detail: 'Consignment detail did not qualify for service group ' + _self.model.order.serviceGroupText, life: 8000 });
                        }
                    })
                    .catch(() => {
                        this.activity.loading = false;
                    });
            },
            onSelectServiceGroup(event) {
                this.model.order.serviceGroupCode = event?.record?.code ?? null;
                this.model.order.serviceGroupText = event?.record?.text ?? null;
                this.model.order.serviceGroupMask = event?.record?.mask ?? null;
            },
            populateCollectionAddress(e) {
                this.model.collection.city = e?.city;
                this.model.collection.state = e?.state;
                this.model.collection.postalCode = e?.postalCode;
                this.model.collection.countryID = e?.countryID;
                this.model.collection.companyName = e?.companyName;
                this.model.collection.address1 = e?.address1;
                this.model.collection.address2 = e?.address2;
                this.model.collection.address3 = e?.address3;
                if ((this.model.delivery?.countryID ?? 0) === 0) {
                    this.model.delivery.countryID = e?.countryID;
                }
                this.collectionAddress = {
                    city: this.model?.collection?.city,
                    state: this.model?.collection?.state,
                    postalCode: this.model?.collection?.postalCode,
                    countryID: this.model?.collection?.countryID,
                    companyName: this.model?.collection?.companyName,
                    address1: this.model?.collection?.address1,
                    address2: this.model?.collection?.address2,
                    address3: this.model?.collection?.address3
                };
            },
            populateDeliveryAddress(e) {
                this.model.delivery.city = e?.city;
                this.model.delivery.state = e?.state;
                this.model.delivery.postalCode = e?.postalCode;
                this.model.delivery.countryID = e?.countryID;
                this.model.delivery.companyName = e?.companyName;
                this.model.delivery.address1 = e?.address1;
                this.model.delivery.address2 = e?.address2;
                this.model.delivery.address3 = e?.address3;
                this.deliveryAddress = {
                    city: this.model?.delivery?.city,
                    state: this.model?.delivery?.state,
                    postalCode: this.model?.delivery?.postalCode,
                    countryID: this.model?.delivery?.countryID,
                    companyName: this.model?.delivery?.companyName,
                    address1: this.model?.delivery?.address1,
                    address2: this.model?.delivery?.address2,
                    address3: this.model?.delivery?.address3
                };
            },
            populateCollectionContact(e) {
                if (e) {
                    this.$nextTick(() => {
                        this.model.collectionContact.firstName = e.firstName;
                        this.model.collectionContact.lastName = e.lastName;
                        if (e.address && e.address?.address1) {
                            this.populateCollectionAddress(e.address);
                        }
                    });
                }
            },
            populateDeliveryContact(e) {
                if (e) {
                    this.$nextTick(() => {
                        this.model.deliveryContact.firstName = e.firstName;
                        this.model.deliveryContact.lastName = e.lastName;
                        if (e.address && e.address?.address1) {
                            this.populateDeliveryAddress(e.address);
                        }
                    });
                }
            },
            onAdd() {
                var itemline = {
                    id: this.parcels.length + 1,
                    actualWeight: 0.00,
                    length: 0,
                    width: 0,
                    height: 0,
                    declaredValue: 1.00,
                    insuranceValue: 0.00,
                    dutyValue: 0.00,
                    taxValue: 0.00,
                    dangerousGoods: 100,
                    volumetricWeight: 0.00,
                    chargeableWeight: 0.00,
                    packageCount: 1
                };
                this.model.parcels.push(itemline);
            },
            onDelete(index) {
                let parcels = this.parcels.filter(x => x.parcelID !== index).map(x => this.$filters.init(x));
                for (let i = 0; i < parcels.length; i++)
                    parcels[i].parcelID = i + 1;
                this.model.parcels = parcels;
            },
            onCopyNext(index) {
                let source = this.$filters.init(this.model.parcels[index - 1]);
                source.parcelID = index + 1;
                this.model.parcels[index] = source;

            },
            onCopyValue(e) {
                if (e.target < this.model.parcels.length) {
                    let source = this.$filters.init(this.model.parcels[e.source - 1] ?? this.model.parcels[e.target - 1]);
                    source.parcelID = this.model.parcels[e.target - 1].parcelID;
                    this.model.parcels[e.target - 1] = source;
                }
                else {
                    let source = this.$filters.init(this.model.parcels[e.source - 1]);
                    source.parcelID = e.target;
                    this.model.parcels.push(source);
                }
            }
             , onOpen(e) {
                var index = this.activeIndex.findIndex(x => x == e.index);
                if (index === -1)
                    this.activeIndex.push(e.index);
            }
            , onClose(e) {
                var index = this.activeIndex.findIndex(x => x == e.index);
                if (index !== -1)
                    this.activeIndex.splice(index, 1);
            }
            ,getServiceGroup() {
                if (this.model?.order?.serviceGroupMask !== null && this.model?.order?.serviceGroupMask !== undefined) {
                    this.$axios.get(this.$config.config.endpoint.api + `/services/facility/ruleengineauditlog/` + this.model?.order?.serviceGroupMask + `/servicegroup`)
                        .then((response) => {
                            this.rules = response?.data?.result[0]?.rules ?? [];
                        })
                        .catch(() => {
                        });
                }
                else {
                    this.rules = [];
                }
            },
            getSetting() {
                if (this.model.customerID > 0)
                    return this.$store.dispatch("companygeneralsetting/getURL", { url: `/services/settings/companygeneralsetting/ByCompany/${(this.customer.mask)}`.replaceAll("//", "/") });
            },

        },
        async created() {
            this.model = this.modelInit;
        },
        mounted() {
            let _self = this;
            window.addEventListener('setItem', () => {
                this.keys = sessionStorage.getItem('activekeys');
            });
            this.keys = sessionStorage.getItem('activekeys');
            _self.model.order.customerID = this.model?.order?.customerID ?? this.scopeCustomerDS[0]?.id;
            this.populateCollectionAddress(this.warehouse);
            this.activity.parentLoading = false;
            this.model = this.modelInit;

        },
        unmounted() {
            try {
                window.removeEventListener('setItem');
            } catch { /**/ }
        }
    }
</script>
<style>
    #RuleEngineAccordion .p-accordion-header-link {
        padding: 0.75rem 1rem;
        border-bottom: 1px solid var(--gray-200);
    }
    .p-picklist-header {
        padding: 0 10px !important;
    }

    .p-orderlist-controls,
    .p-picklist-buttons.p-picklist-source-controls,
    .p-picklist-buttons.p-picklist-source-controls, .p-picklist-buttons.p-picklist-target-controls {
        display: none !important;
    }

    .picklist-item {
        display: flex;
        align-items: center;
        padding: .2rem;
        width: 100%;
    }

    img#is-img {
        width: 75px;
        /*   box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);*/
        margin-right: 1rem;
    }

    .picklist-list-detail {
        flex: 1 1 0;
    }

    .picklist-list-action {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .picklist-category-icon {
        vertical-align: middle;
        margin-right: .5rem;
        font-size: .875rem;
    }

    .picklist-category {
        vertical-align: middle;
        line-height: 1;
        font-size: .875rem;
    }

    .custom-skeleton {
        border: 1px solid var(--surface-d);
        border-radius: 4px;
    }

    .custom-skeleton ul {
        list-style: none;
    }

        .custom-skeleton ul > li {
            padding: 0.75rem;
        }
</style>
