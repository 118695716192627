<template>
    <is-addressbooklookup v-if="tools.showDirectory"  @is-selected="addressBookSelect($event)"  @is-cancel="tools.showDirectory = false" :searchText="model.firstName" :customerID="directoryFilterID"></is-addressbooklookup>
    <div v-if="!disabled || (disabled && disabledView.toLowerCase() === 'field')">
        <form-input :disabled="disabled" :id="`${id}_FirstName`" label="First Name" :v$="v$" :value="model.firstName" vID="firstName" @is-sync="model.firstName = $event.value;" :showDirectory="showDirectory" @is-click="tools.showDirectory = $event" />
        <form-input :disabled="disabled" :id="`${id}_LastName`" label="Last Name" :v$="v$" :value="model.lastName" @is-sync="model.lastName = $event.value; this.v$.$touch();" :tab-index="-1" />
        <form-input :disabled="disabled" v-if="useContactDetail"  :id="`${id}_Email`" label="Email" :v$="v$" :value="model.email" vID="email" :max-length="100" @is-sync="model.email = $event.value?.toLowerCase()" v-lowercase />
        <form-input :disabled="disabled" v-if="useContactDetail" :id="`${id}_Telephone`" label="Telephone" :v$="v$" :value="model.telephone" :max-length="25" vID="telephone" @is-sync="model.telephone = $event.value" />
        <form-input :disabled="disabled" v-if="useContactDetail" :id="`${id}_Mobile`" label="Mobile" :v$="v$" :value="model.mobile" vID="mobile" :max-length="25" @is-sync="model.mobile = $event.value" />
        <form-input :disabled="disabled" v-if="useRegistrationNumber" :id="`${id}_VATID`" label="VAT Number" :v$="v$" :value="model.vat" @is-sync="model.vat = $event.value" :max-length="25" />
        <form-input :disabled="disabled" v-if="useRegistrationNumber" :id="`${id}_EORI`" vID="eori" label="EORI <span style='font-size: 0.7rem'>(Economic Operators Registration and Identification)</span>" :v$="v$" :value="model.eori" @is-sync="model.eori = $event.value" :max-length="25" />
        <form-input :disabled="disabled" v-if="useRegistrationNumber" :id="`${id}_IOSS`" vID="ioss" label="IOSS <span style='font-size: 0.7rem'>(Import One-Stop Shop)</span>" :v$="v$" :value="model.ioss" @is-sync="model.ioss = $event.value" :max-length="15" />
        <form-input :disabled="disabled" v-if="useRegistrationNumber" :id="`${id}_UKIMS`" vID="ukims" label="UKIMS <span style='font-size: 0.7rem'>(UK Internal Market Scheme)</span>" :v$="v$" :value="model.ukims" @is-sync="model.ukims = $event.value" :max-length="35" />
        <form-input :disabled="disabled" v-if="useRegistrationNumber" :id="`${id}_PID`" label="Personal ID Number" :v$="v$" :value="model.idNumber" @is-sync="model.idNumber = $event.value" :max-length="25" />
        <form-lookup :disabled="disabled" v-if="useRegistrationNumber" :id="`${id}_PIDType`" type="enum" label="ID Type" source="identitynumbertype" :v$="v$" :value="model.idType" @is-sync="model.idType = $event.value" />
    </div>
    <div v-else>
        <div style="font-size: 1.2rem; font-weight: 600; margin-bottom: 5px;">{{model.firstName}} {{model.lastName}}</div>
        <span v-html="`${fullDetails}`"></span>
    </div>
</template>
<script>
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength, maxLength, email, helpers } from '@vuelidate/validators';
    import mixins from '@/assets/lib/cosmos/_js/core-mixins.js';
    /** CUSTOM VALIDATOR **/
    const regex = (param) => helpers.withParams({ type: 'regex', value: param }, (value) => value.match(param) != null);
    export default {
        name: 'FormContactField',
        mixins: [mixins.COMPONENT],
        setup: () => ({ v$: useVuelidate() }),
        props: {
            value: { type: Object },
            useRegistrationNumber: { type: Boolean, default: false },
            disabledView: { type: String, default: "field", validator: (value) => ["field", "perline", "singleline"].indexOf((value ?? "field").toLowerCase()) !== -1 },
            showDirectory: { type: Boolean, default: false },
            directoryFilterID: { type: Number, default: null },
            useContactDetail: { type: Boolean, default: true },
            applyValidatorRequired: { type: Boolean, default: true }
        },
        data() {
            return {
                model: {
                    id: 0,
                    temporaryID: null,
                    firstName: null,
                    lastName: null,
                    email: null,
                    mobile: null,
                    telephone: null,
                    eori: null,
                    ioss: null,
                    ukims: null,
                    vat: null,
                    idNumber: null,
                    idType: null
                },
                validation: {
                    pattern: null,
                    rei: 'state',
                    requirePostalCode: false,
                    requireState: false
                },
                tools: {
                    showDirectory: false
                }
            }
        },
        computed: {
            fullDetails() {
                let pidType = (this.$vm.$enums["identitynumbertype"].filter(x => x.id === this.model.idType ?? 300)[0].text).toUpperCase();
                let separator = "<br />"
                let data = "<div style='font-size: 0.9rem; font-family: Roboto Mono, monospace'>" + ((this.model.email ?? "").trim().length > 0 ? " <i class='fa fa-at fa-fw' style='width: 25px'></i>" + ((this.model.email ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + separator : "") + " " +
                    ((this.model.telephone ?? "").trim().length > 0 ? "<i class='fa fa-phone fa-fw' style='width: 25px'></i>" + ((this.model.telephone ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + separator : "") + " " +
                    ((this.model.mobile ?? "").trim().length > 0 ? "<i class='fa fa-mobile fa-fw' style='width: 25px'></i>" + ((this.model.mobile ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + separator : "") + "</div>";
                if (this.useRegistrationNumber)
                    data += "<div style='margin-top: 5px; padding-top: 5px; margin-bottom: 10px; border-top: 1px solid var(--surface-d); font-size: 0.9rem; font-family: Roboto Mono, monospace'>" +
                        ((this.model.vat ?? "").trim().length > 0 ? "<i class='fa fa-id-card fa-fw' style='width: 25px'></i>" + ((this.model.vat ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + " (VAT)" + separator : "") + " " +
                        ((this.model.eori ?? "").trim().length > 0 ? "<i class='fa fa-id-card fa-fw' style='width: 25px'></i>" + ((this.model.eori ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + " (EORI)" + separator : "") + " " +
                        ((this.model.ioss ?? "").trim().length > 0 ? "<i class='fa fa-id-card fa-fw' style='width: 25px'></i>" + ((this.model.ioss ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + " (IOSS)" + separator : "") + " " +
                        ((this.model.ukims ?? "").trim().length > 0 ? "<i class='fa fa-id-card fa-fw' style='width: 25px'></i>" + ((this.model.ukims ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + " (UKIMS)" + separator : "") + " " +
                        ((this.model.idNumber ?? "").trim().length > 0 ? "<i class='fa fa-id-card fa-fw' style='width: 25px'></i>" + ((this.model.idNumber ?? "").trim() + '�').replaceAll(',�', '').replaceAll('�', '') + " (" + pidType + ")" +separator : "") + "</div>"
                return data;
            },
            isMobileOptional() {
                return true;
            }
        },
        validations() {
            /** DYNAMIC VALIDATION **/
            let validator = {
                model: {
                    firstName: { required, minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                    lastName: { maxLength: maxLength(35), $autoDirty: true },
                    email: { required, email, minLength: minLength(1), maxLength: maxLength(100), $autoDirty: true },
                    mobile: { required, $autoDirty: true, maxLength: maxLength(25) },
                    telephone: { $autoDirty: true, maxLength: maxLength(25) },
                    eori: { $autoDirty: true },
                    ioss: { $autoDirty: true },
                    ukims: { $autoDirty: true },
                    vat: { maxLength: maxLength(25), $autoDirty: true },
                    idNumber: { $autoDirty: true },
                    idType: { $autoDirty: true }
                }
            };

            if ((this.model.ioss?.length ?? 0) > 0)
                validator.model.ioss = { maxLength: maxLength(15), $autoDirty: true, regex: helpers.withMessage(`The IOSS must follow this format: IM9999999999.`, regex('^IM[0-9a-zA-Z]{10,13}$')) }

            if ((this.model.eori?.length ?? 0) > 0)
                validator.model.eori = { maxLength: maxLength(25), $autoDirty: true, regex: helpers.withMessage(`Invalid EORI format.`, regex('^(XI[a-zA-Z0-9]{12,15}|GB[a-zA-Z0-9]{12,15}|(AT|BE|BG|CY|CZ|DE|DK|EE|EL|ES|FI|FR|HR|HU|IE|IT|LT|LU|LV|MT|NL|PL|PT|RO|SE|SI|SK)[a-zA-Z0-9]{8,15})$')) }

            if ((this.model.ukims?.length ?? 0) > 0)
                validator.model.ukims = { maxLength: maxLength(35), minLength: minLength(32), $autoDirty: true, regex: helpers.withMessage('Invalid UKIMS format. Acceptable Format(s): ZZUKIMXXXXXXXXXXXXXXXYYYYMMDDHHmmss, where ZZ must be either GB or XI, X corresponds to the 13-15 EORI Number, then the rest is date and time (i.e. GBUKIM12345678901234520240101123045)', regex('^(GB|XI)(UKIM)([a-zA-Z0-9]{12,15})(2[0-9]{3})((01|03|05|07|08|10|12)(0[1-9]|1[0-9]|2[0-9]|3[0-1])|(04|06|09|11)(0[1-9]|1[0-9]|2[0-9]|30)|02(0[1-9]|1[0-9]|2[0-9]))(([0-1][0-9])|2[0-3])([0-5][0-9])([0-5][0-9])$')) }

            if ((this.model.telephone?.length ?? 0) > 0)
                validator.model.telephone = { maxLength: maxLength(25), minLength: minLength(7), $autoDirty: true, regex: helpers.withMessage(`Invalid Telephone format. It must be a numeric string with at least one non-zero character and a length between 7 and 25 characters.`, regex('^(?!0+$)[0-9]{7,25}$')) }

            if ((this.model.mobile?.length ?? 0) > 0)
                validator.model.mobile = { required, maxLength: maxLength(25), minLength: minLength(7), $autoDirty: true, regex: helpers.withMessage(`Invalid Mobile format. It must be a numeric string with at least one non-zero character and a length between 7 and 25 characters.`, regex('^(?!0+$)[0-9]{7,25}$')) }


            if (!this.applyValidatorRequired) {
                validator.model = {
                    firstName: { minLength: minLength(1), maxLength: maxLength(35), $autoDirty: true },
                    lastName: { maxLength: maxLength(35), $autoDirty: true },
                    email: {  email, minLength: minLength(1), maxLength: maxLength(100), $autoDirty: true },
                    mobile: {  $autoDirty: true, maxLength: maxLength(25) },
                    telephone: { $autoDirty: true, maxLength: maxLength(25) },
                    eori: { $autoDirty: true },
                    ioss: { $autoDirty: true },
                    ukims: { $autoDirty: true },
                    vat: { maxLength: maxLength(25), $autoDirty: true },
                    idNumber: { $autoDirty: true },
                    idType: { $autoDirty: true }
                }

                if ((this.model.mobile?.length ?? 0) > 0)
                    validator.model.mobile = { maxLength: maxLength(25), minLength: minLength(7), $autoDirty: true, regex: helpers.withMessage(`Invalid Mobile format. It must be a numeric string with at least one non-zero character and a length between 7 and 25 characters.`, regex('^(?!0+$)[0-9]{7,25}$')) }

            }
            return validator;
        },
        watch: {
            model: {
                handler(e) {
                    this.onSync(e);
                },
                deep: true
            },
            value() {
                this.model = this.value;  
            }
        },
        methods: {
            onSync() {
                let data = {
                    firstName: (this.model.firstName ?? "").trim().length > 0 ? (this.model.firstName ?? "").trim() : null,
                    lastName: (this.model.lastName ?? "").trim().length > 0 ? (this.model.lastName ?? "").trim() : null,
                    email: (this.model.email ?? "").trim().length > 0 ? (this.model.email ?? "").trim()?.toLowerCase() : null,
                    mobile: (this.model.mobile ?? "").trim().length > 0 ? (this.model.mobile ?? "").trim() : null,
                    telephone: (this.model.telephone ?? "").trim().length > 0 ? (this.model.telephone ?? "").trim() : null,
                    eori: (this.model.eori ?? "").trim().length > 0 ? (this.model.eori ?? "").trim() : null,
                    ioss: (this.model.ioss ?? "").trim().length > 0 ? (this.model.ioss ?? "").trim() : null,
                    ukims: (this.model.ukims ?? "").trim().length > 0 ? (this.model.ukims ?? "").trim() : null,
                    vat: (this.model.vat ?? "").trim().length > 0 ? (this.model.vat ?? "").trim() : null,
                    idNumber: (this.model.idNumber ?? "").trim().length > 0 ? (this.model.idNumber ?? "").trim() : null,
                    idType: this.model.idType,
                   
                };
                this.$emit("is-sync", data);
            },
            addressBookSelect(e) {
                //console.log(e.record);
                let addressbook = e.record;
                //let countryRecord = this.$vm.$countries.filter(x => x.Id == addressbook.countryID)[0];
                //if (this.model.countryRecord && this.model.countryRecord.useStateCode)
                let data = {
                    addressBookID : e?.value,
                    firstName: (addressbook.firstName ?? "").trim().length > 0 ? (addressbook.firstName ?? "").trim() : null,
                    lastName: (addressbook.lastName ?? "").trim().length > 0 ? (addressbook.lastName ?? "").trim() : null,
                    email: (addressbook.email ?? "").trim().length > 0 ? (addressbook.email ?? "").trim()?.toLowerCase() : null,
                    mobile: (addressbook.mobile ?? "").trim().length > 0 ? (addressbook.mobile ?? "").trim() : null,
                    telephone: (addressbook.telephone ?? "").trim().length > 0 ? (addressbook.telephone ?? "").trim() : null,
                    eori: (addressbook.eori ?? "").trim().length > 0 ? (addressbook.eori ?? "").trim() : null,
                    ioss: (addressbook.iossNumber ?? "").trim().length > 0 ? (addressbook.iossNumber ?? "").trim() : null,
                    ukims: (addressbook.ukimsNumber ?? "").trim().length > 0 ? (addressbook.ukimsNumber ?? "").trim() : null,
                    vat: (addressbook.vatNo ?? "").trim().length > 0 ? (addressbook.vatNo ?? "").trim() : null,
                    idNumber: (addressbook.identityNumber ?? "").trim().length > 0 ? (addressbook.identityNumber ?? "").trim() : null,
                    idType: addressbook.identityNumberType,
                    address: {
                        companyName: addressbook.company,
                        address1: (addressbook.address1 ?? "").trim().length > 0 ? (addressbook.address1 ?? "").trim() : null,
                        address2: (addressbook.address2 ?? "").trim().length > 0 ? (addressbook.address2 ?? "").trim() : null,
                        address3: (addressbook.address3 ?? "").trim().length > 0 ? (addressbook.address3 ?? "").trim() : null,
                        city: (addressbook.city ?? "").trim().length > 0 ? (addressbook.city ?? "").trim() : null,
                        state: (addressbook.state ?? "").trim().length > 0 ? (addressbook.state ?? "").trim() : null,
                        postalCode: (addressbook.postalCode ?? "").trim().length > 0 ? (addressbook.postalCode ?? "").trim() : null,
                        countryID: addressbook.countryID,
                        establishmentType: (addressbook.companyName ?? "").trim().length > 0 ? 100 : 200
                    }
                };

        

                this.$emit("is-sync", data);
            }
        },
        created() {
            this.model = this.value;
        }
    }
</script>